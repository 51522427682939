const COURSE_ROUTES = {
    COURSE_CREATE: { path: "/org/:orgName/course/create", base: "/create" },
    COURSE_HOME: { path: "/org/:orgName/course/user", base: "/user" },
    COURSE_UPDATE: { path: "/org/:orgName/course/update/:id", base: "/update/:id" },
    COURSE_UPLOAD: { path: "/org/:orgName/course/upload/:id", base: "/upload/:id" },
    COURSE_VIDEOS: { path: "/org/:orgName/course/videos/:id", base: "/videos/:id" },
};

const GENERAL_ROUTES = {
    PAGE_NOT_FOUND: { path: "/page-not-found", base: "/page-not-found" },
};

export const ROUTE_DEFINITIONS = { ...COURSE_ROUTES, ...GENERAL_ROUTES };

export const ROUTES = Object.keys(ROUTE_DEFINITIONS).reduce((acc, key) => {
    acc[key] = key;
    return acc;
}, {});

export const resolveFullRoute = (routeKey, params = {}) => {
    const routeTemplate = ROUTE_DEFINITIONS[routeKey]?.path;

    if (!routeTemplate) {
        console.warn(`Invalid route key: ${routeKey}`);
        return ROUTE_DEFINITIONS.PAGE_NOT_FOUND.path;
    }

    const missingParams = [];
    const resolvedRoute = routeTemplate.replace(/:([a-zA-Z]+)/g, (match, key) => {
        if (params[key] !== undefined) {
            return params[key];
        } else {
            missingParams.push(key);
            return match;
        }
    });

    if (missingParams.length > 0) {
        const errorMsg = `Missing required parameters: [${missingParams.join(", ")}] for route "${routeKey}"`;

        if (process.env.NODE_ENV === "development") {
            throw new Error(errorMsg);
        } else {
            console.warn(errorMsg);
            return ROUTE_DEFINITIONS.PAGE_NOT_FOUND.path;
        }
    }

    return resolvedRoute;
}

export const resolveBaseRoute = (routeKey) => {
    return ROUTE_DEFINITIONS[routeKey]?.base || ROUTE_DEFINITIONS.PAGE_NOT_FOUND.base;
}

