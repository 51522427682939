import React from 'react';
import { Avatar, Badge, Box, Tooltip, Typography } from '@mui/material';
import { CommonColors } from '../../utils/colors';

export default function ChatListCard({
  handleChatClick,
  profilePhotoLink,
  name,
  latestMessage,
  chat,
  selectedChat,
  index,
  unreadMessagesCount,
  senderId,
  currentuser,
}) {
  const bgColor = CommonColors.primaryBgColor;
  const activeChatBgColor = '#f4f6ff';
  return (
    <Box
      key={index}
      onClick={() => handleChatClick(chat)}
      sx={{
        cursor: 'pointer',
        padding: 0.5,
        backgroundColor: selectedChat === chat ? activeChatBgColor : '',
        border: selectedChat === chat ? '1px solid #ebebeb' : '',
        marginX: selectedChat === chat ? { sm: '12px' } : { sm: '12px' },
        borderRadius: selectedChat === chat ? '8px' : '',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '-webkit-fill-available',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        {profilePhotoLink ? (
          <img
            src={profilePhotoLink}
            alt="profile"
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              marginRight: '10px',
            }}
          />
        ) : (
          <Avatar
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '30px',
              height: '30px',
              marginRight: '10px',
              backgroundColor: bgColor,
              color: '#fff',
              fontFamily: 'Poppins',
              fontSize: '19px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
              borderRadius: '4px',
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {name && name.charAt(0)}
          </Avatar>
        )}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'auto',
          }}
        >
          <Tooltip title={name} placement="top" arrow>
            <Typography
              variant="subtitle2"
              sx={{
                overflowX: 'auto',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                fontWeight: '500',
                m: 0,
                p: 0,
                lineHeight: 1.3,
              }}
            >
              {name}
            </Typography>
          </Tooltip>
          <Typography
            variant="caption"
            sx={{
              lineHeight: 1.2,
              color: '#9d9d9d',
              overflowX: 'auto',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
            }}
          >
            {latestMessage}
          </Typography>
        </Box>
      </Box>
      {unreadMessagesCount > 0 && (
        <span
          style={{
            backgroundColor: '#8ca4f8',
            color: 'white',
            width: '20px',
            height: '20px',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            flexShrink: 0,
            fontSize: '10px',
            lineHeight: '12px',
          }}
        >
          {unreadMessagesCount > 99 ? '99+' : unreadMessagesCount}
        </span>
      )}
    </Box>
  );
}
