import React from 'react';
import { Box, ListItem, Typography } from '@mui/material';

const TestCaseItem = ({ testCase }) => {
  const formatInput = (input) => {
    return Object.entries(input).map(([key, value]) => (
      <>
        <Box
          key={key}
          sx={{
            borderLeft: '1px solid #eee',
            pl: 1,
            my: 1,
            py: 0,
            '&:last-child': {
              mb: 0,
            },
          }}
        >
          <Typography
            sx={{
              lineHeight: 1.6,
              color: '#021148',
            }}
            variant="subtitle2"
          >
            {key}:{' '}
            <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
              {Array.isArray(value) ? `[${value.join(', ')}]` : `${value.toString()}, `}
            </span>
          </Typography>
        </Box>
      </>
    ));
  };

  const formatExpected = (expected) => {
    return Array.isArray(expected) ? `[${expected.join(', ')}]` : expected;
  };

  return (
    <ListItem
      sx={{
        borderLeft: '1px solid #eee',
        pl: 2,
        my: 2,
        py: 0,
        '&:last-child': {
          mb: 0,
        },
        mt: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      {testCase?.isHidden ? (
        <Typography
          sx={{
            lineHeight: 1.6,
            color: '#021148',
            mb: 0.5,
            display: 'block',
          }}
          variant="subtitle2"
        >
          Hidden Test Case
        </Typography>
      ) : (
        <>
          <Typography
            sx={{
              lineHeight: 1.6,
              color: '#021148',
              mb: 1,
              display: 'block',
            }}
            variant="subtitle2"
          >
            Input:
            {formatInput(testCase.input)}
          </Typography>

          <Typography
            sx={{
              lineHeight: 1.6,
              color: '#021148',
              mb: 0.5,
              display: 'block',
            }}
            variant="subtitle2"
          >
            Expected:
            <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
              {formatExpected(testCase.expected)}
            </span>
          </Typography>

          {testCase?.output && (
            <Typography
              sx={{
                lineHeight: 1.6,
                color: '#021148',
                mb: 0.5,
                display: 'block',
              }}
              variant="subtitle2"
            >
              Output:
              <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
                {testCase.output}
              </span>
            </Typography>
          )}

          {testCase?.score !== undefined && (
            <Typography
              sx={{
                lineHeight: 1.6,
                color: '#021148',
                mb: 0.5,
                display: 'block',
              }}
              variant="subtitle2"
            >
              Score:
              <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
                {testCase.score}
              </span>
            </Typography>
          )}

          {testCase?.execution_time_ms !== undefined && (
            <Typography
              sx={{
                lineHeight: 1.6,
                color: '#021148',
                mb: 0.5,
                display: 'block',
              }}
              variant="subtitle2"
            >
              Execution Time:
              <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
                {testCase.execution_time_ms} ms
              </span>
            </Typography>
          )}

          {testCase?.error && (
            <Typography sx={{ color: 'red' }} variant="subtitle2">
              Error:
              <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
                {testCase.error}
              </span>
            </Typography>
          )}
          {testCase?.stdout && (
            <Typography sx={{ color: '#021148' }} variant="subtitle2">
              Stdout:
              <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
                {testCase.stdout}
              </span>
            </Typography>
          )}
        </>
      )}
    </ListItem>
  );
};

export default TestCaseItem;
