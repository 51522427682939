import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LazyComponent } from '../components/LazyLoader';
import { ROUTES, resolveBaseRoute } from './routes'

const CreateCourse = LazyComponent(() => import('../pages/Courses/CreateCourse'));
const CourseHomePage = LazyComponent(() => import('../pages/Courses/CourseHomePage'));
const UpdateCourse = LazyComponent(() => import('../pages/Courses/CourseUpdate'));
const CourseUpload = LazyComponent(() => import('../pages/Courseupload/Courseupload'));
const CourseVideo = LazyComponent(() => import('../pages/Coursevideo/Coursevideo'));

export const CourseRoutes = () => {
  return (
    <Routes>
      <Route path={resolveBaseRoute(ROUTES.COURSE_CREATE)} element={<CreateCourse />} />
      <Route path={resolveBaseRoute(ROUTES.COURSE_HOME)} element={<CourseHomePage />} />
      <Route path={resolveBaseRoute(ROUTES.COURSE_UPDATE)} element={<UpdateCourse />} />
      <Route path={resolveBaseRoute(ROUTES.COURSE_UPLOAD)} element={<CourseUpload />} />
      <Route path={resolveBaseRoute(ROUTES.COURSE_VIDEOS)} element={<CourseVideo />} />
    </Routes>
  );
};
