import io from 'socket.io-client';
import GetValidatedTokenData from '../utils/helper';

class SocketClient {
  static instance = null;
  socket = null;
  retries = 0;
  maxRetries = parseInt(process.env.REACT_APP_SOCKET_RETRIES, 10) || 3;
  pendingMessages = [];

  constructor() {
    if (SocketClient.instance) {
      return SocketClient.instance;
    }
    SocketClient.instance = this;
  }

  async initSocket() {
    if (!this.socket) {
      const currentUserInfo = GetValidatedTokenData();
      this.socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
        query: { userId: currentUserInfo.id },
        transports: ['websocket'],
        extraHeaders: {
          'Access-Control-Allow-Origin': '*',
          'Cross-Origin-Opener-Policy': 'same-origin-allow-popups',
        },
      });

      this.socket.on('connect', () => {
        this.retries = 0;
        this.flushPendingMessages();
      });

      this.socket.on('connect_error', async () => {
        if (this.retries < this.maxRetries) {
          this.retries++;
          const retryDelay = Math.pow(2, this.retries) * 1000;
          await new Promise(res => setTimeout(res, retryDelay));
          this.socket.connect();
        }
      });
    }
    return this.socket;
  }

  async getSocket() {
    if (!this.socket) {
      await this.initSocket();
    }
    return this.socket;
  }

  async emit(event, data) {
    const socket = await this.getSocket();
    if (socket && socket.connected) {
      socket.emit(event, data);
    } else {
      this.pendingMessages.push({ event, data });
    }
  }

  flushPendingMessages() {
    if (this.socket && this.socket.connected) {
      while (this.pendingMessages.length > 0) {
        const { event, data } = this.pendingMessages.shift();
        this.socket.emit(event, data);
      }
    }
  }
}

export default new SocketClient();
