import { Box, Typography } from '@mui/material';
import React from 'react';

const ProblemLanguages = ({ languages }) => {
  return (
    <Box mt={3}>
      <Typography sx={{ fontWeight: '400', mb: 2 }} variant="body1">
        You can code the problem in the languages-{' '}
      </Typography>
      {languages?.map((lang, index) => {
        return (
          <Typography
            key={index}
            ml={1}
            sx={{
              fontSize: { xs: '14px' },
              textTransform: { xs: 'none' },
              fontWeight: '400',
              mb: 1,
            }}
            variant="body2"
          >
            {index + 1}. {lang}
          </Typography>
        );
      })}
    </Box>
  );
};

export default ProblemLanguages;
