import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import OptionsComponent from '../../components/CodingContests/ContestProblem/OptionsComponent';

function MCQProblemPage({
  problem,
  prevQuestion,
  nextQuestion,
  handleNavigationPrevQuestion,
  handleNavigationNextQuestion,
  problemId,
  type,
  contestId,
  submitProblem,
  setSubmissionData,
  formatRemainingTime,
  remainingTime,
}) {
  const [selectedOption, setSelectedOption] = useState([]);
  const addDataSubmission = (UpdatedData) => {
    setSubmissionData(UpdatedData);
  };

  const handleOptionChange = (optionId) => {
    let data = { problemId: problemId, content: [] };
    setSelectedOption((prevSelected) => {
      const isMAQ = type === 'MAQ';
      const currentChoices = prevSelected?.content?.choiceIds || [];

      if (optionId === '') {
        const clearedChoice = {
          ...prevSelected,
          problemId: problemId,
          content: { choiceIds: [] },
        };
        data = clearedChoice;
        addDataSubmission(clearedChoice);
        return clearedChoice;
      }

      const updatedChoice = isMAQ
        ? currentChoices?.includes(optionId)
          ? {
              ...prevSelected,
              problemId: problemId,
              content: {
                choiceIds: currentChoices.filter((id) => id !== optionId),
              },
            }
          : {
              ...prevSelected,
              problemId: problemId,
              content: {
                choiceIds: [...currentChoices, optionId],
              },
            }
        : currentChoices.includes(optionId)
        ? {
            ...prevSelected,
            problemId: problemId,
            content: {
              choiceIds: [],
            },
          }
        : {
            ...prevSelected,
            problemId: problemId,
            content: {
              choiceIds: [optionId],
            },
          };
      data = updatedChoice;
      addDataSubmission(updatedChoice);
      return updatedChoice;
    });
  };
  useEffect(() => {
    let choiceIds = [];
    const elements =
      Array.isArray(problem?.submission?.content) &&
      problem?.submission?.content?.every(
        (item) => typeof item === 'string' || typeof item === 'number'
      );
    if (
      typeof problem?.submission?.content === 'string' &&
      problem?.submission?.content.trim() !== ''
    ) {
      try {
        choiceIds = JSON.parse(problem?.submission?.content);
      } catch (error) {}
    } else if (elements) {
      choiceIds = problem?.submission?.content;
    } else if (Array.isArray(problem?.submission?.content)) {
      choiceIds = problem?.submission?.content?.map((item) => item?.choiceId);
    }
    setSelectedOption({
      problemId: problem?.submission?.problemId || problemId,
      content: {
        choiceIds: choiceIds || [],
      },
    });

    return () => {
      setSelectedOption([]);
    };
  }, [problem, problemId]);
  useEffect(() => {
    setSubmissionData(selectedOption);
  }, [problem, selectedOption]);

  return (
    <Box
      sx={{
        bgcolor: '#F3F5FC',
        height: { xs: 'auto', md: '100dvh' },
      }}
    >
      <Grid
        container
        sx={{
          p: { xs: 2, md: 3, lg: 6 },
          m: 0,
          bgcolor: '#F3F5FC',
        }}
      >
        <Grid
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            height: '48px!important',
            display: { xs: 'none', md: 'flex' },
            bgcolor: '#F3F5FC',
            border: '1px solid #eee',
            borderRadius: '8px 8px 0px 0px',
            py: 1,
            px: 2,
            position: 'sticky',
            top: 0,
            zIndex: 2,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: '500',
              mb: 0,
              fontSize: { md: '14px', mds: '16px' },
            }}
          >
            {type === 'MCQ' ? 'Select the Correct Answer' : 'Select the Correct Answers'}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: '500',
              mb: 0,
              fontSize: { md: '13px', mds: '14px' },
            }}
          >
            Time Remaining: {formatRemainingTime(remainingTime)}
          </Typography>
          <Box
            sx={{ display: 'flex', justifyContent: 'space-between', gap: { xs: 2, md: 1, mds: 2 } }}
          >
            <Button
              variant="outlined"
              onClick={() => handleNavigationPrevQuestion(contestId, selectedOption, type)}
              size="small"
              disabled={!prevQuestion}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleNavigationNextQuestion(contestId, selectedOption, type)}
              size="small"
              disabled={!nextQuestion}
            >
              Next
            </Button>
            <Button
              variant="contained"
              onClick={() => submitProblem(contestId, selectedOption, type)}
              size="small"
            >
              Submit
            </Button>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              p: { xs: 0 },
              bgcolor: '#fff',
              borderRadius: '8px',
              overflowY: 'auto',
              maxHeight: { xs: 'auto', md: '100%' },
              border: '1px solid #eee',
              borderTop: { md: 'none' },
              borderTopLeftRadius: { md: '0px' },
              borderRight: {
                md: '0px',
              },
              borderTopRightRadius: {
                md: '0px',
              },
              borderBottomRightRadius: {
                md: '0px',
              },
              position: 'relative',
            }}
          >
            <Grid
              item
              xs={12}
              mt={{ xs: 0 }}
              sx={{
                height: { xs: 'auto', sm: '48px!important' },
                px: 2,
                py: 1,
                bgcolor: '#F3F5FC',
                borderBottom: '1px solid #eee',
                position: 'sticky',
                top: 0,
                zIndex: 2,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '500',
                  mb: { xs: 0.3, sm: 0 },
                  fontSize: { xs: '13px', sm: '16px' },
                }}
              >
                {type === 'MCQ' ? 'Select the Correct Answer' : 'Select the Correct Answers'}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: '500',
                  mb: 0,
                  fontSize: { xs: '13px', sm: '14px' },
                }}
              >
                Time Remaining: {formatRemainingTime(remainingTime)}
              </Typography>
            </Grid>
            <Grid item sx={{ p: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1.5,
                  fontWeight: '500',
                }}
              >
                {problem?.title}
              </Typography>
              {problem?.statement?.map((StatementItem, i) => (
                <Typography
                  key={i}
                  variant="subtitle2"
                  sx={{
                    fontWeight: '400',
                    mb: 1,
                    pl: 1,
                    borderLeft: '1px solid #eee',
                  }}
                >
                  {StatementItem}
                </Typography>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            sx={{
              pl: { xs: 0 },
              pt: '0!important',
              bgcolor: '#fff',
              borderRadius: '8px',
              overflowY: 'auto',
              maxHeight: { xs: 'auto', md: '100%' },
              border: '1px solid #eee',
              borderTop: { md: 'none' },
              borderTopRightRadius: { md: '0px' },
              borderTopLeftRadius: {
                md: '0px',
              },
              borderBottomLeftRadius: {
                md: '0px',
              },
              borderLeft: {
                md: '0px',
              },
              position: 'relative',
            }}
          >
            <Grid
              item
              xs={12}
              mt={{ xs: 0 }}
              sx={{
                px: 2,
                py: 1,
                height: '48px!important',
                bgcolor: '#F3F5FC',
                borderBottom: '1px solid #eee',
                position: 'sticky',
                top: 0,
                zIndex: 2,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              mb={2}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => handleNavigationPrevQuestion(contestId, selectedOption, type)}
                  size="small"
                  disabled={!prevQuestion}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleNavigationNextQuestion(contestId, selectedOption, type)}
                  size="small"
                  disabled={!nextQuestion}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  onClick={() => submitProblem(contestId, selectedOption, type)}
                  size="small"
                >
                  Submit
                </Button>
              </Box>
            </Grid>
            <OptionsComponent
              options={problem?.data || []}
              selectedOption={selectedOption}
              type={problem?.type}
              onOptionChange={handleOptionChange}
              problemId={problemId}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MCQProblemPage;
