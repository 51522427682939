import React, { useState, useEffect, useRef } from 'react';
import { organizationAPI } from '../../../api/requests/organization/organizationAPI';
import Skeletons from '../../Skeleton/Skeletons';
import OrganizationMembers from './OrganizationMembers/MembersList';
import Product from '../../ProductCarousel/Product';
import OrganizationDetails from './OrganizationDetails/index';
import './ViewOrganizations.css';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, IconButton, Typography } from '@mui/material';
import { Colors } from '../../../theme/Theme';
import InvitesMemberListDetails from '../OrganizationInvites/InvitesMemberList/InvitesMemberListDetails';

function ViewOrganizationPopup({ selectedOrgName, setSelectedOrgName }) {
  const [isLoading, setLoading] = useState(true);
  const [myCoursesData, setMyCoursesData] = useState([]);
  const [orgDetails, setOrgDetails] = useState(null);
  const [orgMemberData, setOrgMemberData] = useState(null);
  const popupRef = useRef(null);

  const fetchData = async () => {
    try {
      setLoading(true);
      const orgResponse = await organizationAPI.searchViewOrganizationByName(selectedOrgName);
      setOrgDetails(orgResponse.data);
      const orgMembers = orgResponse.data.members;
      setOrgMemberData(orgMembers);
      setMyCoursesData(orgResponse.data.courses);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSelectedOrgName(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.body.classList.remove('View-open');
    };
  }, []);

  const renderProfilePhoto = (user) => {
    if (user.profilePhotoLink) {
      return <img style={{ width: '40px', height: '40px' }} src={user.profilePhotoLink} alt="" />;
    } else {
      const firstNameInitial = user.firstName?.charAt(0) || '';
      const lastNameInitial = user.lastName?.charAt(0) || '';

      return (
        <div className="initials-container">
          <span>{firstNameInitial}</span>
          <span>{lastNameInitial}</span>
        </div>
      );
    }
  };

  return isLoading ? (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
      }}
    >
      <Skeletons type="CircularLoader" />
    </div>
  ) : (
    <div className="View">
      <div className="View-main-withoutSidebar" ref={popupRef}>
        <IconButton
          onClick={() => {
            setSelectedOrgName(null);
          }}
          sx={{ position: 'absolute', top: '8px', right: '8px', zIndex: 4 }}
        >
          <CloseRoundedIcon />
        </IconButton>
        <OrganizationDetails
          OrgData={orgDetails}
          orgMemberData={orgMemberData}
          myCoursesData={myCoursesData}
          orgName={selectedOrgName}
        />
        <div className="viewSecondRow">
          <div className="viewSecondRow-orgDetails">
            <div className="viewSecondRow-orgDetailsMembers">
              <Typography
                variant="h6"
                sx={{
                  mt: 0,
                  mb: 1,
                  color: Colors.headingPrimary,
                  fontWeight: '500',
                }}
              >
                Members :
              </Typography>
              <Box
                sx={{
                  borderTop: '1px solid #e1e1e1',
                  maxHeight: '300px',
                  overflowY: 'scroll',
                  scrollbarWidth: 'thin',
                  scrollbarColor: `${Colors.primary} #e4e4e4`,
                }}
              >
                {orgMemberData?.map((user) => (
                  <Box
                    key={user.username}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <div>
                      <div
                        className="viewSecondRow-orgDetailsMemberBlock-Email"
                        style={{
                          backgroundColor: 'transparent',
                          borderBottom: '1px solid rgba(225, 225, 225, 1)',
                          borderRadius: '0px',
                        }}
                      >
                        <div className="viewSecondRow-orgDetailsMemberBlockDetsCont">
                          <div className="viewSecondRow-orgDetailsMemberBlockDetsCont-Email">
                            {renderProfilePhoto(user)}
                            <div className="viewSecondRow-orgDetailsMemberBlockDets viewSecondRow-orgDetailsMemberBlockDets-Invite">
                              <Typography
                                sx={{
                                  display: '-webkit-box',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  WebkitBoxOrient: 'vertical',
                                  WebkitLineClamp: 1,
                                  fontWeight: '500',
                                }}
                                variant="subtitle2"
                                color="#021148e6"
                              >
                                {user?.firstName} {user?.lastName}
                              </Typography>
                              <Typography
                                sx={{
                                  display: 'inline-block',
                                  maxWidth: '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  fontWeight: '400',
                                  color: '#3e434be6',
                                }}
                                variant="subtitle2"
                              >
                                {user?.username}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
              </Box>
            </div>
          </div>
          <div className="viewSecondRow-orgCoursesDiv">
            <Product
              forSuggestedOrgPopup={'true'}
              title={'Courses'}
              dataRender={myCoursesData}
              loading={isLoading}
              disableHover={false}
              showDescription={true}
            />
          </div>
        </div>
        <div className="view-RequestToJoinBtnDiv"></div>
      </div>
    </div>
  );
}

export default ViewOrganizationPopup;
