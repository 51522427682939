import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ProblemEndpoints } from '../../components/CodingContests/ContestProblem/ProblemEndpoints';
import ProblemConstraints from '../../components/CodingContests/ContestProblem/ProblemConstraints';
import ProblemMoreInfo from '../../components/CodingContests/ContestProblem/ProblemMoreInfo';
import SampleVideoLinks from '../../components/CodingContests/ContestProblem/SampleVideoLinks';
import ProblemLanguages from '../../components/CodingContests/ContestProblem/ProblemLanguages';
import SubmissionUiSolution from '../../components/CodingContests/ContestProblem/SubmissionUiSolution';
import { candidateAPI } from '../../api/requests/contests/CandidateAPI';

const UiProblem = ({
  problem,
  prevQuestion,
  nextQuestion,
  handleNavigationPrevQuestion,
  handleNavigationNextQuestion,
  type,
  contestId,
  problemId,
  submitProblem,
  setSubmissionData,
  formatRemainingTime,
  remainingTime,
}) => {
  const [content, setContent] = useState({
    githubRepo: '',
    language: '',
    githubUsername: '',
  });
  const [submissionResponse, setSubmissionResponse] = useState('');

  const handleContentChange = (field, value) => {
    setContent((prevContent) => ({
      ...prevContent,
      [field]: value,
    }));
    setSubmissionData({ problemId: problemId, content: content });
  };

  useEffect(() => {
    if (problem?.submission?.content) {
      const { githubRepo, language, githubUsername, message } = problem.submission.content;
      if (githubRepo || language || githubUsername) {
        setContent({ githubRepo, language, githubUsername });
      }

      if (message) {
        setSubmissionResponse(message);
      }
    }
    return () => {
      setContent({
        githubRepo: '',
        language: '',
        githubUsername: '',
      });
      setSubmissionResponse('');
    };
  }, [problem, problemId]);

  useEffect(() => {
    setSubmissionData({ problemId, content: content });
  }, [problem, content]);

  const postData = {
    problemId: problem?.id,
    content: {
      githubRepo: content.githubRepo,
      language: content.language,
      githubUsername: content.githubUsername,
    },
  };

  const submitCode = async () => {
    try {
      const res = await candidateAPI.problemSubmission(contestId, postData);
      setSubmissionResponse(res?.message);
      const { deployedLink, ...updatedContent } = res;
      setContent((prevContent) => ({
        ...prevContent,
        message: res?.message,
      }));
    } catch (err) {}
  };
  return (
    <Box
      sx={{
        bgcolor: '#F3F5FC',
        height: { xs: 'auto', md: '100dvh' },
      }}
    >
      <Grid
        container
        sx={{
          flexGrow: 1,
          p: { xs: 2, md: 3, lg: 6 },
          m: 0,
          bgcolor: '#F3F5FC',
        }}
      >
        <Grid
          xs={12}
          alignItems="center"
          justifyContent="space-between"
          sx={{
            display: { xs: 'none', md: 'flex' },
            bgcolor: '#F3F5FC',
            border: '1px solid #eee',
            borderRadius: '8px 8px 0px 0px',
            py: 1,
            px: 2,
            position: 'sticky',
            top: 0,
            zIndex: 2,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: '500',
              mb: 0,
              fontSize: { md: '14px', mds: '16px' },
            }}
          >
            Problem Instructions
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: '500',
              mb: 0,
              fontSize: { md: '13px', mds: '14px' },
            }}
          >
            Time Remaining: {formatRemainingTime(remainingTime)}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Button
              variant="outlined"
              onClick={() => handleNavigationPrevQuestion(contestId, postData, type)}
              size="small"
              disabled={!prevQuestion}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleNavigationNextQuestion(contestId, postData, type)}
              size="small"
              disabled={!nextQuestion}
            >
              Next
            </Button>
            <Button
              variant="contained"
              onClick={() => submitProblem(contestId, postData, type)}
              size="small"
            >
              Submit
            </Button>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              p: { xs: 0 },
              bgcolor: '#fff',
              overflowY: 'auto',
              maxHeight: { xs: 'auto', md: '100%' },
              border: '1px solid #eee',
              borderRadius: '8px',
              borderTop: { md: 'none' },
              borderRight: {
                md: '0px',
              },
              borderTopLeftRadius: { md: '0px' },
              borderTopRightRadius: { md: '0px' },
              borderBottomRightRadius: { md: '0px' },
              position: 'relative',
            }}
          >
            <Grid
              item
              xs={12}
              mt={{ xs: 0 }}
              sx={{
                height: { xs: 'auto', sm: '48px!important' },
                px: 2,
                py: 1,
                bgcolor: '#F3F5FC',
                borderBottom: '1px solid #eee',
                position: 'sticky',
                top: 0,
                zIndex: 2,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'space-between',
                alignItems: { xs: 'flex-start', sm: 'center' },
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '500',
                  mb: { xs: 0.3, sm: 0 },
                  fontSize: { xs: '13px', sm: '16px' },
                }}
              >
                Problem Instructions
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: '500',
                  mb: 0,
                  fontSize: { xs: '13px', sm: '14px' },
                }}
              >
                Time Remaining: {formatRemainingTime(remainingTime)}
              </Typography>
            </Grid>
            <Grid item sx={{ p: 2 }}>
              <Typography
                variant="subtitle1"
                sx={{
                  mb: 1.5,
                  fontWeight: '500',
                }}
              >
                {problem?.title}
              </Typography>
              {problem?.statement?.map((StatementItem, i) => (
                <Typography
                  key={i}
                  variant="subtitle2"
                  sx={{
                    fontWeight: '400',
                    mb: 1,
                    pl: 1,
                    borderLeft: '1px solid #eee',
                  }}
                >
                  {StatementItem}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12} sx={{ px: '16px', height: 'auto' }}>
              <Grid>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: '500',
                    mb: 1,
                  }}
                >
                  Hints
                </Typography>
                {problem?.data?.hints?.map((StatementItem, i) => (
                  <Typography
                    key={i}
                    variant="subtitle2"
                    sx={{
                      fontWeight: '400',
                      mb: 1,
                      pl: 1,
                      borderLeft: '1px solid #eee',
                    }}
                  >
                    {StatementItem}
                  </Typography>
                ))}
              </Grid>
              <SampleVideoLinks sampleVideo={problem?.data?.sampleVideo} />
              <ProblemLanguages languages={problem?.data?.languages} />
              <ProblemConstraints constraints={problem?.data?.constraints} />
              <ProblemMoreInfo moreInfo={problem?.data?.moreInfo} />
              <ProblemEndpoints backendId={problem?.data?.applicationId} />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            mt={{ xs: 2, md: 0 }}
            sx={{
              pl: { xs: 0 },
              pt: '0!important',
              bgcolor: '#fff',
              borderRadius: '8px',
              overflowY: 'auto',
              maxHeight: { xs: 'auto', md: '100%' },
              border: '1px solid #eee',
              borderTop: { md: 'none' },
              borderTopRightRadius: { md: '0px' },
              borderTopLeftRadius: {
                md: '0px',
              },
              borderBottomLeftRadius: {
                md: '0px',
              },
              borderLeft: {
                md: '0px',
              },
              position: 'relative',
            }}
          >
            <Grid
              item
              xs={12}
              mt={{ xs: 0 }}
              sx={{
                px: 2,
                py: 1,
                height: '48px!important',
                bgcolor: '#F3F5FC',
                borderBottom: '1px solid #eee',
                position: 'sticky',
                top: 0,
                zIndex: 2,
                display: { xs: 'flex', md: 'none' },
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
              mb={2}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                <Button
                  variant="outlined"
                  onClick={() => handleNavigationPrevQuestion(contestId, postData, type)}
                  size="small"
                  disabled={!prevQuestion}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => handleNavigationNextQuestion(contestId, postData, type)}
                  size="small"
                  disabled={!nextQuestion}
                >
                  Next
                </Button>
                <Button
                  variant="contained"
                  onClick={() => submitProblem(contestId, postData, type)}
                  size="small"
                >
                  Submit
                </Button>
              </Box>
            </Grid>
            <SubmissionUiSolution
              problem={problem}
              content={content}
              onContentChange={handleContentChange}
              submitCode={submitCode}
              submissionResponse={submissionResponse}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UiProblem;
