import {
  Avatar,
  Box,
  Button,
  Typography,
  TextField,
  Tabs,
  Tab,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { ChatApi } from '../../../../api/requests/chat/ChatApi';
import AddMembers from './AddMembers';
import BigLoader from '../../../Skeleton/BigLoader';
import { handleAlert } from '../../../../utils/handleAlert';
import CloseIcon from '@mui/icons-material/Close';
import { CommonColors } from '../../../../utils/colors';
import MemberActionPopUp from '../../../PopUp/MemberActionPopUp';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChatIcon from '@mui/icons-material/Chat';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { Colors } from '../../../../theme/Theme';

export default function GroupDetails({
  chat,
  setOpenDetails,
  setGroupList,
  groupList,
  groupMembers,
  setGroupMembers,
  openDetails,
}) {
  const bgColor = CommonColors.primaryBgColor;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveBtnLoading, setsaveBtnLoading] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupDescription, setGroupDescription] = useState('');
  const [groupDetails, setGroupDetails] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [userId, setUserId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [initialGroupName, setInitialGroupName] = useState('');
  const [initialGroupDescription, setInitialGroupDescription] = useState('');
  const memberActionRef = useRef(null);
  const [tabIndex, setTabIndex] = useState(0);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  const GetUser = () => {
    const token = localStorage.getItem('Token');

    if (token) {
      const decodedJwt = parseJwt(token);
      setUserId(decodedJwt.id);
    }
    return false;
  };

  useEffect(() => {
    GetUser();
  }, []);

  const handleSaveDetails = async (e) => {
    e.preventDefault();
    setsaveBtnLoading(true);
    try {
      const updateRequestBody = {
        name: groupName,
        description: groupDescription,
      };
      await ChatApi.updateGroupDetails(groupDetails.groupChatData.id, updateRequestBody);
      handleAlert('Details Updated Successfully', 'success');
      setInitialGroupName(groupName);
      setInitialGroupDescription(groupDescription);
      setEditMode(false);
    } catch (error) {
      handleAlert('error.message', 'warning');
    }
    setsaveBtnLoading(false);
  };

  const handleRemove = async (member) => {
    try {
      await ChatApi.removeUserFromGroup(groupDetails?.groupChatData.id, [member?.user_id]);
      setGroupMembers((prevMembers) => prevMembers.filter((m) => m.user_id !== member.user_id));
      setSelectedMember(null);
    } catch (error) {
      handleAlert(error.message, 'warning');
    }
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const getGroupDetails = async () => {
    try {
      const response = await ChatApi.getGroupById(
        chat.groupChatData?.id ? chat.groupChatData.id : chat.id,
        1
      );
      setGroupDetails(response);
      setIsAdmin(response.isGroupAdmin);
      setGroupName(response.groupChatData.name);
      setGroupDescription(response.groupChatData.description);
      setInitialGroupName(response.groupChatData.name);
      setInitialGroupDescription(response.groupChatData.description);
    } catch (error) {
      handleAlert(error.message, 'warning');
    }
  };

  useEffect(() => {
    setLoading(true);
    getGroupDetails().finally(() => setLoading(false));
  }, [chat]);

  const handleRoleUpdate = (userId, newRole) => {
    setGroupMembers((prevMembers) =>
      prevMembers.map((member) => {
        if ((member.user_id ? member.user_id : member.id) === userId) {
          return { ...member, role: newRole };
        }
        return member;
      })
    );
  };

  const handleMenuOpen = (event, member) => {
    setSelectedMember(member);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSelectedMember(null);
    setMenuAnchorEl(null);
  };

  const handleCancelEdit = () => {
    setGroupName(initialGroupName);
    setGroupDescription(initialGroupDescription);
    setEditMode(false);
  };

  const handleChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  if (loading)
    return (
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: { xs: '55%', md: '50%' },
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '40%',
          height: '50%',
          alignItems: 'center',
          zIndex: '1',
        }}
      >
        <BigLoader />
      </Box>
    );

  return (
    <Dialog open={openDetails} onClose={() => setOpenDetails(false)} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Group Info</Typography>
        <IconButton onClick={() => setOpenDetails(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box>
        <DialogContent dividers sx={{ pt: 0, borderBottom: 'none' }}>
          <Tabs value={tabIndex} onChange={handleChange} variant="fullWidth" sx={{ width: '100%' }}>
            <Tab label="Details" sx={{ textTransform: 'capitalize' }} />
            <Tab label="Members" sx={{ textTransform: 'capitalize' }} />
            {isAdmin && <Tab label="Add Members" sx={{ textTransform: 'capitalize' }} />}
          </Tabs>

          {tabIndex === 0 && (
            <Box sx={{ width: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  width: '-webkit-fill-available',
                  mt: 2.5,
                }}
              >
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  id="outlined-helperText"
                  label="Group Name"
                  onChange={(e) => setGroupName(e.target.value)}
                  value={groupName}
                  disabled={!editMode}
                  InputProps={{
                    readOnly: !editMode,
                  }}
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  rows={3}
                  sx={{
                    mt: 2.5,
                  }}
                  id="outlined-helperText"
                  label="Group Description"
                  onChange={(e) => setGroupDescription(e.target.value)}
                  value={groupDescription}
                  disabled={!editMode}
                  InputProps={{
                    readOnly: !editMode,
                  }}
                />
              </Box>
            </Box>
          )}
          {tabIndex === 1 && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  overflowY: 'auto',
                  height: { xs: 'auto', md: 'auto' },
                  maxHeight: '250px',
                  width: '100%',
                  marginTop: 'auto',
                  scrollbarWidth: 'thin',
                  mt: 0,
                }}
              >
                {groupMembers &&
                  groupMembers.map((member, index) => (
                    <Box
                      key={index}
                      display={'flex'}
                      width={'100%'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      sx={{ py: 1.5, borderBottom: '1px solid rgb(226, 226, 226)' }}
                    >
                      <Box display={'flex'} gap={1} alignItems={'center'}>
                        {member.profilePhotoLink ? (
                          <Avatar
                            src={member.profilePhotoLink}
                            style={{
                              backgroundColor: member.profilePhotoLink ? 'transparent' : bgColor,
                              color: member.profilePhotoLink ? 'black' : 'white',
                              width: '30px',
                              height: '30px',
                              fontSize: '19px',
                            }}
                          >
                            {member.firstName.charAt(0).toUpperCase()}
                          </Avatar>
                        ) : (
                          <Avatar
                            style={{
                              backgroundColor: member.profilePhotoLink ? 'transparent' : bgColor,
                              color: member.profilePhotoLink ? 'black' : 'white',
                              width: '30px',
                              height: '30px',
                            }}
                          >
                            {member.firstName.charAt(0).toUpperCase()}
                          </Avatar>
                        )}
                        <Typography
                          key={index}
                        >{`${member.firstName} ${member.lastName}`}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '5px',
                          alignItems: 'center',
                        }}
                      >
                        {member.role === 'ADMIN' && (
                          <Typography
                            color="primary"
                            sx={{
                              mr: 1,
                            }}
                          >
                            Admin
                          </Typography>
                        )}
                        {member.user_id !== userId && isAdmin && (
                          <IconButton
                            sx={{
                              p: 0.5,
                            }}
                            onClick={(e) => handleMenuOpen(e, member)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  ))}
              </Box>
              {isAdmin && (
                <MemberActionPopUp
                  ref={memberActionRef}
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  onClose={handleMenuClose}
                  member={selectedMember}
                  isAdmin={isAdmin}
                  groupDetails={groupDetails}
                  onRemove={handleRemove}
                  onUpdateRole={handleRoleUpdate}
                />
              )}
            </>
          )}
          {tabIndex === 2 && isAdmin && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AddMembers
                groupList={groupList}
                setGroupList={setGroupList}
                groupDetails={groupDetails}
                setOpen={setOpen}
                groupMembers={groupMembers}
                setGroupMembers={setGroupMembers}
                setTabIndex={setTabIndex}
              />
            </Box>
          )}
        </DialogContent>
        {tabIndex === 0 && isAdmin && (
          <DialogActions sx={{ px: 3, pt: 0, pb: 2 }}>
            {!editMode ? (
              <Button color="primary" onClick={() => setEditMode(true)} variant="outlined">
                Edit
              </Button>
            ) : (
              <>
                <Button color="primary" onClick={handleCancelEdit} variant="outlined">
                  Cancel
                </Button>
                <LoadingButton
                  color="primary"
                  onClick={handleSaveDetails}
                  loading={saveBtnLoading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  disabled={
                    groupName === initialGroupName && groupDescription === initialGroupDescription
                  }
                >
                  <span>Save</span>
                </LoadingButton>
              </>
            )}
          </DialogActions>
        )}
      </Box>
    </Dialog>
  );
}
