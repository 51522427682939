import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Typography,
  IconButton,
  Button,
  TextField,
  Tooltip,
} from '@mui/material';
import '../chat.css';
import { getOrgName } from '../../../../utils/appendOrgQuery';
import { ChatApi } from '../../../../api/requests/chat/ChatApi';
import GetValidatedTokenData from '../../../../utils/helper';
import { CommonColors } from '../../../../utils/colors';
import ManualAvatar from '../../../Avatar/Avatar';
import UserDetails from './UserDetails';
import EmojiPicker from 'emoji-picker-react';
import ChatInputField from '../../../InputField/ChatInputField';
import { handleAlert } from '../../../../utils/handleAlert';
import Skeletons from '../../../Skeleton/Skeletons';
import MediaMessageBox from '../../../MessageBox/MediaMessageBox';
import { ParseMessageWithEmojis } from '../../../../utils/emojiChecker';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ConfirmationDialog from '../../../Dialogbox/ConfirmationDialog';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from '../SearchBar';
import ChatMessageLoader from '../../../Skeleton/ChatMessageLoader';
import UserChatImage from '../../../../assets/ChatImages/UserChat.webp';
import SuggestedFeatures from '../SuggestedFeatures';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import addNotification from 'react-push-notification';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import MessageActions from '../../../MessageActions';
import { FormattedDateTime } from '../../../../utils/dateFormater';
import ChatReactionBox from '../../../MessageBox/ChatReactionBox';
import SocketClient from '../../../../socket';

export default function UserChatWindow({ setUserList, draftChat }) {
  const [socket, setSocket] = useState(null);

  const [searchParams1] = useSearchParams();
  const chatId = searchParams1.get('chatid');
  useEffect(() => {
    const getSocket = async () => {
      setSocket(await SocketClient.getSocket());
    };
    getSocket();
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const getChatIdandName = () => {
    const getQueryParam = (param) => {
      const params = new URLSearchParams(location.search);
      return params.get(param) || '';
    };
    return { chatId: getQueryParam('chatid'), name: getQueryParam('name') } ?? null;
  };

  const userName = searchParams.get('name');
  const bgColor = CommonColors.primaryBgColor;
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [uploadLoader, setUploadLoader] = useState(false);
  const [newMessage, setNewMessage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOlder, setIsLoadingOlder] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [currentChatId, setCurrentChatId] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [reactionsData, setReactionsData] = useState([]);

  const addMessage = (newMessages) => {
    setMessages((prevMessages) => {
      const messagesArray = Array.isArray(newMessages) ? newMessages : [newMessages];
      const newArr = prevMessages.concat(messagesArray);
      const filterMessages = newArr.filter((msg) => msg.chat_id === getChatIdandName().chatId);
      const messageMap = new Map(filterMessages.map((msg) => [msg.id, msg]));
      filterMessages.forEach(
        (msg) => msg.chat_id === getChatIdandName().chatId && messageMap.set(msg.id, msg)
      );
      const updatedMessagesArray = Array.from(messageMap.values());
      const sortedMessages = updatedMessagesArray.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      return sortedMessages;
    });
  };

  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const orgName = getOrgName();
  const [replyTo, setReplyTo] = useState(null);
  const inputRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
    setShowScrollButton(false);
  };

  useEffect(() => {
    if (!isLoadingOlder && messages.length > 0) {
      const container = messagesContainerRef.current;
      if (container) {
        const isContentScrollable = container.scrollHeight > container.clientHeight;
        setIsScrollable(isContentScrollable);

        const lastMessage = messages[messages.length - 1];
        if (
          lastMessage.sender_id === currentUserInfo.id ||
          (newMessage && newMessage.sender_id !== currentUserInfo.id)
        ) {
          scrollToBottom();
        }
      }
    }
  }, [messages, isLoadingOlder, newMessage]);

  const onEmojiClick = (event) => {
    setInputValue((prevInputValue) => prevInputValue + event.emoji);
  };

  const updateMessages = (message) => {
    if (
      Object.prototype.hasOwnProperty.call(message, 'created_at') &&
      (getChatIdandName().chatId === message?.chat_id || currentChatId === message?.chat_id)
    ) {
      addMessage(message);
      setIsLoadingOlder(false);
      setUserList((prevUserList) =>
        prevUserList.map((user) => {
          const userId = user?.chatData ? user.senderData.id : user.id;
          const chatId = userDetails?.chatData
            ? userDetails.senderData.id
            : getChatIdandName().chatId;
          if (userId === getChatIdandName().chatId) {
            return {
              ...user,
              lastMessageData: {
                ...user.lastMessageData,
                message: message.message,
              },
            };
          }
          return user;
        })
      );
      if (message.sender_id === currentUserInfo.id) {
        scrollToBottom();
      }
    } else if (
      Object.prototype.hasOwnProperty.call(message, 'created_at') &&
      messages.length === 0
    ) {
      addMessage(message);
      setIsLoadingOlder(false);
      setUserList((prevUserList) =>
        prevUserList.map((user) => {
          const userId = user.chatData ? user.senderData.id : user.senderData.id;
          const chatId = userDetails?.chatData
            ? userDetails.senderData.id
            : getChatIdandName().chatId;
          if (userId === getChatIdandName().chatId) {
            return {
              ...user,
              lastMessageData: {
                ...user.lastMessageData,
                message: message.message,
              },
            };
          }
          return user;
        })
      );
      setCurrentChatId(message.chat_id);
      navigate(`/org/${orgName}/chat?chatid=${message.chat_id}&name=${userName}`);
    }
  };

  useEffect(() => {
    if (getChatIdandName().chatId !== 'newChatId') {
      scrollToBottom();
    }
  }, [getChatIdandName().chatId]);

  useEffect(() => {
    if (newMessage) {
      updateMessages(newMessage);
      if (newMessage.sender_id !== currentUserInfo.id) {
        setShowScrollButton(true);
      }
    }
  }, [newMessage]);

  const fetchMessages = useCallback(
    async (pageNum) => {
      if (isLoading) return;
      setIsLoading(true);
      setIsLoadingOlder(true);
      try {
        const id = getChatIdandName().chatId;
        if (!id || id === 'newChatId') {
          setIsLoading(false);
          return;
        }
        setReactionsData([]);
        const container = messagesContainerRef.current;
        const scrollHeight = container.scrollHeight;
        const scrollTop = container.scrollTop;
        const response = await ChatApi.getUserChatDetails(id, pageNum);
        if (!response.messages) {
          setMessages([]);
          setHasMore(false);
          return;
        }

        if (!Array.isArray(response.messages)) {
          handleAlert('Error fetching messages', 'error');
          return;
        }
        setUserDetails(response);
        response?.messages.map((item) => {
          if (item?.reactionsData?.length > 0) {
            setReactionsData((prev) => [
              ...prev,
              { msgId: item.id, emojiData: item.reactionsData },
            ]);
          }
        });
        addMessage(response.messages);
        setPage(pageNum);
        setHasMore(pageNum < response.totalPages);
        requestAnimationFrame(() => {
          const newScrollHeight = container.scrollHeight;
          container.scrollTop = newScrollHeight - scrollHeight + scrollTop;
        });
      } catch (error) {
        handleAlert(error.message, 'error');
      } finally {
        setIsLoading(false);
        setIsLoader(false);
        setIsLoadingOlder(false);
      }
    },
    [getChatIdandName().chatId]
  );

  useEffect(() => {
    setMessages([]);
    setPage(1);
    setIsLoader(true);
    if (getChatIdandName().chatId) {
      fetchMessages(1);
    }
  }, [getChatIdandName().chatId]);

  const handleScroll = useCallback(() => {
    const container = messagesContainerRef.current;
    if (container) {
      const isContentScrollable = container.scrollHeight > container.clientHeight;
      setIsScrollable(isContentScrollable);

      if (container.scrollTop === 0 && hasMore && !isLoading) {
        fetchMessages(page + 1);
      }

      if (isContentScrollable) {
        const atBottom =
          container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
        setShowScrollButton(!atBottom);
      } else {
        setShowScrollButton(false);
      }
    }
  }, [fetchMessages, page, hasMore, isLoading]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const handleDeleteUserChat = async () => {
    try {
      const response = await ChatApi.deleteUserChat(getChatIdandName().chatId);
      setUserList((prevUsers) =>
        prevUsers.filter((item) => item.senderData.id !== userDetails.senderData.id)
      );
      handleAlert(response.message, 'success');
      navigate(`/org/${orgName}/chat`);
    } catch (error) {
      handleAlert('Something went wrong', 'error');
    }
  };
  const currentUserInfo = GetValidatedTokenData();
  const notify = (username, message) => {
    addNotification({
      title: username,
      message: message,
      duration: 5000,
      native: true,
    });
  };

  useEffect(() => {
    socket?.on('message', (msg) => {
      setNewMessage(msg[0]);
      if (currentUserInfo.id !== msg?.[0]?.sender_id) {
        notify(msg?.[0]?.username, msg?.[0]?.message);
      }
      setShowScrollButton(true);
    });

    return () => {
      socket?.off('message');
      socket?.off('disconnect');
    };
  }, [socket]);

  const [repliedMessages, setRepliedMessages] = useState(); // Modified state to include messages with reply details
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingMessageText, setEditingMessageText] = useState('');
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [showReactEmoji, setShowReactEmoji] = useState(true);

  const handleSendMessage = () => {
    setShowEmojiPicker(false);
    if (!inputValue.trim() && !uploadedFile) return;
    if (socket) {
      let messageData = {
        senderId: currentUserInfo.id,
        message: uploadedFile ? uploadedFile.file.name : inputValue,
        organization: getOrgName(),
        replyId: replyTo ? replyTo?.id : null,
      };

      if (uploadedFile) {
        messageData = {
          ...messageData,
          type: 'FILE',
          url: uploadedFile.url,
        };
      }
      const receiverId =
        getChatIdandName().chatId === 'newChatId'
          ? draftChat?.senderData?.id
          : userDetails?.senderData?.id;
      messageData.receiverId = receiverId;
      setRepliedMessages(messageData);
      socket?.emit('message', messageData);
      updateMessages(messageData);
      setInputValue('');
      setUploadedFile(null);
      setReplyTo(null);
      scrollToBottom();
    }
  };

  const handleReplyMessage = (message) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setReplyTo(message); // Store the entire message being replied to
    scrollToBottom();
  };

  const handleCancelReply = () => {
    setReplyTo(null); // Clear the replyTo state
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
    setAnchorEl(null);
  };

  const handleCancelSearch = () => {
    setShowSearchBar(false);
    setSearchInput('');
  };
  const handleConfirmation = (action) => {
    setConfirmationAction(action);
    setShowConfirmation(true);
  };
  const handleAction = async () => {
    if (confirmationAction === 'delete') {
      handleDeleteUserChat();
    }
    setShowConfirmation(false);
  };
  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await ChatApi.handleDeleteChatMessage(messageId);
      if (response.message === 'Message Deleted Successfully') {
        setMessages(messages.filter((message) => message.id !== messageId));
      }
    } catch (error) {
      handleAlert(error.message, 'error');
    } finally {
      // setIsLoader(false);
    }
  };
  // editMessage
  const handleEditMessage = async (message) => {
    setEditingMessageId(message.id);
    setEditingMessageText(message.message);
  };
  const handleEditMessageSubmit = async (messageId) => {
    try {
      const id = messageId;
      const response = await ChatApi.editMessage(id, editingMessageText);
      addMessage(
        messages.map((message) =>
          message.id === id ? { ...message, message: response?.messageData[0]?.message } : message
        )
      );
      handleCancelEdit();
    } catch (error) {
      handleAlert(error.message, 'error');
    } finally {
      // setIsLoader(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditingMessageText('');
  };

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const id = getChatIdandName()?.chatId;
        if (!id || id === 'newChatId') {
          return;
        }
        const response = await ChatApi.getUserChatDetails(id, 1);
        setUserDetails(response);
      } catch (error) {}
    };

    fetchUserDetails();

    return () => {
      setUserDetails(null);
    };
  }, [chatId]);

  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid #D3D3D3',
        height: isSmallScreen ? '90vh' : '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #D3D3D3',
          backgroundColor: 'white',
          pointerEvents: uploadLoader ? 'none' : 'auto',
          opacity: uploadLoader ? 0.5 : 1,
          borderRadius: '8px 8px 0px 0px',
        }}
        onClick={() => setShowEmojiPicker(false)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ManualAvatar chat={{ username: userName }} bgColor={bgColor} />
          <Box
            sx={{
              overflowX: 'auto',
              display: '-webkit-box',
              WebkitLineClamp: 1,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'normal',
            }}
          >
            <p style={{ fontSize: '18px' }}>{userName}</p>
          </Box>
        </Box>
        <Box>
          <MoreVertIcon
            sx={{ fontSize: { uxl: '28px' }, cursor: 'pointer' }}
            onClick={handleMenuClick}
          />
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            sx={{
              '& .MuiPaper-root': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              },
              '& .MuiList-root': {
                py: 0.5,
              },
            }}
          >
            <MenuItem onClick={() => handleConfirmation('delete')}>Delete Chat</MenuItem>
          </Menu>
          <ConfirmationDialog
            open={showConfirmation}
            onClose={() => setShowConfirmation(false)}
            onConfirm={handleAction}
            title={confirmationAction === 'delete' && 'Confirm Chat Deletion'}
            message={
              "You'll not be able to access this chat. Are you sure you want to delete this Chat?"
            }
          />
        </Box>
      </Box>
      {showSearchBar && (
        <SearchBar
          handleCancelSearch={handleCancelSearch}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      )}
      <Box
        sx={{
          padding: 2,
          flexGrow: 1,
          overflowY: uploadLoader ? 'hidden' : 'auto',
          margin: 0,
          position: 'relative',
          height: 'calc(100% - 32px)!important',
        }}
        ref={messagesContainerRef}
        onScroll={handleScroll}
        onClick={() => setShowEmojiPicker(false)}
      >
        {isLoader ? (
          <ChatMessageLoader />
        ) : messages.length === 0 ? (
          <Box
            sx={{
              marginBottom: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              height: 'calc(100vh - 200px)',
            }}
          >
            <img
              src={UserChatImage}
              style={{
                height: '40vh',
              }}
            />
            <Typography
              sx={{
                textAlign: 'center',
                maxWidth: {
                  xs: '100%',
                  sm: '80%',
                },
                marginBottom: 'auto',
                borderRadius: '5px',
                padding: '10px',
                backgroundColor: '#f5f5f5',
              }}
            >
              Send a message to start the chat!
            </Typography>
            <SuggestedFeatures />
          </Box>
        ) : (
          <>
            {isLoading && <ChatMessageLoader />}
            {messages.map((message, index) => (
              <>
                <div style={{ position: 'relative' }}>
                  {hoveredMessageId === message.id && (
                    <MessageActions
                      canSendMessages={true} //to enable reply
                      message={message}
                      currentUserInfo={currentUserInfo}
                      handleDeleteMessage={handleDeleteMessage}
                      handleEditMessage={handleEditMessage}
                      handleReplyMessage={handleReplyMessage}
                      setHoveredMessageId={setHoveredMessageId}
                      showReactEmoji={showReactEmoji}
                      setReactionsData={setReactionsData}
                      reactionsData={reactionsData}
                    />
                  )}
                  {message.replyMessageData ? (
                    message.replyMessageData &&
                    message.replyMessageData?.message?.id === message?.reply_id && (
                      <>
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            textAlign: 'left',
                            border: '1px solid #D3D3D3',
                            width: 'fit-content',
                            maxWidth: '50%',
                            marginBottom: '4px',
                            marginLeft: message.sender_id === currentUserInfo.id ? 'auto' : '0px',
                            pointerEvents: uploadLoader ? 'none' : 'auto',
                            opacity: uploadLoader ? 0.5 : 1,
                            wordBreak: 'break-word',
                            borderRadius:
                              message.sender_id === currentUserInfo.id
                                ? '10px 0 10px 10px '
                                : '10px 0 10px 10px ',
                            backgroundColor:
                              message.sender_id === currentUserInfo.id ? '#8CA4F8' : '#ECECEC',
                            color: message.sender_id === currentUserInfo.id ? 'white' : 'black',
                            alignItems: 'baseline',
                          }}
                          onMouseEnter={() => setHoveredMessageId(message?.id)}
                          onMouseLeave={() => setHoveredMessageId(null)}
                        >
                          {hoveredMessageId === message.replyMessageData?.message?.id && (
                            <Box
                              onMouseEnter={() =>
                                setHoveredMessageId(message.replyMessageData?.message?.id)
                              }
                              onMouseLeave={() => setHoveredMessageId(null)}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gap: '8px',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                borderRadius: '4px',
                                boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.10)',
                                p: 0.5,
                                width: 'fit-content',
                                left: message.sender_id === currentUserInfo.id ? 'auto' : '10px',
                                pointerEvents: 'auto',
                                position: 'absolute',
                                right: message.sender_id === currentUserInfo.id ? '10px' : 'auto',
                                top: '-32px',
                                zIndex: 5,
                              }}
                            >
                              <Tooltip title="Reply" placement="top" arrow>
                                <IconButton
                                  onClick={() => handleReplyMessage(message)}
                                  size="small"
                                >
                                  <QuickreplyOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Edit" placement="top" arrow>
                                {message.sender_id === currentUserInfo.id && (
                                  <IconButton
                                    onClick={() => handleEditMessage(message)}
                                    size="small"
                                  >
                                    <EditIcon fontSize="small" />
                                  </IconButton>
                                )}
                              </Tooltip>
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  onClick={() => handleDeleteMessage(message.id)}
                                  size="small"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          )}
                          <Box
                            sx={{
                              backgroundColor: 'white',
                              borderRadius:
                                message.replyMessageData?.senderData?.id === currentUserInfo.id
                                  ? '9px 0 0px 0px '
                                  : '9px 0px 0px 0px ',
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <Box
                              sx={{
                                minWidth: '14px',
                                width: '4px',
                                borderTop: '3px solid #698aff',
                                borderLeft: '3px solid #698aff',
                                borderRadius:
                                  message.replyMessageData?.senderData?.id === currentUserInfo.id
                                    ? '9px 0 0px 0px '
                                    : '9px 0px 0px 0px ',
                              }}
                            ></Box>
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                mr: '8px',
                                mb: '4px',
                                mt: '8px',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                }}
                              >
                                <ManualAvatar
                                  chat={userDetails}
                                  bgColor={bgColor}
                                  width={'16px'}
                                  height={'16px'}
                                />
                                <Box
                                  sx={{
                                    alignSelf: 'flex-start',
                                    fontSize: '11px',
                                    color: '#1f1f1f',
                                  }}
                                >
                                  {`${message?.replyMessageData?.senderData?.firstName} ${message?.replyMessageData?.senderData?.lastName}`}
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  color:
                                    message.replyMessageData?.senderData?.id === currentUserInfo.id
                                      ? '#5f6368!important'
                                      : 'black',
                                  fontSize: '12px!important',
                                  marginTop: '4px',
                                }}
                              >
                                <ParseMessageWithEmojis
                                  message={message.replyMessageData?.message?.message}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{ p: '10px' }}>
                            {repliedMessages?.type === 'FILE' ? (
                              <MediaMessageBox message={message?.message} />
                            ) : (
                              <ParseMessageWithEmojis message={message?.message} />
                            )}
                            <Box
                              style={{
                                fontWeight: 'normal',
                                fontSize: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                color:
                                  message.sender_id === currentUserInfo.id ? '#E0E0E0' : '#8C8C8C',
                                marginLeft: 'auto',
                              }}
                            >
                              <FormattedDateTime messageTime={message.created_at} />
                            </Box>
                          </Box>
                        </Box>
                      </>
                    )
                  ) : (
                    <Box
                      key={index}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                        padding: '10px',
                        border: '1px solid #D3D3D3',
                        width: 'fit-content',
                        maxWidth: '50%',
                        marginBottom: '4px',
                        marginLeft: message.sender_id === currentUserInfo.id ? 'auto' : '0px',
                        pointerEvents: uploadLoader ? 'none' : 'auto',
                        opacity: uploadLoader ? 0.5 : 1,
                        wordBreak: 'break-word',
                        borderRadius:
                          message.sender_id === currentUserInfo.id
                            ? '10px 0 10px 10px '
                            : '0 10px 10px 10px ',
                        backgroundColor:
                          message.sender_id === currentUserInfo.id ? '#8CA4F8' : '#ECECEC',
                        color: message.sender_id === currentUserInfo.id ? 'white' : 'black',
                        alignItems: 'baseline',
                      }}
                      onMouseEnter={() => setHoveredMessageId(message.id)}
                      onMouseLeave={() => setHoveredMessageId(null)}
                    >
                      {message.type === 'FILE' ? (
                        <MediaMessageBox message={message} />
                      ) : (
                        <ParseMessageWithEmojis message={message.message} />
                      )}
                      <Box
                        style={{
                          fontWeight: 'normal',
                          fontSize: '10px',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          color: message.sender_id === currentUserInfo.id ? '#E0E0E0' : '#8C8C8C',
                          marginLeft: 'auto',
                        }}
                      >
                        <FormattedDateTime messageTime={message.created_at} />
                      </Box>
                    </Box>
                  )}
                  {reactionsData?.length > 0 &&
                    reactionsData.map((reactionData, index) => {
                      if (reactionData.msgId === message.id) {
                        return (
                          <ChatReactionBox
                            key={index}
                            reactionData={reactionData}
                            isCurrentUser={message.sender_id === currentUserInfo.id}
                            setReactionsData={setReactionsData}
                            reactionsData={reactionsData}
                          />
                        );
                      }
                    })}
                  {editingMessageId === message.id && (
                    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                      <TextField
                        value={editingMessageText}
                        onChange={(e) => setEditingMessageText(e.target.value)}
                        fullWidth
                        multiline
                        variant="outlined"
                        size="small"
                        sx={{ mb: 1 }}
                      />
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={handleCancelEdit} size="small" sx={{ mr: 1 }}>
                          Cancel
                        </Button>
                        <Button
                          onClick={() => handleEditMessageSubmit(message.id)}
                          size="small"
                          variant="contained"
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                  )}
                </div>
              </>
            ))}
          </>
        )}
        <div ref={messagesEndRef} />
        {showScrollButton && (
          <Box
            sx={{
              position: 'sticky',
              bottom: '24px',
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '16px',
              zIndex: 1,
            }}
          >
            <IconButton
              onClick={scrollToBottom}
              sx={{
                backgroundColor: '#eeeff1',
                '&:hover': {
                  backgroundColor: '#dbe7ff',
                },
              }}
            >
              <ArrowDownwardIcon sx={{ color: '#8ca4f8' }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {uploadLoader ? (
        <Box display={'flex'} position={'absolute'} top={'50%'} left={'50%'}>
          <Skeletons type={'LazySmallCircularLoader'} />
        </Box>
      ) : (
        uploadedFile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
              padding: '10px',
            }}
          >
            <img
              src={uploadedFile.url}
              alt={uploadedFile.file.name}
              style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
            />
            <span>{uploadedFile.file.name}</span>
          </Box>
        )
      )}
      {replyTo && (
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Replying to: {replyTo.message}
          </Typography>
          <IconButton onClick={handleCancelReply} size="small">
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSendMessage();
        }}
      >
        <ChatInputField
          ref={inputRef}
          inputValue={inputValue}
          setInputValue={setInputValue}
          handleSendMessage={handleSendMessage}
          handleKeyPress={handleKeyPress}
          setShowEmojiPicker={setShowEmojiPicker}
          showEmojiPicker={showEmojiPicker}
          setUploadedFile={setUploadedFile}
          setUploadLoader={setUploadLoader}
        />
      </form>
      {showEmojiPicker && (
        <div style={{ position: 'absolute', bottom: '60px', right: '20px' }}>
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
    </Box>
  );
}
