import React from 'react';
import '../ViewOrganizations.css';
import RequestToJoinBtn from '../Buttons/RequestToJoin';
import { Box, Typography } from '@mui/material';
import { Colors } from '../../../../theme/Theme';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
export default function OrgDesktop({ Data, orgMemberData, myCoursesData }) {
  if (!Data) {
    return null;
  }
  const renderOrgProfilePhoto = () => {
    if (Data.logoLink) {
      return <img className="viewFirstRow-logoImg" src={Data.logoLink} alt="" />;
    } else {
      const orgInitials = Data.name?.charAt(0) || '';
      return (
        <div className="orgLogo-initials-container">
          <span>{orgInitials}</span>
        </div>
      );
    }
  };
  return (
    <div className="viewFirstRow">
      <div className="viewFirstRow-logo">{renderOrgProfilePhoto()} </div>
      <div className="viewFirstRow-info">
        <div className="viewFirstRow-infoDiv1">
          <div>
            <Typography
              variant="h5"
              sx={{
                pb: 0,
                mb: 0.5,
                color: Colors.headingPrimary,
                display: '-webkit-box',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
              }}
            >
              {Data.name}
            </Typography>
            <Typography
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                color: Colors.subTitleColor,
                mb: 0.5,
              }}
              variant="body1"
            >
              {Data?.creator?.firstName} {Data?.creator?.lastName}
            </Typography>
            <Typography
              sx={{
                display: '-webkit-box',
                WebkitLineClamp: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                color: Colors.subTitleColor,
              }}
              variant="subtitle2"
            >
              {Data?.description}
            </Typography>
          </div>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <RequestToJoinBtn disabled={Data.isAlreadyRequested} OrganizationID={Data.id} />
            <div className="viewFirstRow-info2">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <PeopleAltOutlinedIcon sx={{ fontSize: '18px', color: '#2B3746' }} />
                <Typography variant="subtitle2" fontWeight={300} color={'#787878'}>
                  Members:
                </Typography>
                <Typography variant="subtitle2" color="#2B3746">
                  {orgMemberData.length}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <MenuBookOutlinedIcon sx={{ fontSize: '18px', color: '#2B3746' }} />
                <Typography variant="subtitle2" fontWeight={300} color={'#787878'}>
                  Courses:
                </Typography>
                <Typography variant="subtitle2" color="#2B3746">
                  {myCoursesData?.length}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <AssignmentOutlinedIcon sx={{ fontSize: '18px', color: '#2B3746' }} />
                <Typography variant="subtitle2" fontWeight={300} color={'#787878'}>
                  Tasks:
                </Typography>
                <Typography variant="subtitle2" color="#2B3746">
                  {Data.tasks.totalTasks}
                </Typography>
              </Box>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}
