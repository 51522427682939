import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HintsAccordion({ hints }) {
  return (
    hints?.length > 0 && (
      <Grid item xs={12} p={'0px!important'}>
        <Box>
          {hints?.map((hint, index) => (
            <Accordion
              key={index}
              m={0}
              p={0}
              defaultExpanded={false}
              sx={{
                boxShadow: 'none',
                mt: 0,
                '&.MuiAccordion-root::before': {
                  content: 'none',
                },
                '&.Mui-expanded': {
                  margin: '0px',
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  border: '1px solid #e9e7e7',
                  minHeight: { xs: '48px!important' },
                  borderBottom: index === hints.length - 1 ? '1px solid #e9e7e7!important' : 'none',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: '400',
                  }}
                >
                  {`Hint ${index + 1}`}
                </Typography>
              </AccordionSummary>

              <AccordionDetails
                sx={{
                  border: '1px solid #e9e7e7',
                  px: '0px',
                  p: 2,
                  borderBottom: index === hints.length - 1 ? '1px solid #e9e7e7!important' : 'none',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '14px',
                    fontWeight: '400',
                    color: '#021148',
                  }}
                >
                  {hint}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Grid>
    )
  );
}

export default HintsAccordion;
