import performRequest from '../../axios';

export const contestProblemAPI = {
  createProblem: async (body) => {
    return await performRequest(`problem/`, 'POST', body);
  },

  mapProblemsToContest: async (id, body) => {
    return await performRequest(`contest/${id}`, 'POST', body);
  },

  editProblem: async (id, body) => {
    return await performRequest(`problem/${id}`, 'PATCH', body);
  },

  getProblem: async (id) => {
    return await performRequest(`problem/${id}`, 'GET');
  },
  getAllProblem: async (pageNo) => {
    return await performRequest(`org/problem/${pageNo}`, 'GET');
  },

  submitSolution: async (id, body) => {
    return await performRequest(`problem/${id}/submission`, 'POST', body);
  },
  runCode: async (id, body) => {
    return await performRequest(`problem/${id}/runcode`, 'POST', body);
  },

  getSubmissions: async (id) => {
    return await performRequest(`problem/submissions/${id}`, 'GET');
  },
  getSubmissionsToScore: async (id, pageNo) => {
    return await performRequest(`problem/submissions/${id}/${pageNo}`, 'GET');
  },
  getSubmissionsByProblemId: async (contestId, problemId, pageNo) => {
    return await performRequest(`problem/submissions/${contestId}/${problemId}/${pageNo}`, 'GET');
  },
  getResultsByContestId: async (contestId, pageNo) => {
    return await performRequest(`results/${contestId}/${pageNo}`, 'GET');
  },
  getResultsByUserId: async (contestId, userId, pageNo) => {
    return await performRequest(`result/${contestId}/${userId}`, 'GET');
  },
  getResultsCandidate: async (contestId) => {
    return await performRequest(`candidate/results/${contestId}`, 'GET');
  },
  updateScore: async (body) => {
    return await performRequest('/score', 'PATCH', body);
  },
};
