import { Avatar, Box, Button, IconButton, Typography } from '@mui/material';
import { ChatApi } from '../../../../api/requests/chat/ChatApi';
import React, { useEffect, useState } from 'react';
import { handleAlert } from '../../../../utils/handleAlert';
import CloseIcon from '@mui/icons-material/Close';
import { CommonColors } from '../../../../utils/colors';
import { validateSearchInput } from '../../../../utils/validateSearchInput';
import UserSearchList from '../../../SearchAndAdd/UserSearchList';
import SocketClient from '../../../../socket';

export default function AddMembers({
  setOpen,
  groupDetails,
  groupMembers,
  setGroupMembers,
  setTabIndex,
}) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const getSocket = async () => {
      setSocket(await SocketClient.getSocket());
    };
    getSocket();
  }, []);

  const bgColor = CommonColors.primaryBgColor;
  const [input, setInput] = useState('');
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [userIds, setUserIds] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await ChatApi.addUserToGroup(groupDetails.groupChatData.id, userIds);
      setGroupMembers((prevMembers) => [...prevMembers, ...selectedUsers]);
      socket?.emit('addUserToGroup', { userIds });
      setTabIndex(1);
    } catch (error) {
      handleAlert(error.message, 'error');
    }
    setOpen(false);
  };

  const handleAddition = (user) => {
    if (selectedUsers.some((u) => u.id === user.id)) {
      handleAlert(
        `${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()} has already been selected.`,
        'error'
      );
    } else if (groupMembers.some((member) => member.user_id === user.id)) {
      handleAlert(
        `${user.firstName.toUpperCase()} ${user.lastName.toUpperCase()} is already part of the group.`,
        'error'
      );
    } else if (!selectedUsers.some((u) => u.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]);
      setUserIds([...userIds, user.id]);
      setInput('');
    }
  };

  useEffect(() => {
    const validInputs = validateSearchInput(input);
    if (validInputs.length === 0) {
      setInput('');
      setSearchedUsers([]);
      return;
    }

    const searchUsers = async () => {
      try {
        const response = await ChatApi.searchUser(validInputs[0].toLowerCase());
        const lowerCaseInput = input.toLowerCase();
        const filteredUsers = response.filter((user) =>
          user.username.toLowerCase().includes(lowerCaseInput)
        );
        setSearchedUsers(filteredUsers);
      } catch (error) {
        handleAlert(error.message, 'error');
      }
    };

    searchUsers();
  }, [input]);

  const handleRemove = (user) => {
    setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
    setUserIds(userIds.filter((id) => id !== user.id));
  };
  const handleResetSearchUser = () => {
    setInput('');
  };

  return (
    <Box
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <form
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '20px',
        }}
      >
        <Box sx={{ height: 'inherit' }}>
          <UserSearchList
            input={input}
            setInput={setInput}
            searchedUsers={searchedUsers}
            handleAddition={handleAddition}
            handleRemove={handleRemove}
            handleResetSearchUser={handleResetSearchUser}
            placeholder="Enter username to add to your group"
            width={'100%'}
            size={'small'}
            pl={0}
          />

          {selectedUsers.length > 0 && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflowY: 'auto',
                height: { xs: 'auto', md: 'auto' },
                maxHeight: '250px',
                width: '100%',
                marginTop: 'auto',
                scrollbarWidth: 'thin',
                mt: 0,
              }}
            >
              {selectedUsers.map((user, index) => (
                <Box
                  key={index}
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  sx={{
                    cursor: 'pointer',
                    width: '-webkit-fill-available',
                    py: 1.5,
                    borderBottom: '1px solid rgb(226, 226, 226)',
                  }}
                >
                  <Box display={'flex'} gap={1} alignItems={'center'}>
                    <Avatar
                      src={user.profilePhotoLink}
                      style={{
                        backgroundColor: user.profilePhotoLink ? 'transparent' : bgColor,
                        color: user.profilePhotoLink ? 'black' : 'white',
                        width: '30px',
                        height: '30px',
                        fontSize: '19px',
                      }}
                    >
                      {user.firstName.charAt(0).toUpperCase()}
                    </Avatar>
                    <p>
                      {`${user.firstName} ${user.lastName}`.toUpperCase()} {`(${user.username})`}
                    </p>
                  </Box>
                  <IconButton
                    sx={{
                      p: 0.5,
                    }}
                    onClick={() => handleRemove(user)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            position: 'sticky',
            bottom: '0px',
            right: '24px',
            left: '24px',
            width: '-webkit-fill-available',
            background: '#fff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              marginTop: 'auto',
              justifyContent: 'flex-end',
              mt: 0,
            }}
          >
            <Button
              variant="contained"
              type="submit"
              color="primary"
              onClick={(e) => handleSubmit(e)}
            >
              Add
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
}
