import React from "react";
import { Routes, Route } from "react-router-dom";
import { LazyComponent } from "../components/LazyLoader";
import ProblemPage from "../pages/Contest/ProblemPage";

const ProblemDiscussion = LazyComponent(() =>
  import("../components/CodingContests/ContestProblem/Discussion")
);

export const ProblemRoutes = () => {
  return (
    <Routes>
      <Route path="/:contestId" element={<ProblemPage />} />
      <Route path="/:problemId/discussion" element={<ProblemDiscussion />} />
    </Routes>
  );
};
