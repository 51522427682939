import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Typography,
  Select,
  IconButton,
  Button,
  TextField,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmojiPicker from 'emoji-picker-react';
import { CommonColors } from '../../../../utils/colors';
import GetValidatedTokenData from '../../../../utils/helper';
import { ChatApi } from '../../../../api/requests/chat/ChatApi';
import { getOrgName } from '../../../../utils/appendOrgQuery';
import { handleAlert } from '../../../../utils/handleAlert';
import ChatMessagePanel from '../../../MessageBox/ChatMessagePanel';
import ManualAvatar from '../../../Avatar/Avatar';
import ChatInputField from '../../../InputField/ChatInputField';
import Skeletons from '../../../Skeleton/Skeletons';
import GroupDetails from './GroupDetails';
import ConfirmationDialog from '../../../Dialogbox/ConfirmationDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import BigLoader from '../../../Skeleton/BigLoader';
import SearchBar from '../SearchBar';
import '../chat.css';
import GroupChatDesktop from '../../../../assets/ChatImages/GroupChatDesktop.webp';
import ChatMessageLoader from '../../../Skeleton/ChatMessageLoader';
import SuggestedFeatures from '../SuggestedFeatures';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import addNotification from 'react-push-notification';
import MessageActions from '../../../MessageActions';
import CancelIcon from '@mui/icons-material/Cancel';
import ChatReactionBox from '../../../MessageBox/ChatReactionBox';
import SocketClient from '../../../../socket';

export default function GroupChatWindow({ setGroupList, groupList }) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const getSocket = async () => {
      setSocket(await SocketClient.getSocket());
    };
    getSocket();
  }, []);

  const location = useLocation();
  const getGroupId = () => {
    const getQueryParam = (param) => {
      const params = new URLSearchParams(location.search);
      return params.get(param) || '';
    };
    return getQueryParam('groupid') ?? null;
  };

  const bgColor = CommonColors.primaryBgColor;
  const [confirmationAction, setConfirmationAction] = useState('');
  const [loading, setLoading] = useState(false);
  const [groupDetails, setGroupDetails] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [newMessage, setNewMessage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [members, setMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(false);
  const [isLoadingOlder, setIsLoadingOlder] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingMessageText, setEditingMessageText] = useState('');
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [showReactEmoji, setShowReactEmoji] = useState(true);
  const [replyTo, setReplyTo] = useState(null);
  const inputRef = useRef(null);
  const [reactionsData, setReactionsData] = useState([]);

  const editBoxRef = useRef(null);

  useEffect(() => {
    if (editingMessageId && editBoxRef.current) {
      editBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [editingMessageId]);

  const openMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const orgName = getOrgName();

  const addMessage = (newMessages) => {
    setMessages((prevMessages) => {
      const messagesArray = Array.isArray(newMessages) ? newMessages : [newMessages];
      const newArr = prevMessages.concat(messagesArray);
      const gId = getGroupId();
      const filterMessages = newArr.filter((msg) => msg.chat_id === gId);
      const messageMap = new Map(filterMessages.map((msg) => [msg.id, msg]));
      filterMessages.forEach((msg) => msg.chat_id === getGroupId() && messageMap.set(msg.id, msg));
      const updatedMessagesArray = Array.from(messageMap.values());
      const sortedMessages = updatedMessagesArray.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      return sortedMessages;
    });
  };

  const handleReplyMessage = (message) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setReplyTo(message);
    scrollToBottom();
  };

  const handleCancelReply = () => {
    setReplyTo(null); // Clear the replyTo state
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await ChatApi.handleDeleteChatMessage(messageId);
      if (response.message === 'Message Deleted Successfully') {
        setMessages(messages.filter((message) => message.id !== messageId));
      }
    } catch (error) {
      handleAlert(error.message, 'error');
    } finally {
      // setIsLoader(false);
    }
  };

  const handleEditMessage = async (message) => {
    setEditingMessageId(message.id);
    setEditingMessageText(message.message);
  };
  const handleEditMessageSubmit = async (messageId) => {
    try {
      const id = messageId;
      const response = await ChatApi.editMessage(id, editingMessageText);
      addMessage(
        messages.map((message) =>
          message.id === id ? { ...message, message: response?.messageData[0]?.message } : message
        )
      );
      handleCancelEdit();
    } catch (error) {
      handleAlert(error.message, 'error');
    }
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditingMessageText('');
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    if (!isLoadingOlder && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (
        lastMessage.sender_id === currentUserInfo.id ||
        (newMessage && newMessage.sender_id !== currentUserInfo.id)
      ) {
        scrollToBottom();
      }
    }
  }, [messages, isLoadingOlder, newMessage]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleGroupChatDelete = async () => {
    setLoading(true);
    try {
      await ChatApi.handleGroupChatDeletion(groupDetails.groupChatData.id);
      const getUpdatedGroupList = groupList.filter(
        (group) => group.chatData.id !== groupDetails.groupChatData.id
      );
      setGroupList(getUpdatedGroupList);
      navigate(`/org/${orgName}/chat`);
      setOpenDetails(false);
      if (isSmallScreen) {
        navigate(`/org/${orgName}/chat`);
      }
    } catch (error) {
      handleAlert(error.message, 'warning');
    } finally {
      setLoading(false);
    }
  };
  const onEmojiClick = (event) => {
    setInputValue((prevInputValue) => prevInputValue + event.emoji);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchMessages = useCallback(
    async (pageNum) => {
      if (isLoading) return;
      setIsLoading(true);
      setIsLoadingOlder(true);
      const container = messagesContainerRef.current;
      const prevScrollHeight = container.scrollHeight;
      const prevScrollTop = container.scrollTop;
      try {
        setReactionsData([]);
        const response = await ChatApi.getGroupById(getGroupId(), pageNum);
        response?.messages.map((item) => {
          if (item?.reactionsData?.length > 0) {
            setReactionsData((prev) => [
              ...prev,
              { msgId: item.id, emojiData: item.reactionsData },
            ]);
          }
        });
        if (!response.messages) {
          setMessages([]);
          setHasMore(false);
          return;
        }
        if (!Array.isArray(response.messages)) {
          handleAlert('Error fetching messages', 'error');
          return;
        }

        addMessage(response.messages);

        setPage(pageNum);
        setHasMore(pageNum < response.totalPages);
        setMembers(response.groupChatMembers);
        setGroupDetails(response);
        setIsAdmin(response.isGroupAdmin);
        setShowSearchBar(false);
        setSearchInput('');
        requestAnimationFrame(() => {
          container.scrollTop = container.scrollHeight - prevScrollHeight + prevScrollTop;
        });
      } catch (error) {
        handleAlert('Something went wrong', 'error');
      } finally {
        setIsLoading(false);
        setIsLoader(false);
        setIsLoadingOlder(false);
        isFetchingRef.current = false;
        scrollToBottom();
      }
    },
    [getGroupId(), isLoading]
  );

  useEffect(() => {
    setPage(1);
    setIsLoader(true);
    if (getGroupId()) {
      fetchMessages(1);
    }
    setIsLoading(true);
    const getGroupDetails = async () => {
      try {
        const response = await ChatApi.getGroupById(getGroupId(), 1);
        setMembers(response.groupChatMembers);
        addMessage(response.messages);
        setShowSearchBar(false);
        setSearchInput('');
      } catch (error) {
        handleAlert('Something went wrong', 'error');
      } finally {
        setIsLoading(false);
        setIsLoader(false);
      }
    };
    if (getGroupId()) {
      getGroupDetails();
    }
  }, [location.search]);

  const isFetchingRef = useRef(false);

  const handleScroll = useCallback(() => {
    const container = messagesContainerRef.current;
    if (container) {
      if (container.scrollTop === 0 && hasMore && !isLoading && !isFetchingRef.current) {
        isFetchingRef.current = true;
        fetchMessages(page + 1);
      }
      const atBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
      setShowScrollButton(!atBottom);
    }
  }, [fetchMessages, hasMore, isLoading, page]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const currentUserInfo = GetValidatedTokenData();

  const notify = (username, message) => {
    addNotification({
      title: username,
      message: message,
      duration: 5000,
      native: true,
    });
  };

  useEffect(() => {
    const msg = newMessage;
    if (currentUserInfo.id !== msg?.[0]?.sender_id) {
      notify(msg?.[0]?.username, msg?.[0]?.message);
    } else {
      scrollToBottom();
    }

    if (msg?.[0]?.created_at && msg?.[0]?.chat_id === getGroupId()) {
      addMessage(msg);
      setIsLoadingOlder(false);
      setGroupList((prevGroupList) =>
        prevGroupList.map((group) => {
          if (group?.chatData?.id === msg?.[0]?.chat_id) {
            return {
              ...group,
              lastMessageData: {
                ...group.lastMessageData,
                message: msg?.[0]?.message,
              },
            };
          }
          return group;
        })
      );
    }
  }, [newMessage]);

  useEffect(() => {
    socket?.on('message', (msg) => {
      setNewMessage(msg);
    });

    return () => {
      socket?.off('message');
      socket?.off('disconnect');
    };
  }, [socket]);

  const handleOpenDetails = () => {
    setAnchorEl(null);
    setOpenDetails(!openDetails);
  };

  const handleSendMessage = () => {
    setShowEmojiPicker(false);
    if (!inputValue.trim() && !uploadedFile) return;
    if (socket) {
      let messageData = {
        senderId: currentUserInfo.id,
        message: uploadedFile ? uploadedFile.file.name : inputValue,
        groupId: getGroupId(),
        organization: getOrgName(),
        replyId: replyTo ? replyTo?.id : null,
      };

      if (uploadedFile) {
        messageData = {
          ...messageData,
          type: 'FILE',
          url: uploadedFile.url,
        };
      }
      socket?.emit('message', messageData);
      setInputValue('');
      setUploadedFile(null);
      setReplyTo(null);
      scrollToBottom();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
    setAnchorEl(null);
  };

  const handleCancelSearch = () => {
    setShowSearchBar(false);
    setSearchInput('');
  };

  const handleLeaveGroup = async () => {
    setLoading(true);
    try {
      await ChatApi.leaveGroup(groupDetails.groupChatData.id);
      navigate(`/org/${orgName}/chat`);
      setOpenDetails(false);
      if (isSmallScreen) {
        navigate(`/org/${orgName}/chat`);
      }
    } catch (error) {
      handleAlert(error.message, 'warning');
    }
    setLoading(false);
  };
  const handleGroupDelete = async () => {
    setLoading(true);
    try {
      await ChatApi.handleGroupDeletion(groupDetails.groupChatData.id);
      const getUpdatedGroupList = groupList.filter(
        (group) => group.chatData.id !== groupDetails.groupChatData.id
      );
      setGroupList(getUpdatedGroupList);
      navigate(`/org/${orgName}/chat`);
      setOpenDetails(false);
      if (isSmallScreen) {
        navigate(`/org/${orgName}/chat`);
      }
    } catch (error) {
      handleAlert(error.message, 'error');
    }
    setLoading(false);
  };
  const handleAction = async () => {
    if (confirmationAction === 'delete') {
      handleGroupDelete();
    } else if (confirmationAction === 'leave') {
      handleLeaveGroup();
    } else if (confirmationAction === 'delete chat') {
      handleGroupChatDelete();
    }
    setShowConfirmation(false);
  };
  const handleConfirmation = (action) => {
    setConfirmationAction(action);
    setShowConfirmation(true);
  };
  if (loading)
    return (
      <Box>
        <BigLoader />
      </Box>
    );
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '8px',
        border: '1px solid #D3D3D3',
        height: isSmallScreen ? '90%' : '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          padding: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #D3D3D3',
          backgroundColor: 'white',
          borderRadius: '8px 8px 0px 0px',
          pointerEvents: uploadLoader ? 'none' : 'auto',
          opacity: uploadLoader ? 0.5 : 1,
        }}
        onClick={() => setShowEmojiPicker(false)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ManualAvatar chat={groupDetails} bgColor={bgColor} />
          <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
            <Box display={'flex'} flex={1} width={'100%'}>
              {groupDetails?.groupChatData.name && (
                <p
                  style={{
                    fontSize: '18px',
                    flexDirection: 'row',
                    alignItems: 'center',
                    overflowX: 'auto',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'normal',
                  }}
                >
                  {groupDetails.groupChatData.name}
                </p>
              )}
              {groupDetails?.allowSendingMessage && (
                <Select
                  value={selectedValue}
                  onChange={(event) => setSelectedValue(event.target.value)}
                  sx={{
                    padding: '0px',
                    height: '20px',
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                      },
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '50vh',
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {members.length > 0 &&
                    members.map((member, index) => (
                      <MenuItem
                        key={index}
                        style={{ padding: '5px', backgroundColor: 'transparent' }}
                      >
                        {member.username}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </Box>
            {members && groupDetails?.allowSendingMessage && (
              <p>
                {members.length} {members.length > 1 ? 'members' : 'member'}
              </p>
            )}
          </Box>
        </Box>
        <Box>
          <MoreVertIcon
            sx={{ fontSize: { uxl: '28px' }, cursor: 'pointer' }}
            onClick={handleMenuClick}
          />
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            sx={{
              '& .MuiPaper-root': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              },
              '& .MuiList-root': {
                py: 0.5,
              },
            }}
          >
            {groupDetails?.allowSendingMessage && (
              <>
                <MenuItem onClick={handleOpenDetails}>Chat Info</MenuItem>
                <MenuItem onClick={() => handleConfirmation('leave')}>Leave</MenuItem>
              </>
            )}
            <MenuItem onClick={() => handleConfirmation('delete chat')}>Delete for Me</MenuItem>
            {isAdmin && groupDetails?.allowSendingMessage && (
              <MenuItem onClick={() => handleConfirmation('delete')}>Delete for Everyone</MenuItem>
            )}
          </Menu>
          <ConfirmationDialog
            open={showConfirmation}
            onClose={() => setShowConfirmation(false)}
            onConfirm={handleAction}
            title={
              confirmationAction === 'delete'
                ? 'Delete Group'
                : confirmationAction === 'delete chat'
                ? 'Delete group for yourself'
                : 'Leave Group'
            }
            message={
              confirmationAction === 'delete'
                ? 'Are you sure you want to delete this group? This action cannot be undone.'
                : confirmationAction === 'delete chat'
                ? 'Are you sure you want to delete the chat of this group?'
                : 'Are you sure you want to leave this group?'
            }
          />
        </Box>
      </Box>
      {showSearchBar && (
        <SearchBar
          handleCancelSearch={handleCancelSearch}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      )}
      <Box
        sx={{
          padding: 2,
          flexGrow: 1,
          overflowY: uploadLoader ? 'hidden' : 'auto',
          margin: 0,
          width: '100%',
          position: 'relative',
          height: 'calc(100vh - 200px)',
        }}
        // message container
        ref={messagesContainerRef}
        onScroll={handleScroll}
        onClick={() => setShowEmojiPicker(false)}
      >
        {isLoader ? (
          <ChatMessageLoader />
        ) : messages && messages.length === 0 ? (
          <Box
            sx={{
              marginBottom: 'auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              flexDirection: 'column',
              gap: {
                xs: '10px',
                sm: '20px',
              },
            }}
          >
            <img
              src={GroupChatDesktop}
              style={{
                width: '50%',
                height: '50%',
              }}
            />
            <Typography
              sx={{
                textAlign: 'center',
                maxWidth: {
                  xs: '100%',
                  sm: '80%',
                },
                marginBottom: 'auto',
                borderRadius: '5px',
                padding: '10px',
                backgroundColor: '#f5f5f5',
              }}
            >
              Welcome to the group! 🎉 We're excited to have you here. Feel free to introduce
              yourselves, share your thoughts, and start the conversation. Let's make this a great
              space for everyone!
            </Typography>
            <SuggestedFeatures />
          </Box>
        ) : (
          <>
            {isLoading && <ChatMessageLoader />}
            {messages.map((message, index) => (
              <>
                <div style={{ position: 'relative' }}>
                  {hoveredMessageId === message.id && (
                    <MessageActions
                      canSendMessages={groupDetails?.allowSendingMessage}
                      message={message}
                      currentUserInfo={currentUserInfo}
                      handleDeleteMessage={handleDeleteMessage}
                      handleEditMessage={handleEditMessage}
                      handleReplyMessage={handleReplyMessage}
                      setHoveredMessageId={setHoveredMessageId}
                      showReactEmoji={showReactEmoji}
                      setReactionsData={setReactionsData}
                      reactionsData={reactionsData}
                    />
                  )}
                </div>
                <ChatMessagePanel
                  key={index}
                  message={message}
                  prevMessage={index > 0 ? messages[index - 1] : null}
                  isCurrentUser={message.sender_id === currentUserInfo.id}
                  style={{
                    pointerEvents: uploadLoader ? 'none' : 'auto',
                    opacity: uploadLoader ? 0.5 : 1,
                  }}
                  onMouseEnter={() => setHoveredMessageId(message.id)}
                  onMouseLeave={() => setHoveredMessageId(null)}
                  setHoveredMessageId={setHoveredMessageId}
                  handleEditMessage={handleEditMessage}
                  handleDeleteMessage={handleDeleteMessage}
                  handleReplyMessage={handleReplyMessage}
                  handleCancelReply={handleCancelReply}
                  replyTo={replyTo}
                  setReplyTo={setReplyTo}
                  currentUserInfo={currentUserInfo}
                />
                {reactionsData?.length > 0 &&
                  reactionsData.map((reactionData, index) => {
                    if (reactionData.msgId === message.id) {
                      return (
                        <ChatReactionBox
                          key={index}
                          reactionData={reactionData}
                          isCurrentUser={message.sender_id === currentUserInfo.id}
                          setReactionsData={setReactionsData}
                          reactionsData={reactionsData}
                        />
                      );
                    }
                  })}
                {editingMessageId === message.id && (
                  <Box ref={editBoxRef} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
                    <TextField
                      value={editingMessageText}
                      onChange={(e) => setEditingMessageText(e.target.value)}
                      fullWidth
                      multiline
                      variant="outlined"
                      size="small"
                      sx={{ mb: 1 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={handleCancelEdit} size="small" sx={{ mr: 1 }}>
                        Cancel
                      </Button>
                      <Button
                        onClick={() => handleEditMessageSubmit(message.id)}
                        size="small"
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            ))}
          </>
        )}
        <div ref={messagesEndRef} />
        {showScrollButton && (
          <Box
            sx={{
              position: 'sticky',
              bottom: '24px',
              display: 'flex',
              justifyContent: 'flex-end',
              paddingRight: '16px',
              zIndex: 1,
            }}
          >
            <IconButton
              onClick={scrollToBottom}
              sx={{
                backgroundColor: '#eeeff1',
                '&:hover': {
                  backgroundColor: '#dbe7ff',
                },
              }}
            >
              <ArrowDownwardIcon sx={{ color: '#8ca4f8' }} />
            </IconButton>
          </Box>
        )}
      </Box>
      {uploadLoader ? (
        <Box display={'flex'} position={'absolute'} top={'50%'} left={'50%'}>
          <Skeletons type={'LazySmallCircularLoader'} />
        </Box>
      ) : (
        uploadedFile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 'auto',
              padding: '10px',
              borderBottom: '1px solid #D3D3D3',
            }}
          >
            <img
              src={uploadedFile.url}
              alt={uploadedFile.file.name}
              style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
            />
            <span>{uploadedFile.file.name}</span>
          </Box>
        )
      )}
      {replyTo && (
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Replying to: {replyTo.message}
          </Typography>
          <IconButton onClick={handleCancelReply} size="small">
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
      {groupDetails?.allowSendingMessage ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
        >
          <ChatInputField
            ref={inputRef}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSendMessage={handleSendMessage}
            handleKeyPress={handleKeyPress}
            setShowEmojiPicker={setShowEmojiPicker}
            showEmojiPicker={showEmojiPicker}
            setUploadedFile={setUploadedFile}
            setUploadLoader={setUploadLoader}
          />
        </form>
      ) : isLoader ? (
        <ChatMessageLoader />
      ) : (
        <Box
          sx={{
            marginTop: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            backgroundColor: '#f0f0f0',
            color: '#555',
            padding: '5px',
            borderRadius: '5px',
            fontSize: '14px',
          }}
        >
          You can't send messages to this group because you're no longer a member.
        </Box>
      )}
      {showEmojiPicker && (
        <div style={{ position: 'absolute', bottom: '60px', right: '20px' }}>
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
      {openDetails && (
        <GroupDetails
          chat={groupDetails}
          setOpenDetails={setOpenDetails}
          setGroupList={setGroupList}
          groupList={groupList}
          groupMembers={members}
          setGroupMembers={setMembers}
          socket={socket}
          openDetails={openDetails}
        />
      )}
    </Box>
  );
}
