import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import TestCaseItem from './TestCaseItem';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const TestCase = ({ totalScore, overallTimeTaken, testCases }) => {
  const active = 'contained';
  const [currentTab, setCurrentTab] = useState(1);

  return (
    <Grid
      container
      spacing={2}
      className='testcaseContainer'
      sx={{
        p: 2,
        width: '100%',
        ml: 0,
        height:'auto',
        // maxHeight:'100dvh'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '-webkit-fill-available',
          mb: 2,
        }}
      >
        {totalScore !== undefined && totalScore !== null && (
          <Typography
            sx={{
              lineHeight: 1.6,
              color: '#021148',
              display: 'block',
            }}
            variant="subtitle2"
          >
            {`Total Score ${totalScore}`}
          </Typography>
        )}
        {overallTimeTaken && (
          <Typography
            sx={{
              lineHeight: 1.6,
              color: '#021148',
              display: 'block',
            }}
            variant="subtitle2"
          >
            {`Over All Runtime ${overallTimeTaken} ms`}
          </Typography>
        )}
      </Box>
      <Box
        mt={0}
        sx={{ py: 0, width: '-webkit-fill-available', display: 'flex', flexDirection: 'row' }}
      >
        {testCases?.length > 0 ? (
          <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pr: 2 }}>
              {testCases.map((tab, index) => {
                const caseIndex = index + 1;
                const isPassed = tab?.isPassed;
                const isFailed =
                  tab.output &&
                  (tab.output.includes('Failed') ||
                    tab.output === 'NOT AVAILABLE FOR THIS TESTCASE');
                return (
                  <Button
                    size={'small'}
                    key={caseIndex}
                    sx={{
                      textTransform: { xs: 'unset' },
                      boxShadow: 'none',
                      width: 'max-content',
                      borderRadius: '4px',
                      fontSize: { xs: '12px' },
                      px: 1,
                      color:
                        currentTab === caseIndex
                          ? isFailed
                            ? '#ff1313'
                            : '#021148'
                          : isFailed
                          ? '#ff1313'
                          : '#021148',
                      backgroundColor: currentTab === caseIndex ? '#f5f5f5' : '#fff',
                      '&:hover': {
                        color:
                          currentTab === caseIndex
                            ? isFailed
                              ? '#ff1313'
                              : '#021148'
                            : isFailed
                            ? '#ff1313'
                            : '#021148',
                        backgroundColor: currentTab === caseIndex ? '#f5f5f5' : '#fff',
                        boxShadow: 'none',
                      },
                    }}
                    variant={currentTab === caseIndex ? active : 'text'}
                    onClick={() => setCurrentTab(caseIndex)}
                  >
                    {isPassed !== undefined && (
                      <>
                        {!isPassed ? (
                          <CloseRoundedIcon
                            sx={{
                              color: '#ff1313',
                              mr: 1,
                              fontSize: '16px',
                            }}
                          />
                        ) : (
                          <CheckRoundedIcon
                            sx={{
                              color: '#4caf50',
                              mr: 1,
                              fontSize: '16px',
                            }}
                          />
                        )}
                      </>
                    )}
                    {`Test case ${caseIndex}`}
                  </Button>
                );
              })}
            </Box>
            {testCases[currentTab - 1] && (
              <TestCaseItem
                key={currentTab - 1}
                testCase={testCases[currentTab - 1]}
                index={currentTab - 1}
              />
            )}
          </>
        ) : (
          <Typography>No Test Cases Available</Typography>
        )}
      </Box>
    </Grid>
  );
};

export default TestCase;
