import { Box, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import ProblemNavbar from './ProblemNavbar';
import SolveProblemsTags from './SolveProblemsTags';
import HintsAccordion from './HintsAccordion';

const SolveExplanation = ({
  scrollToHints,
  hintsRef,
  tagsRef,
  scrollToTags,
  problem,
  codingProblemData,
}) => {
 
  const inputParameters = codingProblemData?.inputParameters?.map((param) => JSON.parse(param));
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          p: 0,
          pt: 2,
          width: '100%',
          ml: 0,
          height: 'auto',
          maxHeight: '100dvh',
        }}
      >
        {problem && (
          <ProblemNavbar
            problem={problem}
            scrollToHints={scrollToHints}
            scrollToTags={scrollToTags}
          />
        )}
        {problem.statement?.filter(Boolean).length > 0 && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <Grid item xs={12} p={'0px!important'}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '400',
                }}
              >
                {'Problem Statement'}
              </Typography>
              <List mt={0} sx={{ py: 0 }}>
                {problem.statement?.map((ele, index) => {
                  return (
                    ele && (
                      <ListItem
                        key={index}
                        sx={{
                          borderLeft: '1px solid #eee',
                          pl: 2,
                          my: 2,
                          py: 0,
                          '&:last-child': {
                            mb: 0,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.6,
                            color: '#021148',
                          }}
                          variant="subtitle2"
                        >
                          {ele}
                        </Typography>
                      </ListItem>
                    )
                  );
                })}
              </List>
            </Grid>
          </Grid>
        )}
        {inputParameters?.length > 0 && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <Grid item xs={12} p={'0px!important'}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '400',
                }}
              >
                {'Input Parameters'}
              </Typography>
              <List mt={0} sx={{ py: 0 }}>
                {inputParameters?.map((param, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      borderLeft: '1px solid #eee',
                      pl: 2,
                      my: 2,
                      py: 0,
                      '&:last-child': {
                        mb: 0,
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        lineHeight: 1.6,
                        color: '#021148',
                      }}
                      variant="subtitle2"
                    >
                      {param.name} :
                      <span style={{ color: '#787878', fontWeight: '300' }}> {param.type}</span>
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        )}
        {codingProblemData?.testCases && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <Grid item xs={12} p={'0px!important'}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '400',
                }}
              >
                {'Example Test Cases'}
              </Typography>
              <List mt={0} sx={{ py: 0 }}>
                {codingProblemData?.testCases?.map((ele, index) => {
                  return (
                    ele && (
                      <ListItem
                        key={index}
                        sx={{
                          borderLeft: '1px solid #eee',
                          pl: 2,
                          my: 2,
                          py: 0,
                          '&:last-child': {
                            mb: 0,
                          },
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.6,
                            color: '#021148',
                            mb: 0.5,
                            display: 'block',
                          }}
                          variant="subtitle2"
                        >
                          Input:
                          <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
                            {Object?.entries(ele?.input).map(([key, value], i) => (
                              <span key={i}>
                                {key}: {Array.isArray(value) ? `[${value.join(', ')}]` : value}
                                {i < Object?.entries(ele.input).length - 1 ? ', ' : ''}
                              </span>
                            ))}
                          </span>
                        </Typography>

                        <Typography
                          sx={{
                            lineHeight: 1.6,
                            color: '#021148',
                            display: 'block',
                          }}
                          variant="subtitle2"
                        >
                          Output:
                          <span style={{ color: '#787878', fontWeight: '300', marginLeft: '8px' }}>
                            {Array.isArray(ele?.expected) ? `[${ele?.expected.join(', ')}]` : ele?.expected}
                          </span>
                        </Typography>
                      </ListItem>
                    )
                  );
                })}
              </List>
            </Grid>
          </Grid>
        )}

        {problem?.data?.constraints?.filter(Boolean).length > 0 && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <Grid item xs={12} p={'0px!important'}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '400',
                }}
              >
                {'Constraints'}
              </Typography>
              <List mt={0} sx={{ py: 0 }}>
                {problem?.data?.constraints?.map((ele, index) => {
                  return (
                    ele && (
                      <ListItem
                        key={index}
                        sx={{
                          borderLeft: '1px solid #eee',
                          pl: 2,
                          my: 2,
                          py: 0,
                          '&:last-child': {
                            mb: 0,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.6,
                            color: '#021148',
                          }}
                          variant="subtitle2"
                        >
                          {ele}
                        </Typography>
                      </ListItem>
                    )
                  );
                })}
              </List>
            </Grid>
          </Grid>
        )}
        {problem?.data?.moreInfo?.filter(Boolean).length > 0 && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <Grid item xs={12} p={'0px!important'}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '400',
                }}
              >
                {'More Info'}
              </Typography>
              <List mt={0} sx={{ py: 0 }}>
                {problem?.data?.moreInfo?.map((ele, index) => {
                  return (
                    ele && (
                      <ListItem
                        key={index}
                        sx={{
                          borderLeft: '1px solid #eee',
                          pl: 2,
                          my: 2,
                          py: 0,
                          '&:last-child': {
                            mb: 0,
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: 1.6,
                            color: '#021148',
                          }}
                          variant="subtitle2"
                        >
                          {ele}
                        </Typography>
                      </ListItem>
                    )
                  );
                })}
              </List>
            </Grid>
          </Grid>
        )}
        {problem?.data?.tags?.filter(Boolean).length > 0 && (
          <Grid
            ref={tagsRef}
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <SolveProblemsTags tags={problem?.tags} />
          </Grid>
        )}
        {problem?.data?.hints?.filter(Boolean).length > 0 && (
          <Grid
            ref={hintsRef}
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <HintsAccordion hints={problem?.data?.hints} />
          </Grid>
        )}
        {problem?.data?.author?.filter(Boolean).length > 0 && (
          <Grid
            container
            spacing={2}
            sx={{
              p: 2,
              ml: 0,
              mt: 0,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '400',
                mb: '12px',
              }}
            >
              {'Author'}
            </Typography>
            <Grid
              item
              xs={12}
              p={'0px!important'}
              sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}
            >
              {problem?.data?.author?.map((author, index) => {
                return (
                  <Box key={index} display="flex" flexDirection="row" alignItems="center">
                    <IconButton
                      sx={{
                        width: { xs: 30, md: 36 },
                        height: { xs: 30, md: 36 },
                        borderRadius: 1,
                        bgcolor: '#d9d9d9',
                        color: '#505050',
                        '&:hover': {
                          backgroundColor: '#d9d9d9',
                        },
                        fontSize: { xs: '14px', md: '16px' },
                        mr: 1.5,
                      }}
                    >
                      {author.slice(0, 1)}
                    </IconButton>
                    <Typography
                      sx={{
                        mr: 0.5,
                        fontWeight: '500',
                        fontSize: { xs: '14px', md: '16px' },
                        display: '-webkit-box!important',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: ' vertical',
                      }}
                      variant="body1"
                      color="black"
                    >
                      {author}
                    </Typography>
                  </Box>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SolveExplanation;
