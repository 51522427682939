export const formatDateTime = (timestamp, isSmallScreen) => {
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    };
  
    const dateOptionsLarge = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
  
    const dateOptionsSmall = {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    };
  
    const dateFormatter = new Intl.DateTimeFormat(
      'en-GB',
      isSmallScreen ? dateOptionsSmall : dateOptionsLarge
    );
  
    const timeFormatter = new Intl.DateTimeFormat('en-GB', timeOptions);
  
    let date;
    if (typeof timestamp === 'string') {
      date = new Date(timestamp);
    } else if (typeof timestamp === 'number') {
      date = new Date(timestamp < 1e12 ? timestamp * 1000 : timestamp);
    } else {
      return "Unavailable"
    }
  
    const formattedTime = timeFormatter
      .format(date)
      .replace(/am|pm/i, (match) => match.toUpperCase());
    const formattedDate = dateFormatter.format(date);
  
    return `${formattedTime}, ${formattedDate}`;
  };
  