import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function SolveProblemsTags({ tags }) {
  return (
    tags?.length > 0 &&
    tags[0] !== '' && (
      <Grid item xs={12} p={'0px!important'}>
        <Accordion m={0} p={0} defaultExpanded={false} sx={{ boxShadow: 'none', mt: 0 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              border: '1px solid #e9e7e7',
              minHeight: { xs: '48px!important' },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '400',
              }}
            >
              Tags
            </Typography>
          </AccordionSummary>

          <AccordionDetails
            sx={{ border: '1px solid #e9e7e7', borderTop: 'none', px: '0px', p: 2 }}
          >
            <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
              {tags?.map((tag, index) => {
                return (
                  <Chip
                  key={index}
                    p={0.5}
                    sx={{
                      maxHeight: '20px!important',
                      '.MuiChip-label': {
                        px: '8px',
                      },
                      bgcolor: '#f1f1f1',
                      cursor: 'pointer',
                    }}
                    label={
                      <Typography
                        sx={{
                          fontSize: '12px',
                          fontWeight: '400',
                          textTransform: 'capitalize',
                          color: '#021148',
                          lineHeight: '12px',
                        }}
                        variant="caption"
                      >
                        {tag}
                      </Typography>
                    }
                    variant="filled"
                  />
                );
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
    )
  );
}

export default SolveProblemsTags;
