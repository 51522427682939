import React, { useState } from 'react';
import { Grid, Typography, List, ListItem, ListItemText, Divider, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getOrgName } from '../../utils/appendOrgQuery';
import CircleIcon from '../../assets/ContestImages/circle.svg';
import CircleActiveIcon from '../../assets/ContestImages/circleActive.svg';
import CircleCompletedIcon from '../../assets/ContestImages/circleCompleted.svg';
import { Colors } from '../../theme/Theme';

function QuestionNavigator({
  questions,
  drawerWidth = 200,
  handleNavigate,
  problemId,
}) {
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const navigate = useNavigate();
  const orgName = getOrgName();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const savedOptions = JSON.parse(localStorage.getItem('selectedOption'));
  const handleQuestionSelect = (index) => {
    setCurrentQuestionIndex(index);
  };

  return (
    <>
      {isDesktop ? (
        <Grid
          item
          sx={{
            width: `${drawerWidth}px`,
            bgcolor: '#fff',
            height: '100vh',
            borderRight: '1px solid #eee',
            overflowY: 'auto',
            position: 'fixed',
          }}
        >
          <Typography variant="h6" sx={{ p: 2 }}>
            Questions
          </Typography>
          <Divider />
          <List>
            {questions?.map((question, index) => {
              const attemptedQuestion = questions?.find(
                (option) =>
                  option?.submission?.problemId === question.id &&
                  Object.keys(option?.submission.content || {}).length > 0
              );
              return (
                <ListItem
                  button
                  key={index}
                  sx={{
                    borderLeft: question.id === problemId ? ' 4px solid #698AFF' : null,
                    backgroundColor: question.id === problemId ? ' #F7F7F7' : '',
                  }}
                  onClick={() => (handleNavigate(question?.id), handleQuestionSelect(index))}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontSize: '14px',
                      color: '#0f0f0f',
                      lineHeight: 'normal',
                    }}
                    primary={`Question ${index + 1}`}
                  />
                  {question.id === problemId ? (
                    attemptedQuestion ? (
                      <img src={CircleCompletedIcon} alt="Completed" />
                    ) : (
                      <img src={CircleActiveIcon} alt="Active" />
                    )
                  ) : attemptedQuestion ? (
                    <img src={CircleCompletedIcon} alt="Completed" />
                  ) : (
                    <img src={CircleIcon} alt="Default" />
                  )}
                </ListItem>
              );
            })}
          </List>
        </Grid>
      ) : (
        <Box
          sx={{
            width: '100%',
            bgcolor: '#fff',
            borderTop: '1px solid #eee',
            borderBottom: '1px solid #eee',
            position: 'sticky',
            bottom: 0,
            zIndex: 2,
            display: 'flex',
            overflowX: 'auto',
          }}
        >
          {questions.map((question, index) => {
            const attemptedQuestion = questions?.find(
              (option) =>
                option?.submission?.problemId === question.id &&
                Object.keys(option?.submission.content || {}).length > 0
            );
            return (
              <Box
                key={index}
                sx={{
                  padding: '10px 16px',
                  cursor: 'pointer',
                  textAlign: 'center',
                  minWidth: '50px',
                  bgcolor:
                    question.id === problemId
                      ? attemptedQuestion
                        ? Colors.correctAnswer
                        : Colors.currentQuestionBg
                      : attemptedQuestion
                      ? Colors.correctAnswer
                      : '#fff',
                  '&:hover': { bgcolor: '#f0f0f0' },
                }}
                onClick={() => (handleNavigate(question?.id), handleQuestionSelect(index))}
              >
                <Typography variant="body2">{index + 1}</Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </>
  );
}

export default QuestionNavigator;
