import React from 'react';
import '../ViewOrganizations.css';
import RequestToJoinBtn from '../Buttons/RequestToJoin';
import { Box, Typography } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { Colors } from 'chart.js';

export default function OrgPhone({ Data, orgMemberData, myCoursesData }) {
  if (!Data) {
    return null;
  }
  const renderOrgProfilePhoto = () => {
    if (Data.logoLink) {
      return <img className="viewFirstRow-logoImg" src={Data.logoLink} alt="" />;
    } else {
      const orgInitials = Data.name?.charAt(0) || '';
      return (
        <div className="orgLogo-initials-container">
          <span>{orgInitials}</span>
        </div>
      );
    }
  };
  return (
    <div className="viewFirstRowPhone">
      <div className="viewFirstRowPhone-1">
        {renderOrgProfilePhoto()}{' '}
        <Typography
          variant="h6"
          sx={{
            pb: 0,
            mb: 0.5,
            color: Colors.headingPrimary,
            display: '-webkit-box',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
          }}
        >
          {Data.name}
        </Typography>
      </div>
      <div className="viewFirstRowPhone-2">
        <Typography
          sx={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitBoxOrient: 'vertical',
            color: Colors.subTitleColor,
            mb: 0.5,
          }}
          variant="body1"
        >
          {Data?.description}
        </Typography>
        <div className="viewFirstRow-info2">
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <PeopleAltOutlinedIcon sx={{ fontSize: '18px', color: '#2B3746' }} />
            <Typography variant="subtitle2" fontWeight={300} color={'#787878'}>
              Members:
            </Typography>
            <Typography variant="subtitle2" color="#2B3746">
              {orgMemberData.length}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <MenuBookOutlinedIcon sx={{ fontSize: '18px', color: '#2B3746' }} />
            <Typography variant="subtitle2" fontWeight={300} color={'#787878'}>
              Courses:
            </Typography>
            <Typography variant="subtitle2" color="#2B3746">
              {myCoursesData?.length}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <AssignmentOutlinedIcon sx={{ fontSize: '18px', color: '#2B3746' }} />
            <Typography variant="subtitle2" fontWeight={300} color={'#787878'}>
              Tasks:
            </Typography>
            <Typography variant="subtitle2" color="#2B3746">
              {Data.tasks.totalTasks}
            </Typography>
          </Box>
        </div>
      </div>
      <RequestToJoinBtn disabled={Data.isAlreadyRequested} OrganizationID={Data.id} />
    </div>
  );
}
