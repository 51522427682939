import { createTheme } from '@mui/material/styles';
export const Colors = {
  primary: '#698AFF',
  secondary: '#fff',
  light_gray: '#c2c2c2',
  white: '#fff',
  correctAnswer: '#61cb8e',
  currentQuestionBg: '#698aff14',
  bgColor: '#F4F6FF',
  heading: '#021148',

  // heading color for this bgColor:'#F4F6FF',
  headingColor: '#021148',
  subHeadingColor: '#35496b',
  paragraphColor: '#5a6783',
  borderColor: '#E1E1E1',

  // popColors
  headingPrimary: '#021148e6',
  subTitleColor: '#787878',

  orgPagesHeader: {
    titleColor: '#000000de',
    descriptionColor: '#808080',
    bg: '#ffffff',
    border: '#E1E1E1',
  },

  // Card Colors
  card: {
    bg: '#ffffff',
    heading: '#021148', // Dark for strong contrast
    cardParagraph: '#3f4859', // Muted for readability
    cardCaption: '#3e434be6', // Subtle, lighter for captions
    cardBorder: '#dce3f2',
    task: {
      bgColor: '#ffffff',
      heading: '#021148',
      cardParagraph: '#808080',
      cardCaption: '#3e434be6',
      cardBorder: '#dce3f2',
      boxShadow: '0 4px 12px rgba(105, 138, 255, 0.05)',
      avatarBg: '#698AFF',
      sabTaskIconColor: '#698AFF',
      borderBottom: '#e0e0e0',
      priority: { bg: '#B22222', color: '#ffffff' },
      isFileRequired: 'red',
      isEndingTodayLabel: {
        bg: '#FF000010',
        titleColor: '#FF0000',
      },
      isEndingTomorrowLabel: {
        bg: '#FFA50010',
        titleColor: '#FFA500',
      },
      contestCode: {
        color: '#4DB6AC',
        borderCOlor: ' #e0f2f1',
      },
    },
  },

  hacksthon: {
    bg: '#FFD89B',
    text: '#BA5A00',
  },
  quizContest: {
    bg: '#D6EDFF',
    text: '#35517A',
  },
  draftContest: {
    bg: '#E0E0E0',
    text: '#4F4F4F',
  },

  cardsShadow: 'rgba(105, 138, 255, 0.05)',
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
    ErrorBtn: {
      main: '#d32f2f',
      contrastText: '#fff',
    },
    ChipColor: {
      main: '#F4F7FF',
      contrastText: '#698AFF',
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      xsMedium: 410,
      sm: 600,
      md: 900,
      mds: 1000,
      slg: 1100,
      lg: 1200,
      xlg: 1325,
      xl: 1536,
      uxl: 2000,
    },
  },

  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export default theme;
