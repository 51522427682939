import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';

const ProblemNavbar = ({ scrollToHints,problem, scrollToTags }) => {
  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          p: 2,
          ml: 0,
        }}
      >
        <Grid item xs={12} p={'0px!important'}>
          <Typography
            variant="h6"
            sx={{
              fontWeight: '400',
              fontSize: { xs: '16px', md: '20px' },
            }}
          >
            {problem?.title}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={'auto'}
          p={'0px!important'}
          mt={{ xs: 1, display: 'flex', alignItems: 'center' }}
        >
          <Typography
            variant="caption"
            sx={{
              fontWeight: '300',
              color: 'grey',
              lineHeight: '16px',
            }}
          >
            Problem code:
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            sx={{
              fontWeight: '500',
              color: '#000000',
              lineHeight: '18px',
              display: '-webkit-box!important',
              WebkitLineClamp: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitBoxOrient: ' vertical',
              fontSize: { xs: '12px', lg: '14px' },
            }}
          >
            {problem?.code}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={'auto'} p={'0px!important'} ml={1} mt={1} display="flex" gap={1}>
          <Chip
            p={0.5}
            sx={{
              border: 'none',
              maxHeight: '20px!important',
              '.MuiChip-label': {
                px: '8px',
                cursor: 'pointer',
              },

              bgcolor:
                problem?.difficultyLevel === 'MEDIUM'
                  ? '#f4c90010'
                  : problem?.difficultyLevel === 'HARD'
                  ? '#FF000010'
                  : problem?.difficultyLevel === 'EASY'
                  ? '#228c2210'
                  : 'transparent',
            }}
            label={
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  textTransform: 'capitalize',
                  color:
                    problem?.difficultyLevel === 'MEDIUM'
                      ? '#f4c900'
                      : problem?.difficultyLevel === 'HARD'
                      ? '#FF0000'
                      : problem?.difficultyLevel === 'EASY'
                      ? '#228c22'
                      : '#343434',
                  lineHeight: '12px',
                }}
                variant="caption"
              >
                {problem?.difficultyLevel}
              </Typography>
            }
            variant="filled"
          />
          <Chip
            p={0.5}
            onClick={scrollToTags}
            sx={{
              maxHeight: '20px!important',
              '.MuiChip-label': {
                px: '8px',
              },
              bgcolor: '#eee',
              cursor: 'pointer',
            }}
            label={
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  textTransform: 'capitalize',
                  color: '333',
                  lineHeight: '12px',
                }}
                variant="caption"
              >
                {'Tags'}
              </Typography>
            }
            variant="filled"
          />
          <Chip
            p={0.5}
            onClick={scrollToHints}
            sx={{
              maxHeight: '20px!important',
              '.MuiChip-label': {
                px: '8px',
                cursor: 'pointer',
              },
              bgcolor: '#eee',
            }}
            label={
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  textTransform: 'capitalize',
                  color: '333',
                  lineHeight: '12px',
                }}
                variant="caption"
              >
                {'Hint'}
              </Typography>
            }
            variant="filled"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProblemNavbar;
