import React, { useEffect, useState } from 'react';
import { contestProblemAPI } from '../../../../api/requests/contests/contestProblemAPI';
import { useLocation, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { formatDateTime } from '../../../../utils/formateDataTime';
import TestCase from '../../../CodingContests/ContestProblem/ProblemSolve/TestCase';
import { handleAlert } from '../../../../utils/handleAlert';
import defaultImage from '../../../../assets/CourseImages/Thumbnail.webp';

const ResultUserId = () => {
  const { contestId, userId } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isCandidate = query.get('candidate') === 'true';
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [contestData, setContestData] = useState();
  const [problemData, setProblemData] = useState();
  const [totalScore, setTotalScore] = useState();
  const [scores, setScores] = useState({});
  const [editingEnabled, setEditingEnabled] = useState('');
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');
  const [problemType, setProblemType] = useState('');
  const [testCases, setTestCases] = useState('[]');
  const [overallTimeTaken, setOverallTimeTaken] = useState('');
  const [loader, setLoader] = useState(false);
  const [selectedOption, setSelectedOption] = useState([]);

  const getResultByUserId = async () => {
    setLoader(true);
    try {
      const data = isCandidate
        ? await contestProblemAPI.getResultsCandidate(contestId)
        : await contestProblemAPI.getResultsByUserId(contestId, userId);

      if (isCandidate) {
        setContestData(data?.results.contestData);
        setProblemData(data?.results.problemData);
        setTotalScore(data?.results.totalScore);
      } else if (!isCandidate) {
        setContestData(data?.contestData);
        setProblemData(data?.problemData);
        setTotalScore(data?.totalScore);
        const initialScores = {};

        data?.problemData?.forEach((submission) => {
          initialScores[submission?.submission.id] = submission?.submission.score || '0';
        });
        setScores(initialScores);
      }
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const handleButtonClick = async (event, id, userId, problemId) => {
    try {
      const score = scores[id] || 0;
      const body = { contestId, userId, id, problemId, score };
      await contestProblemAPI.updateScore(body);
    } catch (err) {
      handleAlert('Cannot update score', 'error');
    }
    setEditingEnabled('');
  };

  const showContent = (content, type) => {
    setOpen(true);
    setContent(content);
    setProblemType(type);
    if (type === 'CODING') {
      const testCase = JSON.parse(content.testCases);
      setTestCases(testCase?.results);
      setOverallTimeTaken(testCase?.overallTimeTaken);
      let total = testCase?.results?.reduce(
        (acc, testCase) => {
          acc + testCase?.score;
          if (testCase.isPassed) {
            acc.passed += 1;
          } else {
            acc.failed += 1;
          }
          acc.totalTestCases += 1;
          return acc;
        },
        { totalScore: 0, passed: 0, failed: 0, totalTestCases: 0 }
      );
      setTotalScore(total?.totalScore);
      setIsPassedTestCases({
        passed: total?.passed,
        failed: total?.failed,
        totalTestCases: total?.totalTestCases,
      });
    } else if (type === 'UI') {
    } else if (type === 'MCQ' || type === 'MAQ') {
      setSelectedOption({ content: content });
    }
  };
  const handleUpdateScore = (username, newScore) => {
    setScores((prevScores) => ({
      ...prevScores,
      [username]: newScore,
    }));
  };

  const onClose = () => {
    setOpen(false);
  };

  const openNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };

  useEffect(() => {
    getResultByUserId();
  }, []);

  let passed = 0;
  let failed = 0;
  const renderSubmissionRows = () => {
    return problemData?.map((item, index) => {
      const submissionContent = JSON.parse(item?.submission?.content ?? "{}");
      if (item?.submission?.problemType === 'CODING') {
        const testCaseData = JSON.parse(submissionContent?.testCases);
        const total = testCaseData?.results?.reduce(
          (acc, testCase) => {
            if (testCase?.isPassed) {
              acc.passed += 1;
            } else {
              acc.failed += 1;
            }
            return acc;
          },
          { passed: 0, failed: 0 }
        );

        passed = total?.passed;
        failed = total?.failed;
      }

      return (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell sx={{ width: '15%!important' }}>
            <Box
              sx={{
                maxWidth: 'fit-content',
                p: { xs: '4px 12px', md: '4px 16px' },
                boxShadow: 0,
                textTransform: 'none',
                color: '#698AFF',
                backgroundColor: 'transparent',
                borderRadius: '20px',
                border: '1px solid #DEE6FB ',
                minWidth: 'max-content',
              }}
            >
              <Typography sx={{ fontSize: { xs: '12px' }, fontWeight: '400' }} variant="body2">
                {formatDateTime(item?.updatedAt)}
              </Typography>
            </Box>
          </TableCell>
          <TableCell
            sx={{
              minWidth: { xs: '180px', sm: '200px' },
            }}
          >
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {`${item?.title}`}
            </Typography>
          </TableCell>
          <TableCell>{item?.type}</TableCell>
          <TableCell>{item?.code}</TableCell>

          <TableCell sx={{ width: '15%!important' }}>
            {isCandidate ? (
              item.submission.score
            ) : (
              <Box display="flex" alignItems="center" sx={{ gap: 1 }}>
                <TextField
                  id={item?.submission.id}
                  type="number"
                  size="small"
                  value={scores[item?.submission?.id]}
                  style={{ minWidth: '75px', maxWidth: '100px' }}
                  disabled={editingEnabled === item?.submission?.id ? false : true}
                  onChange={(e) => handleUpdateScore(item?.submission?.id, e.target.value)}
                />
                {editingEnabled === item?.submission.id ? (
                  <Button
                    onClick={(e) =>
                      handleButtonClick(e, item?.submission.id, item?.submission.userId, item?.submission.problemId)
                    }
                  >
                    Update
                  </Button>
                ) : (
                  <Button onClick={(e) => setEditingEnabled(item?.submission.id)}>Edit</Button>
                )}
              </Box>
            )}
          </TableCell>
          <TableCell sx={{ minWidth: '400px!important' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: 'space-between',
              }}
            >
              {item?.submission.problemType === 'UI' && (
                <Typography
                  sx={{ cursor: 'pointer', ':hover': { color: '#698AFF' } }}
                  onClick={() => openNewTab(submissionContent.deployedLink)}
                >
                  {submissionContent.deployedLink}
                </Typography>
              )}
              {item?.submission?.problemType === 'CODING' && (
                <>
                  <Typography>{`TestCases: Passed: ${passed}, Failed: ${failed}.`}</Typography>
                </>
              )}
              {item?.submission.problemType === 'ESSAY' && (
                <Typography
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    maxWidth: '300px',
                  }}
                >{`${submissionContent}`}</Typography>
              )}
              {(item?.submission.problemType === 'MCQ' ||
                item?.submission.problemType === 'MAQ') && (
                  <>
                    <List component="ol" marker="disc">
                      {submissionContent?.map((contentItem) => {
                        return (
                          <ListItem key={item} sx={{ px: '0px!important' }}>
                            {contentItem?.type === 'IMAGE' ? (
                              <img
                                src={contentItem?.content || './'}
                                alt={`Option ${index + 1}`}
                                style={{
                                  width: 'auto',
                                  height: 'auto',
                                  maxWidth: '100px',
                                  maxHeight: '80px',
                                  objectFit: 'cover',
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = defaultImage;
                                }}
                              />
                            ) : (
                              <Typography
                                sx={{
                                  color: '#021148',
                                  fontSize: 16,
                                  fontWeight: '500',
                                  lineHeight: 1.5,
                                }}
                              >
                                {contentItem?.content || 'Title'}
                              </Typography>
                            )}
                          </ListItem>
                        );
                      })}
                    </List>
                  </>
                )}
              <Button
                onClick={() => showContent(submissionContent, item?.submission.problemType)}
                sx={{ minWidth: 'fit-content' }}
                size={'small'}
                variant="outlined"
              >
                {'View Details'}
              </Button>
            </Box>
          </TableCell>
        </TableRow>
      );
    });
  };

  const drawerWidth = 200;

  return (
    <Box
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      {' '}
      {loader ? (
        <Box
          sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100dvh' }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack
            spacing={1}
            sx={{
              textAlign: 'start',
              width: '100%',
              borderBottom: ' 1px solid #E1E1E1',
              py: { xs: 3, md: 2 },
              px: { xs: 2, md: 4 },
            }}
          >
            <Typography variant="h5" sx={{ pb: 0 }}>
              {contestData?.title}
            </Typography>
            <Typography
              sx={{ color: '#787878', fontSize: { xs: '14px', md: '16px' }, lineHeight: 1.2 }}
              variant="subtitle1"
            >
              {`Problem Type: ${contestData?.type}`}
            </Typography>
          </Stack>
          <Box sx={{ px: { xs: 2, md: 4 }, mt: { xs: 2, md: 4 } }}>
            <Paper
              sx={{
                boxShadow: 'none',
                border: '1px solid #E7E7ED',
                borderRadius: 2,
                overflow: 'hidden',
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: '#F3F5FC' }}>
                      <TableCell sx={{ fontWeight: 500, color: '#021148' }}>
                        Submission Time
                      </TableCell>
                      <TableCell sx={{ fontWeight: 500, color: '#021148' }}>Problem Name</TableCell>
                      <TableCell sx={{ fontWeight: 500, color: '#021148' }}>Type</TableCell>
                      <TableCell sx={{ fontWeight: 500, color: '#021148' }}>Code</TableCell>
                      <TableCell sx={{ fontWeight: 500, color: '#021148' }}>Score</TableCell>
                      <TableCell sx={{ fontWeight: 500, color: '#021148' }}>
                        Submission Content
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{renderSubmissionRows()}</TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Box>
        </>
      )}
      <Dialog open={open} onClose={onClose} sx={{ minWidth: '400px!important' }}>
        <DialogTitle>Submitted content</DialogTitle>
        <DialogContent>
          {(problemType === 'MCQ' || problemType === 'MAQ') && (
            <>
              <List component="ol" marker="disc">
                {content?.map((item, index) => {
                  return (
                    <ListItem key={item} sx={{ px: '0px!important' }}>
                      {item?.type === 'IMAGE' ? (
                        <img
                          src={item?.content || './'}
                          alt={`Option ${index + 1}`}
                          style={{
                            width: 'auto',
                            height: 'auto',
                            maxWidth: '100px',
                            maxHeight: '80px',
                            objectFit: 'cover',
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = defaultImage;
                          }}
                        />
                      ) : (
                        <Typography
                          sx={{
                            color: '#021148',
                            fontSize: 16,
                            fontWeight: '500',
                            lineHeight: 1.5,
                          }}
                        >
                          {item?.content || 'Title'}
                        </Typography>
                      )}
                    </ListItem>
                  );
                })}
              </List>
            </>
          )}
          {problemType === 'UI' && (
            <>
              <Typography sx={{ mb: 1 }}>{`Deployed link : ${content?.deployedLink}`}</Typography>
              <Typography
                sx={{ mb: 1 }}
              >{`Github username : ${content?.githubUsername}`}</Typography>
              <Typography sx={{ mb: 1 }}>{`Github repository : ${content?.githubRepo}`}</Typography>
              <Typography>{`Github language : ${content?.language}`}</Typography>
            </>
          )}
          {problemType === 'CODING' && (
            <>
              <Typography sx={{ mb: 2 }}>{`language : ${content?.language}`}</Typography>
              <Box sx={{ p: 2, mb: 2, bgcolor: '#F3F5FC' }}>
                {/* <Typography>{`Code : ${content?.code}`}</Typography> */}
                <TextField
                  value={content?.code}
                  multiline
                  fullWidth
                  minRows={10}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Typography
                sx={{
                  lineHeight: 1.6,
                  mb: 2,
                  color: '#021148',
                  display: 'block',
                }}
                variant="subtitle2"
              >
                {`TestCases: Total ${passed + failed}, Passed: ${passed}, Failed: ${failed}.`}
              </Typography>
              <TestCase
                totalScore={totalScore}
                overallTimeTaken={overallTimeTaken}
                testCases={testCases}
              />
            </>
          )}
          {problemType === 'ESSAY' && <Typography>{`${content}`}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ResultUserId;
