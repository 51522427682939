import React from 'react';
import Nav from '../../components/Header/Navbar';
import Footer from '../../components/Header/Footer';
import Mid from '../../components/LandingPage/Org/OrganizationsPage';
import Invite from '../../components/Invite/Invite';
import { useParams } from 'react-router-dom';
import OrganizationHomePage from '../../pages/Onboarding/Dashboard';

export default function EmailInvite({
  setSelectedOrgName,
  scrollToSection,
  featuresRef,
  whyChooseUsRef,
  pricingRef,
  homeRef,
}) {
  const { inviteId } = useParams();
  return (
    <div>
      <Invite inviteId={inviteId} setSelectedOrgName={setSelectedOrgName} />
      <OrganizationHomePage
        scrollToSection={scrollToSection}
        featuresRef={featuresRef}
        whyChooseUsRef={whyChooseUsRef}
        pricingRef={pricingRef}
        homeRef={homeRef}
      />
      {/* <Nav />
      <Mid />
      <Footer /> */}
    </div>
  );
}
