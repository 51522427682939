import performRequest from '../../axios';

export const candidateAPI = {
  getContestById: async (contestId) => {
    return await performRequest(`candidate/contest/${contestId}`, 'GET');
  },
  endContest: async (contestId) => {
    return await performRequest(`candidate/contest/${contestId}/END`, 'GET');
  },
  startContest: async (contestId) => {
    return await performRequest(`candidate/contest/${contestId}/START`, 'GET');
  },
  tabSwitchCount: async (contestId) => {
    return await performRequest(`candidate/contest/${contestId}/TAB_SWITCH`, 'GET');
  },
  problemSubmission: async (contestId, body) => {
    return await performRequest(`candidate/submission/${contestId}`, 'POST', body);
  },
}