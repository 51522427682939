import React from 'react';
import {
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  Box,
  Typography,
  Button,
} from '@mui/material';
import defaultImage from '../../../assets/CourseImages/Thumbnail.webp';

export default function OptionsComponent({ options, selectedOption = {}, type, onOptionChange }) {
  let choiceIds = selectedOption?.content?.choiceIds || [];
  return (
    <Box sx={{ pr: 2, pl: { xs: 2 } }}>
       <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500',
                mb: 2,
              }}
            >
              {type === 'MAQ' ? 'Select one or more options' : 'Select only one'}
            </Typography>

      {type === 'MCQ' && choiceIds[0] && (
        <Button
          onClick={() => onOptionChange('')}
          variant="outlined"
          color="primary"
          size="small"
          sx={{ mb: 1 }}
        >
          Clear Selection
        </Button>
      )}
      {type === 'MCQ' ? (
        <RadioGroup value={choiceIds[0] || ''} onChange={(e) => onOptionChange(e.target.value)}>
          {options?.map((option, index) => (
            <Box
              key={option.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                borderRadius: 1,
                border: '1px solid #EAEAEA',
                transition: 'background 0.3s, border 0.3s',
                borderColor: choiceIds[0] === option.id ? '#698AFF' : '#EAEAEA',
                background:
                  choiceIds[0] === option.id ? 'rgba(105, 138, 255, 0.06)' : 'transparent',
                '&:hover': { background: 'rgba(105, 138, 255, 0.06)', borderColor: '#698AFF' },
                mb: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Radio
                    value={option?.id}
                    sx={{
                      color: '#BCBCBC',
                      '&.Mui-checked': {
                        color: '#698AFF',
                      },
                    }}
                  />
                }
                label={
                  option?.type === 'IMAGE' ? (
                    <img
                      src={option?.content}
                      alt={`Option ${index + 1}`}
                      style={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '180px',
                        maxHeight: '100px',
                        objectFit: 'cover',
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = defaultImage;
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        color: '#021148',
                        fontSize: 16,
                        fontWeight: '500',
                        lineHeight: 1.5,
                      }}
                    >
                      {option?.content}
                    </Typography>
                  )
                }
                sx={{ flexGrow: 1, padding: 2 }}
              />
            </Box>
          ))}
        </RadioGroup>
      ) : (
        <Box display="flex" flexDirection="column">
          {options?.map((option, index) => (
            <Box
              key={option.id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: 0,
                borderRadius: 1,
                border: '1px solid #EAEAEA',
                transition: 'background 0.3s, border 0.3s',
                borderColor: choiceIds?.includes(option.id) ? '#698AFF' : '#EAEAEA',
                background: choiceIds?.includes(option.id)
                  ? 'rgba(105, 138, 255, 0.06)'
                  : 'transparent',
                '&:hover': {
                  background: 'rgba(105, 138, 255, 0.06)',
                  borderColor: '#698AFF',
                },
                mb: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={choiceIds?.includes(option.id)}
                    onChange={() => onOptionChange(option.id)}
                    sx={{
                      color: '#BCBCBC',
                      '&.Mui-checked': {
                        color: '#698AFF',
                      },
                    }}
                  />
                }
                label={
                  option?.type === 'IMAGE' ? (
                    <img
                      src={option?.content}
                      alt={`Option ${index + 1}`}
                      style={{
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '180px',
                        maxHeight: '100px',
                        objectFit: 'cover',
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = defaultImage;
                      }}
                    />
                  ) : (
                    <Typography
                      sx={{
                        color: '#021148',
                        fontSize: 16,
                        fontWeight: '500',
                        lineHeight: 1.5,
                      }}
                    >
                      {option?.content}
                    </Typography>
                  )
                }
                sx={{ flexGrow: 1, padding: 2 }}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}
