import React from 'react';
import '../SuggestedOrganizations.css';
import { handleAlert } from '../../../../utils/handleAlert';
import JoinIconWhite from '../../../../assets/LandingPage/Org/joinOrgWhiteIcon.svg';
import { organizationAPI } from '../../../../api/requests/organization/organizationAPI';
import { Button } from '@mui/material';

export default function RequestToJoin({ disabled, OrganizationID }) {
  const handleJoinRequest = async (OrganizationID) => {
    try {
      await organizationAPI.requestToJoinOrganization(OrganizationID);
      handleAlert('Join request sent successfully', 'success');
    } catch (error) {
      console.error('Error sending join request:', error);
    }
  };
  return (
    <Button
      onClick={() => handleJoinRequest(OrganizationID)}
      disabled={disabled}
      variant='contained'
      sx={{textTransform:'capitalize',whiteSpace: {sm:'nowrap'}, minWidth:{sm:'max-content'}}}
    >
      Request to Join
      <img src={JoinIconWhite} alt="" style={{marginLeft:'8px'}} />
    </Button>
  );
}
