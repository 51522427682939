import performRequest from '../axios';

export const contactSupportAPI = {
  contactSupport: async (body) => {
    return await performRequest('contactSupport', 'POST', body);
  },
  contactSales: async (body) => {
    return await performRequest('contactSales', 'POST', body);
  },
};
