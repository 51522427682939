import React, { useState, useEffect, useRef } from 'react';
import './JoinOrgPopup.css';
import ViewIconWhite from '../../../assets/LandingPage/Org/viewOrgPrimaryIcon.svg';
import { organizationAPI } from '../../../api/requests/organization/organizationAPI';
import RequestToJoin from './Buttons/RequestToJoin';
import ViewOrganizationPopup from './ViewOrganizations';
import { Box, Button, Typography } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { IconButton } from '@mui/material';

export default function JoinOrgPopup({ onClose, code }) {
  const [organization, setOrganization] = useState(null);
  const [selectedOrgName, setSelectedOrgName] = useState('');
  const popupRef = useRef();
  const fetchOrganization = async (code) => {
    try {
      const response = await organizationAPI.searchOrganizationByCode(code);
      setOrganization(response);
    } catch (error) {
      console.error('Error fetching suggested organizations:', error);
    }
  };
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  const openView = (organizationName) => {
    setSelectedOrgName(organizationName);
  };

  useEffect(() => {
    document.body.classList.add('CreateOrgPopup-open');
    window.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.body.classList.remove('CreateOrgPopup-open');
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  useEffect(() => {
    fetchOrganization(code);
  }, [code]);

  if (!organization) {
    return null;
  }

  const renderProfilePhoto = ({ logoLink, name }) => {
    if (logoLink) {
      return <img className="ViewOrg-LogoImg" src={logoLink} alt="" />;
    } else {
      const firstNameInitial = name?.charAt(0) || '';

      return (
        <div className="SuggestedOrginitials-container">
          <span>{firstNameInitial}</span>
        </div>
      );
    }
  };
  return (
    <div className="JoinOrgPopup">
      <div className="JoinOrgPopup-main" ref={popupRef}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: '8px', right: '8px' }}>
          <CloseRoundedIcon />
        </IconButton>
        <div className="JoinOrgClosePopup-content">
          <Typography variant="h5" sx={{ pb: 0, mb: 1 }}>
            Join Organizations:
          </Typography>
          <Typography sx={{ color: 'grey', lineHeight: 1.6 }} variant="subtitle1">
            Explore the featured organizations to be a part of it with groups of people who share a
            team, course or mutual interest by requesting to be a part of it.
          </Typography>
        </div>
        <div>
          <Box height={'250px'} sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
            {organization.map((organization) => (
              <div key={organization.id} className="JoinOrg-orgsRow">
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
                  {renderProfilePhoto({ logoLink: organization.logoLink, name: organization.name })}
                  <div>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: 'vertical',
                      }}
                      variant="body1"
                      color="#2B3746"
                    >
                      {' '}
                      {organization.name}
                    </Typography>
                    <p className="JoinOrg-orgsCreator">
                      {organization?.creator?.username || organization?.creatorData?.username || ''}
                    </p>
                  </div>
                </Box>
                <div className="JoinOrg-orgsBtnDiv">
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{
                      textTransform: 'capitalize',
                      whiteSpace: { sm: 'nowrap' },
                      minWidth: { sm: 'max-content' },
                    }}
                    onClick={() => openView(organization.name)}
                  >
                    View Org
                    <img src={ViewIconWhite} alt="" style={{ marginLeft: '8px' }} />
                  </Button>
                  <RequestToJoin
                    disabled={organization?.isAlreadyRequested}
                    OrganizationID={organization?.id}
                  />
                </div>
              </div>
            ))}
          </Box>
          {selectedOrgName && (
            <ViewOrganizationPopup
              selectedOrgName={selectedOrgName}
              setSelectedOrgName={setSelectedOrgName}
              withoutSidebar={'true'}
            />
          )}
        </div>
      </div>
    </div>
  );
}
