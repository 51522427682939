import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MCQProblemPage from './MCQProblemPage';
import EssayProblemPage from './EssayProblemPage';
import PageNotFound from '../PageNotFound/PageNotFound';
import { candidateAPI } from '../../api/requests/contests/CandidateAPI';
import QuestionNavigator from '../../components/QuestionNavigator';
import { handleAlert } from '../../utils/handleAlert';
import { getOrgName } from '../../utils/appendOrgQuery';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import UiProblem from './UiProblem';
import CodingProblem from './CodingProblem';
import { duration } from 'moment/moment';
import ReusableDialog from '../../components/Dialogbox/ReusableDialog';

const ProblemPage = () => {
  const { contestId } = useParams();
  const [problemId, setProblemId] = useState();
  const [type, setType] = useState();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [problem, setProblem] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showEnterFullscreenDialog, setShowEnterFullscreenDialog] = useState(false);
  const [submitConfirmation, setSubmitConfirmation] = useState(false);
  const orgName = getOrgName();
  const [drawerWidth] = useState(200);
  const [loading, setLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [submissionData, setSubmissionData] = useState(null);
  const [contestDuration, setContestDuration] = useState(0);
  const [remainingTime, setRemainingTime] = useState(0);
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);

  const addOrUpdateSubmissionData = (problemId, newContent, problemType, value = 'default') => {
    setData((prevState) => {
      const updatedProblemData = prevState?.problemData?.map((item) => {
        if (item?.id === problemId) {
          let updatedSubmission = {
            ...item?.submission,
            problemId,
          };

          switch (problemType) {
            case 'ESSAY':
              updatedSubmission.content = newContent;
              break;

            case 'MCQ':
            case 'MAQ':
              updatedSubmission = {
                ...updatedSubmission,
                ...(newContent?.choiceIds?.length > 0
                  ? {
                      problemId: problemId,
                      content: newContent?.choiceIds,
                    }
                  : {
                      problemId: '',
                      content: newContent?.choiceIds || [],
                    }),
              };
              break;

            case 'CODING':
              updatedSubmission = {
                ...updatedSubmission,
                content: newContent,
                fullCode: value,
              };
              break;

            case 'UI':
              updatedSubmission.content = newContent;
              break;

            default:
              console.warn(`Unsupported problem type: ${problemType}`);
              break;
          }

          return {
            ...item,
            submission: updatedSubmission,
          };
        }
        return item;
      });

      return {
        ...prevState,
        problemData: updatedProblemData,
      };
    });
  };

  const problemSubmission = async (contestId, body) => {
    setDataLoading(true);
    try {
      const submissionData = await candidateAPI.problemSubmission(contestId, body);
      setDataLoading(false);
      return submissionData;
    } catch (error) {
      setDataLoading(false);
    } finally {
      setDataLoading(false);
    }
  };
  const submit = async () => {
    try {
      const submissionData = await candidateAPI.endContest(contestId);
      if (submissionData) {
        handleAlert('Submission successful!', 'success');
        exitFullscreen();
        navigate(
          data?.contestData?.showScoreOnSubmission
            ? `/org/${orgName}/contest/${contestId}/result?candidate=true`
            : `/org/${orgName}/contest`
        );
      }
    } catch (error) {
      handleAlert('Submission failed. Please try again.', 'error');
    }
  };

  const submitProblem = (contestId, body, type) => {
    problemSubmission(contestId, body)
      .then(() => {
        addOrUpdateSubmissionData(body.problemId, body.content, type);
        setSubmitConfirmation(true);
      })
      .catch((error) => handleAlert('Submission failed. Please try again.', 'error'));
  };

  const currentQuestionIndex = questions?.length
    ? questions.findIndex((problem) => problem.id === problemId && problem.type === type)
    : -1;
  const currentQuestion = questions?.find(
    (problem) => problem.id === problemId && problem.type === type
  );
  const nextQuestion =
    currentQuestionIndex !== -1 && currentQuestionIndex < questions.length - 1
      ? questions[currentQuestionIndex + 1]
      : null;
  const prevQuestion = currentQuestionIndex > 0 ? questions[currentQuestionIndex - 1] : null;

  const handleNavigationNextQuestion = (contestId, body, type, value) => {
    if (nextQuestion) {
      setProblemId(nextQuestion.id);
      setType(nextQuestion.type);
      setProblem(nextQuestion);
      problemSubmission(contestId, body)
        .then((res) => {
          if (type === 'CODING') {
            body.content = {
              ...body.content,
              results: res?.testCases?.results,
              overallTimeTaken: res?.testCases?.overallTimeTaken,
            };
          } else if (type === 'UI') {
            body.content = {
              ...body.content,
              message: res?.message,
            };
          }
          addOrUpdateSubmissionData(body.problemId, body.content, type, value);
        })
        .catch((error) => {});
    }
  };

  const handleNavigationPrevQuestion = (contestId, body, type, value) => {
    if (prevQuestion) {
      setProblemId(prevQuestion.id);
      setType(prevQuestion.type);
      setProblem(prevQuestion);
      problemSubmission(contestId, body)
        .then((res) => {
          if (type === 'CODING') {
            body.content = {
              ...body.content,
              results: res?.testCases?.results,
              overallTimeTaken: res?.testCases?.overallTimeTaken,
            };
          } else if (type === 'UI') {
            body.content = {
              ...body.content,
              message: res?.message,
            };
          }
          addOrUpdateSubmissionData(body.problemId, body.content, type, value);
        })
        .catch((error) => {});
    }
  };

  const handleNavigate = (questionId) => {
    const targetQuestion = data?.problemData?.find((q) => q?.id === questionId);

    if (targetQuestion) {
      setProblemId(targetQuestion?.id);
      setType(targetQuestion?.type);
      setProblem(targetQuestion);
      let cleanSubmissionData = { ...submissionData };
      if (targetQuestion?.type === 'UI') {
        const { message, ...cleanContent } = submissionData?.content || {};
        cleanSubmissionData = { ...submissionData, content: cleanContent };
      }
      problemSubmission(contestId, cleanSubmissionData)
        .then((res) => {
          if (type === 'CODING') {
            setSubmissionData((prevData) => ({
              ...prevData,
              content: {
                ...prevData.content,
                results: res?.testCases?.results,
                overallTimeTaken: res?.testCases?.overallTimeTaken,
              },
            }));
            addOrUpdateSubmissionData(
              submissionData?.problemId,
              {
                ...submissionData?.content,
                results: res?.testCases?.results,
                overallTimeTaken: res?.testCases?.overallTimeTaken,
              },
              currentQuestion?.type,
              submissionData?.fullCode
            );
          } else if (type === 'UI') {
            setSubmissionData((prevData) => ({
              ...prevData,
              content: {
                ...prevData.content,
                message: res?.message,
              },
            }));

            addOrUpdateSubmissionData(
              submissionData?.problemId,
              {
                ...submissionData?.content,
                message: res?.message,
              },
              currentQuestion?.type,
              submissionData?.content
            );
          } else {
            addOrUpdateSubmissionData(
              submissionData?.problemId,
              submissionData?.content,
              currentQuestion?.type,
              submissionData?.fullCode
            );
          }
        })
        .catch((error) => {
          setDataLoading(false);
        });
    }
  };

  const tabSwitchCountIncrease = async () => {
    try {
      const increase = await candidateAPI.tabSwitchCount(contestId);
    } catch (error) {}
  };

  const getContestById = async () => {
    try {
      const data = await candidateAPI.getContestById(contestId);
      const updatedProblemData = data?.problemData?.map((item) => ({
        ...item,
        submission: item?.submission?.content
          ? { ...item.submission, content: JSON.parse(item.submission.content) }
          : item.submission,
      }));
      setContestDuration(data?.timeLeft ?? data?.contestData?.duration * 60);
      setData({ ...data, problemData: updatedProblemData });
      setQuestions(updatedProblemData);
      if (updatedProblemData?.length > 0) {
        const initialProblem = updatedProblemData[0];
        setProblemId(initialProblem?.id);
        setType(initialProblem?.type);
        setProblem(initialProblem);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getContestById();
  }, []);
  useEffect(() => {
    setLoading(true);
    if (data && problemId) {
      const currentProblem = data?.problemData?.find((item) => item.id === problemId);
      setQuestions(data?.problemData);
      if (currentProblem) {
        setProblem(currentProblem);
        setProblemId(currentProblem?.id);
        setType(currentProblem?.type);
      } else {
        setProblem(null);
      }
    }
    setLoading(false);

    return () => {
      setProblem(null);
    };
  }, [data, problemId]);

  useEffect(() => {
    if (contestDuration) {
      const startTime = Math.floor(Date.now() / 1000);

      const calculateRemainingTime = () => {
        const currentTime = Math.floor(Date.now() / 1000);
        const elapsedTime = currentTime - startTime;
        const remaining = Math.max(contestDuration - elapsedTime, 0);

        setRemainingTime(remaining);
        if (remaining === 0 && !apiCalled) {
          setIsTimeUp(true);
          clearInterval(interval);
        }
      };

      calculateRemainingTime();

      const interval = setInterval(calculateRemainingTime, 1000);

      return () => clearInterval(interval);
    }
  }, [contestDuration]);

  useEffect(() => {
    if (isTimeUp && submissionData) {
      callTimesUpAPIs();
    }
  }, [isTimeUp, submissionData]);

  const formatRemainingTime = (seconds) => {
    if (seconds <= 0) return 'Contest has ended';

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hours}h:${minutes}m:${secs}s`;
  };

  const callTimesUpAPIs = async () => {
    try {
      await submit();
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      setSubmissionData(null);
    };
  }, [problemId]);

  const enterFullscreen = () => {
    setIsFullscreen(true);
    setShowEnterFullscreenDialog(false);
    const docElm = document.documentElement;
    if (docElm.requestFullscreen) docElm.requestFullscreen();
    else if (docElm.mozRequestFullScreen) docElm.mozRequestFullScreen();
    else if (docElm.webkitRequestFullscreen) docElm.webkitRequestFullscreen();
    else if (docElm.msRequestFullscreen) docElm.msRequestFullscreen();
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) document?.exitFullscreen();
    else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
    else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
    else if (document.msExitFullscreen) document.msExitFullscreen();

    setIsFullscreen(false);
  };

  const handleFullscreenChange = () => {
    const isNowFullscreen = !!(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );

    setIsFullscreen(isNowFullscreen);

    if (!isNowFullscreen) {
      setShowEnterFullscreenDialog(true);
    }
  };

  useEffect(() => {
    const isNowFullscreen = !!(
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    );

    setIsFullscreen(isNowFullscreen);
    if (!isNowFullscreen) {
      setShowEnterFullscreenDialog(true);
    } else {
      setShowEnterFullscreenDialog(false);
    }
  }, [isFullscreen]);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);

    if (!document.fullscreenElement) {
      setShowEnterFullscreenDialog(true);
    }

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('msfullscreenchange', handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    let isKeyHeld = false;

    const handleKeyDown = (e) => {
      if (!isFullscreen && (e.key === 'Escape' || e.key === 'F11')) {
        if (!isKeyHeld) {
          isKeyHeld = true;
          setShowEnterFullscreenDialog(true);
        }
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Escape' || e.key === 'F11') {
        isKeyHeld = false;
        setShowEnterFullscreenDialog(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isFullscreen]);

  useEffect(() => {
    const handlePopState = () => {
      window.history.pushState(null, null, window.location.href);
    };
    window.history.pushState(null, null, window.location.href);
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = '';
      setShowEnterFullscreenDialog(true);
      return '';
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        let tabSwitchCount = parseInt(localStorage.getItem('tabSwitchCount') || '0', 10);
        tabSwitchCount += 1;
        localStorage.setItem('tabSwitchCount', tabSwitchCount);
        tabSwitchCountIncrease();
      }
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    enterFullscreen();

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [problemId]);

  const renderProblemPage = () => {
    if (!problem) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100dvh',
            p: 3,
          }}
        >
          <CircularProgress size="30px" />
        </Box>
      );
    }
    const commonProps = {
      contestId,
      problemId,
      problem,
      questions,
      currentQuestionIndex,
      prevQuestion,
      nextQuestion,
      handleNavigationPrevQuestion,
      handleNavigationNextQuestion,
      submit,
      problemId,
      type,
      submitProblem,
      setSubmissionData,
      formatRemainingTime,
      remainingTime,
    };
    switch (type) {
      case 'MCQ':
        return <MCQProblemPage {...commonProps} setData={setData} />;

      case 'MAQ':
        return <MCQProblemPage {...commonProps} setData={setData} />;
      case 'ESSAY':
        return <EssayProblemPage {...commonProps} />;
      case 'CODING':
        return (
          <CodingProblem {...commonProps} addOrUpdateSubmissionData={addOrUpdateSubmissionData} />
        );
      case 'UI':
        return (
          <UiProblem
            {...commonProps}
            data={data}
            addOrUpdateSubmissionData={addOrUpdateSubmissionData}
          />
        );
      default:
        return <PageNotFound />;
    }
  };

  const submitConfirmationClose = () => {
    setSubmitConfirmation(false);
  };

  const handleConfirmDialog = () => {
    setIsTimeUp(false);
  };

  return (
    <>
      {problem && ['MCQ', 'MAQ', 'ESSAY', 'CODING', 'UI'].includes(type) && (
        <QuestionNavigator
          questions={questions || []}
          contestId={contestId}
          drawerWidth={drawerWidth}
          handleNavigate={handleNavigate}
          problemId={problemId}
        />
      )}

      <Box
        sx={{
          ml:
            problem && ['MCQ', 'MAQ', 'ESSAY', 'CODING', 'UI'].includes(type)
              ? { xs: '0px', md: `${drawerWidth}px` }
              : '0px',
          bgcolor: '#F3F5FC',
        }}
      >
        {renderProblemPage()}
      </Box>
      {dataLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            zIndex: '10',
          }}
        >
          <CircularProgress
            size="30px"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
            }}
          />
        </Box>
      )}

      <Dialog open={showEnterFullscreenDialog} onClose={enterFullscreen}>
        <DialogTitle>Fullscreen Required</DialogTitle>
        <DialogContent>
          <Typography>This test requires to be attempted in full screen mode only.</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={enterFullscreen} color="primary">
            Enter Fullscreen
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={submitConfirmation} onClose={submitConfirmationClose}>
        <DialogTitle>Do you want to submit the contest</DialogTitle>
        <DialogContent>
          <Typography>
            Have you completed the contest if not, we recommend to submit all problems first
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outline" onClick={submitConfirmationClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <ReusableDialog
        open={isTimeUp}
        title="Time's Up!"
        confirmText="Exit"
        onConfirm={handleConfirmDialog}
      >
        The contest has ended. Your answers will now be submitted.
      </ReusableDialog>
    </>
  );
};

export default ProblemPage;
