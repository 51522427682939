import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';

const SampleVideoLinks = ({ sampleVideo }) => {
  const handleButtonClick = (url) => {
    window.open(url, '_blank');
  };
  return (
    <Grid item xs={12} sx={{ px: '0px' }}>
      <Typography
        variant="body1"
        sx={{
          fontWeight: '500',
          mb: 1,
        }}
      >
        Sample Video Links
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        alignItems="center"
        justifyContent="flex-start"
        sx={{flexWrap:'wrap'}}
      >
        {sampleVideo?.map((video, index) => (
          <Button
            key={index}
            variant="outlined"
            onClick={() => handleButtonClick(video)}
            sx={{
              width: 'fit-content',
              textTransform: 'none',
            }}
          >
            Video {index + 1}
          </Button>
        ))}
      </Box>
    </Grid>
  );
};

export default SampleVideoLinks;
