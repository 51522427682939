import { Grid, Typography, Box, Button, MenuItem, Menu } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import SolveExplanation from '../../components/CodingContests/ContestProblem/ProblemSolve/SolveExplanation';
import { contestProblemAPI } from '../../api/requests/contests/contestProblemAPI';
import TestCase from '../../components/CodingContests/ContestProblem/ProblemSolve/TestCase';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Editor } from '@monaco-editor/react';
import { candidateAPI } from '../../api/requests/contests/CandidateAPI';
import { constrainedEditor } from 'constrained-editor-plugin';

const CodingProblem = ({
  problem,
  problemId,
  prevQuestion,
  nextQuestion,
  handleNavigationPrevQuestion,
  handleNavigationNextQuestion,
  submit,
  submitProblem,
  contestId,
  type,
  setSubmissionData,
  formatRemainingTime,
  remainingTime,
  addOrUpdateSubmissionData,
}) => {
  const tagsRef = useRef(null);
  const hintsRef = useRef(null);
  const [testCases, setTestCases] = useState([]);
  const [submitionResponse, setSubmitionResponse] = useState('');
  const [totalScore, setTotalScore] = useState(null);
  const [overallTimeTaken, setOverallTimeTaken] = useState(null);
  const formattedLanguages = problem?.data?.languages;
  const languages = formattedLanguages?.map((lang) => lang?.toLowerCase());
  const [value, setValue] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [startLine, setStartLine] = useState(0);
  const [endLine, setEndLine] = useState(0);
  const [userCode, setUserCode] = useState('');
  const monacoRef = useRef(null);
  const constrainedInstanceRef = useRef(null);

  const parsedData = JSON.parse(problem?.data?.boilerPlateCode);
  const CODE_SNIPPETS = {
    java: parsedData?.JAVA?.topHalf + parsedData?.JAVA?.bottomHalf || '',
    python: parsedData?.PYTHON?.topHalf + parsedData?.PYTHON?.bottomHalf || '',
    cpp: parsedData?.CPP?.topHalf + parsedData?.CPP?.bottomHalf || '',
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (language) => {
    setSelectedLanguage(language);
    const editor = monacoRef.current;
    const model = editor.getModel();

    model.setValue(CODE_SNIPPETS[language]);
    setValue(CODE_SNIPPETS[language]);

    model.disposeRestrictions();

    applyRestrictions(editor);

    handleClose();
  };

  const submitProblemAnswer = async (problemAnswer, selectedLanguage) => {
    const dataforpost = {
      problemId: problemId,
      content: { code: userCode, language: selectedLanguage.toUpperCase() },
    };
    try {
      const res = await candidateAPI.problemSubmission(contestId, dataforpost);
      addOrUpdateSubmissionData(dataforpost.problemId, dataforpost.content, type, value);
      setSubmitionResponse(res);
      if (res) {
        setTestCases(res?.testCases?.results || []);
      }
      setOverallTimeTaken(res?.testCases?.overallTimeTaken || 0);
      setTotalScore(res?.results.score || 0);
    } catch (err) {}
  };

  useEffect(() => {
    setValue(CODE_SNIPPETS[selectedLanguage]);
  }, [problem, selectedLanguage]);

  useEffect(() => {
    setSubmissionData({
      problemId: problemId,
      content: { code: userCode, language: selectedLanguage.toUpperCase() },
      fullCode: value,
    });
  }, [problem, userCode]);

  useEffect(() => {
    if (problem && problemId) {
      if (problem?.submission?.fullCode) {
        setValue(problem?.submission?.fullCode);
        setSelectedLanguage(problem?.submission?.content?.language.toLowerCase());
      } else if (problem?.submission?.content) {
        setSelectedLanguage(problem?.submission?.content?.language.toLowerCase());
        setValue(CODE_SNIPPETS[problem?.submission?.content?.language.toLowerCase()]);
        if (problem?.submission?.content?.testCases) {
          const parsedTestCases = JSON.parse(problem?.submission?.content.testCases);
          setTestCases(parsedTestCases?.results || '');
          setOverallTimeTaken(parsedTestCases?.overallTimeTaken || 0);
        }
      } else if (problem?.submission?.content?.results) {
        setTestCases(problem?.submission?.content?.results || '');
        setOverallTimeTaken(problem?.submission?.content?.overallTimeTaken || 0);
      } else {
        setTestCases(problem?.data?.testCases);
      }
    }
    return () => {
      if (!problemId) {
        setTestCases([]);
      }
    };
  }, [problem, problemId]);

  const handleEditorChange = (value) => {
    setValue(value);
    const editor = monacoRef.current;

    const userCode = extractRestrictedCode(editor);
    setUserCode(userCode);

    setSubmissionData({
      problemId: problemId,
      content: { code: userCode, language: selectedLanguage.toUpperCase() },
    });
  };

  const extractRestrictedCode = (editor) => {
    const model = editor.getModel();
    if (!model) return '';

    if (!startLine || !endLine) {
      console.error('Failed to find restricted line range.');
      return '';
    }

    const lines = model.getLinesContent();
    const restrictedLines = lines.slice(startLine - 1);
    let lastLine = startLine;
    if (selectedLanguage === 'java' || selectedLanguage === 'cpp') {
      for (let i = 0; i < restrictedLines.length; i++) {
        if (restrictedLines[i].trim() === '}') {
          lastLine = startLine + i;
          break;
        }
      }
    } else if (selectedLanguage === 'python') {
      const indentation = startLine;
      for (let i = 0; i < restrictedLines.length; i++) {
        const lineIndentation = startLine;
        if (lineIndentation < indentation && restrictedLines[i].trim() !== '') {
          lastLine = startLine + i;
          break;
        }
        if (restrictedLines[i].includes('if __name__')) {
          lastLine = startLine + i;
          break;
        }
      }
    }
    const extractLines = lines.slice(startLine - 1, lastLine - 1);
    const result = extractLines.join('\n');

    return extractLines.join('\n');
  };

  const executeCodeCompilation = async (userCode, selectedLanguage) => {
    const postData = {
      code: userCode,
      language: selectedLanguage.toUpperCase(),
    };
    try {
      const res = await contestProblemAPI.runCode(problemId, postData);
      setTestCases(res?.results?.testCases || []);
      setOverallTimeTaken(res?.results?.overallTimeTaken || 0);
    } catch (err) {}
  };

  const scrollToTags = () => {
    if (tagsRef.current) {
      tagsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  const scrollToHints = () => {
    if (hintsRef.current) {
      hintsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  let restrictions = [];

  const onMount = (editor, monaco) => {
    monacoRef.current = editor;
    editor.focus();

    const constrainedInstance = constrainedEditor(monaco);
    constrainedInstance.initializeIn(editor);

    const model = editor.getModel();
    const lines = model.getLinesContent();
    let startl = 0;
    let endline = 0;
    let { startLine, endLine } = findFunctionLogicLineRange(editor) || { startLine: 0, endLine: 0 };

    setStartLine(startLine);
    setEndLine(endLine);
    restrictions = [];

    restrictions.push({
      range: [startLine, 1, endLine, 1],
      allowMultiline: true,
    });
    constrainedInstance.addRestrictionsTo(model, restrictions);
    constrainedInstanceRef.current = constrainedInstance;
  };

  const handleUnmount = () => {
    if (constrainedInstanceRef.current) {
      constrainedInstanceRef.current.dispose();
    }
  };

  const findFunctionLogicLineRange = (editor) => {
    const model = editor.getModel();
    if (!model) {
      return;
    }
    const lines = model.getLinesContent();
    const searchText = 'Write your function logic here';

    let startLine = -1;
    for (let i = 0; i < lines.length; i++) {
      if (lines[i].includes(searchText)) {
        startLine = i + 1;
        break;
      }
    }
    // if (startLine === -1) {
    //   console.error(`Text "${searchText}" not found in the editor.`);
    //   return null;
    // }
    const range = {
      startLine: startLine,
      endLine: startLine + 1,
    };
    return range;
  };

  const applyRestrictions = (editor) => {
    if (!constrainedInstanceRef.current) {
      return;
    }

    const model = editor.getModel();
    const { startLine, endLine } = findFunctionLogicLineRange(editor);
    setStartLine(startLine);
    setEndLine(endLine);
    if (!startLine || !endLine) {
      return;
    }

    restrictions = [];

    restrictions.push({
      range: [startLine, 1, endLine, 1],
      allowMultiline: true,
    });

    constrainedInstanceRef.current.addRestrictionsTo(model, restrictions);
  };

  const dataforpost = {
    problemId: problemId,
    content: { code: userCode, language: selectedLanguage.toUpperCase() },
  };
  return (
    <Grid
      container
      sx={{
        p: { xs: 2, md: 3, lg: 6 },
        m: 0,
        bgcolor: '#F3F5FC',
        display: 'flex',
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        gap: { xs: 2 },
        height: { xs: 'auto', md: '100dvh' },
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          bgcolor: '#fff',
          borderRadius: '8px',
          overflowY: 'auto',
          border: '1px solid #eee',
          borderRight: { md: '0px' },
          position: 'relative',
        }}
      >
        <Grid
          item
          xs={12}
          mt={{ xs: 0 }}
          sx={{
            px: 2,
            py: 0.5,
            bgcolor: '#F3F5FC',
            borderBottom: '1px solid #eee',
            position: 'sticky',
            top: 0,
            zIndex: 2,
            display: 'flex',
            alignItems: 'center',
            minHeight: '42px!important',
            justifyContent: 'space-between',
          }}
          mb={2}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: '500',
              mb: 0,
              fontSize: { xs: '12px', sm: '13px', mds: '14px' },
            }}
          >
            Problem Details
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontWeight: '500',
              mb: 0,
              fontSize: { xs: '12px', sm: '13px', md: '13px', mds: '14px' },
            }}
          >
            Time Remaining: {formatRemainingTime(remainingTime)}
          </Typography>
        </Grid>
        <SolveExplanation
          scrollToTags={scrollToTags}
          scrollToHints={scrollToHints}
          hintsRef={hintsRef && hintsRef}
          tagsRef={tagsRef && tagsRef}
          problem={problem}
          codingProblemData={problem.data}
        />
      </Grid>

      <Grid container item xs={12} md={6}>
        <Grid
          item
          xs={12}
          sx={{
            p: 0,
            bgcolor: '#fff',
            borderRadius: '8px',
            mb: 2,
            border: '1px solid #eee',
            position: 'relative',
          }}
        >
          <Grid
            item
            xs={12}
            mt={{ xs: 0 }}
            sx={{
              px: 2,
              pl: { xs: 1, sm: 2, md: 1, mds: 2 },
              py: 0.5,
              bgcolor: '#F3F5FC',
              minHeight: '42px!important',
              borderBottom: '1px solid #eee',
              position: 'sticky',
              top: 0,
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            mb={2}
          >
            <Box>
              <Button
                onClick={handleClick}
                size="small"
                endIcon={<KeyboardArrowDownRoundedIcon />}
                sx={{
                  fontSize: { xs: '11px', sm: '13px' },
                  backgroundColor: 'transparent',
                  textTransform: 'uppercase',
                  color: '#021148',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                {selectedLanguage}
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    bgcolor: 'white',
                    color: '#021148',
                    textTransform: 'uppercase',
                  },
                }}
              >
                {languages?.map((language, index) => (
                  <MenuItem
                    key={index}
                    sx={{ bgcolor: selectedLanguage === language ? 'rgba(0, 0, 0, 0.04)' : '' }}
                    onClick={() => handleMenuItemClick(language)}
                  >
                    {language}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: { xs: 1, sm: 2, md: 1, mds: 2 },
              }}
            >
              <Button
                variant="outlined"
                onClick={() => handleNavigationPrevQuestion(contestId, dataforpost, type, value)}
                size="small"
                sx={{ fontSize: { xs: '11px', sm: '13px' }, px: { xs: '4px', sm: '9px' } }}
                disabled={!prevQuestion}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                onClick={() => handleNavigationNextQuestion(contestId, dataforpost, type, value)}
                size="small"
                disabled={!nextQuestion}
                sx={{ fontSize: { xs: '11px', sm: '13px' }, px: { xs: '4px', sm: '9px' } }}
              >
                Next
              </Button>
              <Button
                variant="contained"
                onClick={() => submitProblem(contestId, dataforpost, type)}
                size="small"
                sx={{ fontSize: { xs: '11px', sm: '13px' }, px: { xs: '4px', sm: '9px' } }}
              >
                Submit
              </Button>
            </Box>
          </Grid>
          <Box sx={{ p: 2 }}>
            <Editor
              height="42dvh"
              value={value}
              onChange={handleEditorChange}
              onMount={onMount}
              defaultLanguage={selectedLanguage}
              onUnmount={handleUnmount}
              defaultValue="// please write your code here"
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            p: 0,
            bgcolor: '#fff',
            borderRadius: '8px',
            height: { xs: 'auto', md: '35dvh' },
            overflowY: 'auto',
            border: '1px solid #eee',
            position: 'relative',
            scrollbarWidth: 'thin',
            scrollbarColor: '#ccc transparent',
            '&::-webkit-scrollbar': {
              width: '3px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#ccc',
              borderRadius: '8px',
            },
          }}
        >
          <Grid
            item
            xs={12}
            mt={{ xs: 0 }}
            sx={{
              px: 2,
              py: 0.5,
              bgcolor: '#F3F5FC',
              minHeight: '42px!important',
              borderBottom: '1px solid #eee',
              position: 'sticky',
              top: 0,
              zIndex: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            mb={2}
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Button
                onClick={() => executeCodeCompilation(userCode, selectedLanguage.toUpperCase())}
                size="small"
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#fff',
                  color: '#021148',
                  fontSize: { xs: '11px', sm: '13px' },
                  px: { xs: '4px', sm: '9px' },
                  '&:hover': {
                    backgroundColor: '#e6e9f5',
                  },
                }}
              >
                Run
              </Button>
              <Button
                onClick={() => submitProblemAnswer(userCode, selectedLanguage)}
                size="small"
                sx={{
                  textTransform: 'none',
                  backgroundColor: '#00d23010',
                  color: '#28A745',
                  fontSize: { xs: '11px', sm: '13px' },
                  px: { xs: '6px', sm: '9px' },
                  '&:hover': {
                    backgroundColor: '#00d23020',
                  },
                }}
              >
                Submit Code
              </Button>
            </Box>
          </Grid>
          <TestCase
            problem={problem?.data}
            submitionResponse={submitionResponse}
            totalScore={totalScore}
            overallTimeTaken={overallTimeTaken}
            testCases={testCases}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CodingProblem;
