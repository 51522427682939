import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@mui/material/Button';
import { organizationAPI } from '../../api/requests/organization/organizationAPI';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Skeletons from '../Skeleton/Skeletons';
import { useNavigate } from 'react-router-dom';
import TaskIcon from '@mui/icons-material/Task';
import ImportContactsOutlinedIcon from '@mui/icons-material/ImportContactsOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { Avatar, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { handleAlert } from '../../utils/handleAlert';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1, px: 3, pb: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


const Notifications_SideBar = ({ open, anchorEl, placement, handleClose, setSelectedOrgName }) => {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [activeTab, setActiveTab] = useState(0);

  const boxWidth = isDesktop ? 400 : 310;
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const fetchNotifications = async () => {
    try {
      setLoadingNotifications(true);
      const response = await organizationAPI.getNotifications();
      setNotificationsList(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoadingNotifications(false);
    }
  };

  const [loadingInvites, setLoadingInvites] = useState(false);
  const [invitesRecieved, setInvitesReceived] = useState([]);
  const fetchInvitesReceivedByOrganization = async () => {
    try {
      setLoadingInvites(true);
      const responseInvitesReceived = await organizationAPI.getInvitesRecievedByUser();
      setInvitesReceived(responseInvitesReceived);
    } catch (error) {
      console.error('Error fetching suggested organizations:', error);
    } finally {
      setLoadingInvites(false);
    }
  };

  const MarkAsRead = async () => {
    try {
      await organizationAPI.markNotificationsAsRead();
    } catch (error) {
      console.error('Error marking as read:', error);
    }
  };
  const fetchData = async () => {
    try {
      if (activeTab === 0) {
        await fetchNotifications();
      } else if (activeTab === 1) {
        await fetchInvitesReceivedByOrganization();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [activeTab]);

  const handleClickAway = () => {
    handleClose();
  };

  const navigate = useNavigate();

  const handleNotificationClick = (notification) => {
    switch (notification.type) {
      case 'TASK':
        navigate(`/org/${notification.name}/task/${notification.serviceId}`);
        break;
      case 'CONTEST':
        navigate(`/org/${notification.name}/contest/${notification.serviceId}`);
        break;
      case 'COURSE':
        navigate(`/org/${notification.name}/course/videos/${notification.serviceId}`);
        break;
      default:
        break;
    }
    handleClose();
  };
  const openView = (organizationName) => {
    setSelectedOrgName(organizationName);
  };

  const rejectInvite = (id) => {
    organizationAPI
      .inviteAction(id, 'REJECT')
      .then(() => {
        handleAlert('Invite rejected successfully', 'success');
        fetchInvitesReceivedByOrganization();
      })
      .catch((error) => {
        handleAlert('Error rejecting invite', 'error');
      });
  };

  const acceptInvite = (id) => {
    organizationAPI
      .inviteAction(id, 'ACCEPT')
      .then(() => {
        handleAlert('Invite accepted successfully', 'success');
        fetchInvitesReceivedByOrganization();
      })
      .catch((error) => {
        handleAlert('Error accepting invite', 'error');
      });
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box
          sx={{
            width: boxWidth,
          }}
        >
          <Box
            sx={{
              py: 2,
              pl: 2,
              pr: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderBottom: '1px solid #0000001f',
            }}
          >
            <Typography variant="h6">Notifications</Typography>
            <Box>
              {notificationsList.length > 0 && value === 0 && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ mr: { sm: 1.5 } }}
                  onClick={async () => {
                    await MarkAsRead();
                    handleClose();
                  }}
                >
                  Mark as read
                </Button>
              )}
              <IconButton onClick={handleClose} sx={{ p: 0.5 }}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <AppBar
            position="static"
            sx={{
              background: 'transparent',
              boxShadow: 'none',
            }}
          >
            <Tabs value={value} onChange={handleChange} variant="fullWidth" sx={{ width: '100%' }}>
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label="Notification"
              />
              <Tab
                sx={{
                  textTransform: 'capitalize',
                }}
                label="Invites received"
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
            sx={{ padding: '0px' }}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              {loadingNotifications ? (
                <Skeletons type="smallCircularLoader" />
              ) : (
                <Box
                  sx={{
                    overflowY: 'auto',
                  }}
                >
                  {notificationsList.map((notification) => {
                    let iconComponent;

                    switch (notification.type) {
                      case 'TASK':
                        iconComponent = <TaskIcon fontSize="small" />;
                        break;
                      case 'CONTEST':
                        iconComponent = <EmojiEventsIcon fontSize="small" />;
                        break;
                      case 'COURSE':
                        iconComponent = <ImportContactsOutlinedIcon fontSize="small" />;
                        break;
                      default:
                        iconComponent = <NotificationsIcon fontSize="small" />;
                        break;
                    }

                    return (
                      <Box
                        key={notification.id}
                        sx={{
                          padding: '8px 10px',
                          width: '100%',
                          color: '#2B3746',
                          fontFamily: 'Poppins',
                          fontSize: '14px',
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          cursor: 'pointer',
                          borderBottom: '1px solid #e2e2e2',
                        }}
                        onClick={() => handleNotificationClick(notification)}
                      >
                        {iconComponent}
                        {notification.message}.
                      </Box>
                    );
                  })}
                </Box>
              )}
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction} sx={{ paddingTop: '0px' }}>
              {loadingInvites ? (
                <Skeletons type="smallCircularLoader" />
              ) : (
                <>
                  {invitesRecieved?.map((member) => (
                    <Box
                      display={'flex'}
                      width={'100%'}
                      justifyContent={'space-between'}
                      flexDirection={'column'}
                      alignItems={'center'}
                      sx={{ py: 1.5, borderBottom: '1px solid rgb(226, 226, 226)', rowGap: 1 }}
                    >
                      <Box
                        display={'flex'}
                        gap={1}
                        alignItems={'flex-start'}
                        justifyContent={'space-between'}
                        width={'-webkit-fill-available'}
                      >
                        <Box display={'flex'} gap={1} alignItems={'flex-start'}>
                          <Avatar
                            src={member?.organization?.logoLink}
                            alt={member?.organization?.name}
                            style={{
                              backgroundColor: member?.organization?.logoLink
                                ? 'transparent'
                                : '#354150',
                              color: member?.organization?.logoLink ? 'black' : 'white',
                              width: '30px',
                              height: '30px',
                              fontSize: '19px',
                            }}
                          >
                            {member?.organization?.name?.charAt(0)?.toUpperCase()}
                          </Avatar>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: '500' }}
                          >{`${member?.organization?.name}`}</Typography>
                        </Box>
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{ minWidth: 'fit-content', textTransform: 'capitalize' }}
                          onClick={() => openView(member?.organization?.name)}
                        >
                          View Org
                        </Button>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          width: '-webkit-fill-available',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          size="small"
                          variant="text"
                          sx={{ minWidth: 'fit-content', textTransform: 'capitalize' }}
                          onClick={() => rejectInvite(member?.id)}
                        >
                          Reject
                        </Button>
                        <Button
                          size="small"
                          variant="contained"
                          sx={{ minWidth: 'fit-content', textTransform: 'capitalize' }}
                          onClick={() => acceptInvite(member?.id)}
                        >
                          Accept
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </>
              )}
            </TabPanel>
          </SwipeableViews>
        </Box>
      </Drawer>
    </>
  );
};

export default Notifications_SideBar;
