import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  useTheme,
  useMediaQuery,
  Typography,
  TextField,
  Button,
  IconButton,
  Select,
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import '../chat.css';
import GroupDetails from './GroupDetails';
import { CommonColors } from '../../../../utils/colors';
import GetValidatedTokenData from '../../../../utils/helper';
import { ChatApi } from '../../../../api/requests/chat/ChatApi';
import { getOrgName } from '../../../../utils/appendOrgQuery';
import { handleAlert } from '../../../../utils/handleAlert';
import ChatMessagePanel from '../../../MessageBox/ChatMessagePanel';
import ManualAvatar from '../../../Avatar/Avatar';
import EmojiPicker from 'emoji-picker-react';
import ChatInputField from '../../../InputField/ChatInputField';
import Skeletons from '../../../Skeleton/Skeletons';
import ConfirmationDialog from '../../../Dialogbox/ConfirmationDialog';
import { useNavigate, useParams } from 'react-router-dom';
import BigLoader from '../../../Skeleton/BigLoader';
import SearchBar from '../SearchBar';
import ChatMessageLoader from '../../../Skeleton/ChatMessageLoader';
import GroupChatDesktop from '../../../../assets/ChatImages/GroupChatDesktop.webp';
import SuggestedFeatures from '../SuggestedFeatures';
import addNotification from 'react-push-notification';
import MessageActions from '../../../MessageActions';
import CancelIcon from '@mui/icons-material/Cancel';
import ChatReactionBox from '../../../MessageBox/ChatReactionBox';
import SocketClient from '../../../../socket';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function GroupChatMobile({ setGroupList, groupList, setSelectedChat }) {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const getSocket = async () => {
      setSocket(await SocketClient.getSocket());
    };
    getSocket();
  }, []);

  const bgColor = CommonColors.primaryBgColor;
  const [messages, setMessages] = useState([]);
  const [confirmationAction, setConfirmationAction] = useState('');
  const [groupDetails, setGroupDetails] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [newMessage, setNewMessage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const openMenu = Boolean(anchorEl);
  const [isLoader, setIsLoader] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOlder, setIsLoadingOlder] = useState(false);
  const [editingMessageId, setEditingMessageId] = useState(null);
  const [editingMessageText, setEditingMessageText] = useState('');
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [showReactEmoji, setShowReactEmoji] = useState(true);
  const [replyTo, setReplyTo] = useState(null);
  const [reactionsData, setReactionsData] = useState([]);
  const inputRef = useRef(null);

  const addMessage = (newMessages) => {
    setMessages((prevMessages) => {
      const messagesArray = Array.isArray(newMessages) ? newMessages : [newMessages];
      const messageMap = new Map(prevMessages.map((msg) => [msg.id, msg]));
      messagesArray.forEach((msg) => messageMap.set(msg.id, msg));
      const updatedMessagesArray = Array.from(messageMap.values());
      const sortedMessages = updatedMessagesArray.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );
      return sortedMessages;
    });
  };

  const handleScroll = () => {
    const container = messagesContainerRef.current;
    if (container) {
      const atBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
      setShowScrollButton(!atBottom);
    }
  };

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleReplyMessage = (message) => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    setReplyTo(message); // Store the entire message being replied to
    scrollToBottom();
  };

  const handleCancelReply = () => {
    setReplyTo(null); // Clear the replyTo state
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      const response = await ChatApi.handleDeleteChatMessage(messageId);
      if (response.message === 'Message Deleted Successfully') {
        setMessages(messages.filter((message) => message.id !== messageId));
      }
    } catch (error) {
      handleAlert(error.message, 'error');
    } finally {
    }
  };
  // editMessage
  const handleEditMessage = async (message) => {
    setEditingMessageId(message.id);
    setEditingMessageText(message.message);
  };
  const handleEditMessageSubmit = async (messageId) => {
    try {
      const id = messageId;
      const response = await ChatApi.editMessage(id, editingMessageText);
      addMessage(
        messages.map((message) =>
          message.id === id ? { ...message, message: response?.messageData[0]?.message } : message
        )
      );
      handleCancelEdit();
    } catch (error) {
      handleAlert(error.message, 'error');
    } finally {
      // setIsLoader(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingMessageId(null);
    setEditingMessageText('');
  };

  const url = useParams();
  const groupName = url.name;
  const navigate = useNavigate();
  const orgName = getOrgName();
  const messagesContainerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  };

  useEffect(() => {
    if (!isLoadingOlder && messages.length > 0) {
      const lastMessage = messages[messages.length - 1];
      if (
        lastMessage.sender_id === currentUserInfo.id ||
        (newMessage && newMessage.sender_id !== currentUserInfo.id)
      ) {
        scrollToBottom();
      }
    }
  }, [messages, isLoadingOlder, newMessage]);

  const handleGroupChatDelete = async () => {
    setLoading(true);
    try {
      await ChatApi.handleGroupChatDeletion(groupDetails.groupChatData.id);
      const getUpdatedGroupList = groupList.filter(
        (group) => group.chatData.id !== groupDetails.groupChatData.id
      );
      setGroupList(getUpdatedGroupList);
      setSelectedChat(null);
      setOpenDetails(false);
      if (isSmallScreen) {
        navigate(`/org/${orgName}/chat`);
      }
    } catch (error) {
      handleAlert(error.message, 'warning');
    } finally {
      setLoading(false);
    }
  };
  const handleLeaveGroup = async () => {
    setLoading(true);
    try {
      await ChatApi.leaveGroup(groupDetails.groupChatData.id);
      setSelectedChat(null);
      setOpenDetails(false);
      if (isSmallScreen) {
        navigate(`/org/${orgName}/chat`);
      }
    } catch (error) {
      handleAlert(error.message, 'warning');
    }
    setLoading(false);
  };

  const handleGroupDelete = async () => {
    setLoading(true);
    try {
      await ChatApi.handleGroupDeletion(groupDetails.groupChatData.id);
      const getUpdatedGroupList = groupList.filter(
        (group) => group.chatData.id !== groupDetails.groupChatData.id
      );
      setGroupList(getUpdatedGroupList);
      setSelectedChat(null);
      setOpenDetails(false);
      if (isSmallScreen) {
        navigate(`/org/${orgName}/chat`);
      }
    } catch (error) {
      handleAlert(error.message, 'error');
    }
    setLoading(false);
  };
  const handleAction = async () => {
    if (confirmationAction === 'delete') {
      handleGroupDelete();
    } else if (confirmationAction === 'leave') {
      handleLeaveGroup();
    } else if (confirmationAction === 'delete chat') {
      handleGroupChatDelete();
    }
    setShowConfirmation(false);
  };
  const handleConfirmation = (action) => {
    setConfirmationAction(action);
    setShowConfirmation(true);
  };
  const onEmojiClick = (event) => {
    setInputValue((prevInputValue) => prevInputValue + event.emoji);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const updateMessages = (message) => {
    if (
      Object.prototype.hasOwnProperty.call(message, 'created_at') &&
      message.chat_id ===
        (groupDetails.groupChatData?.id ? groupDetails.groupChatData.id : groupDetails.id)
    ) {
      setIsLoadingOlder(false);
      addMessage([...messages, message]);
      if (message.sender_id === currentUserInfo.id) {
        scrollToBottom();
      }
    }
    setInputValue('');
  };
  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    if (newMessage) {
      updateMessages(newMessage);
      if (newMessage.sender_id !== currentUserInfo.id) {
        setShowScrollButton(true);
      }
    }
  }, [newMessage]);

  const fetchMessages = async (pageNum) => {
    if (isLoading) return;
    setIsLoadingOlder(true);
    setIsLoading(true);
    const getGroupDetails = async () => {
      try {
        setReactionsData([]);
        const container = messagesContainerRef.current;
        const initialScrollHeight = container?.scrollHeight || 0;
        const initialScrollTop = container?.scrollTop || 0;
        const response = await ChatApi.getGroupById(url.id, pageNum);
        response?.messages.map((item) => {
          if (item?.reactionsData?.length > 0) {
            setReactionsData((prev) => [
              ...prev,
              { msgId: item.id, emojiData: item.reactionsData },
            ]);
          }
        });
        if (!response.messages) {
          setMessages([]);
          setHasMore(false);
          return;
        }

        if (!Array.isArray(response.messages)) {
          handleAlert('Error fetching messages', 'error');
          return;
        }
        addMessage(response.messages);
        setMembers(response.groupChatMembers);
        setPage(pageNum);
        setHasMore(pageNum < response.totalPages);
        if (container) {
          requestAnimationFrame(() => {
            const newScrollHeight = container.scrollHeight;
            container.scrollTop = newScrollHeight - initialScrollHeight + initialScrollTop;
          });
        }
        setGroupDetails(response);
        setIsAdmin(response.isGroupAdmin);
        setShowSearchBar(false);
        setSearchInput('');
      } catch (error) {
        handleAlert(error.message, 'error');
      } finally {
        setIsLoading(false);
        setIsLoader(false);
        setIsLoadingOlder(false);
      }
    };
    getGroupDetails();
  };
  const getGroups = async () => {
    try {
      const response = await ChatApi.getGroups();
      setSelectedChat(response.find((group) => group.chatData.id === url.id));
    } catch (error) {
      handleAlert('error.message', 'warning');
    }
    setLoading(false);
  };
  useEffect(() => {
    setMessages([]);
    setPage(1);
    setIsLoader(true);
    fetchMessages(1).then(() => {
      scrollToBottom();
    });
    getGroups();
  }, []);

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  const debouncedHandleScroll = useCallback(
    debounce(() => {
      const container = messagesContainerRef.current;
      if (container) {
        if (container.scrollTop === 0 && hasMore && !isLoading) {
          fetchMessages(page + 1);
        }
        const atBottom =
          container.scrollHeight - container.scrollTop <= container.clientHeight + 100;
        setShowScrollButton(!atBottom);
      }
    }, 200),
    [fetchMessages, page, hasMore, isLoading]
  );

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.addEventListener('scroll', debouncedHandleScroll);
      return () => container.removeEventListener('scroll', debouncedHandleScroll);
    }
  }, [debouncedHandleScroll]);

  const currentUserInfo = GetValidatedTokenData();
  const notify = (username, message) => {
    addNotification({
      title: username,
      message: message,
      duration: 5000,
      native: true,
    });
  };

  useEffect(() => {
    if (!isSmallScreen) {
      navigate(`/org/${orgName}/chat`);
    }
  }, [isSmallScreen]);
  useEffect(() => {
    socket?.on('message', (msg) => {
      setNewMessage(msg[0]);
      if (currentUserInfo.id !== msg?.[0]?.sender_id) {
        notify(msg?.[0]?.username, msg?.[0]?.message);
      }
      setShowScrollButton(true);
    });
    return () => {
      socket?.off('message');
      socket?.off('disconnect');
    };
  }, [socket]);

  const handleOpenDetails = () => {
    setAnchorEl(null);
    setOpenDetails(!openDetails);
  };

  const handleSendMessage = () => {
    setShowEmojiPicker(false);
    if (inputValue.trim() && uploadedFile) return;
    if (socket) {
      let messageData = {
        senderId: currentUserInfo.id,
        message: uploadedFile ? uploadedFile.file.name : inputValue,
        groupId: url.id,
        organization: getOrgName(),
        replyId: replyTo ? replyTo?.id : null,
      };

      if (uploadedFile) {
        messageData = {
          ...messageData,
          type: 'FILE',
          url: uploadedFile.url,
        };
      }
      socket?.emit('message', messageData);
      updateMessages(messageData);
      setReplyTo(null);
      setUploadedFile(null);
      scrollToBottom();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
    setAnchorEl(null);
  };

  const handleCancelSearch = () => {
    setShowSearchBar(false);
    setSearchInput('');
  };
  if (loading) {
    return <BigLoader />;
  }
  const drawerWidth = 200;

  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid #D3D3D3',
        height: { xs: 'calc(100dvh - 72px)', sm: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        mt: { xs: 2, sm: 0, md: 2 },
      }}
    >
      <Box
        sx={{
          py: 1,
          pl: 1,
          pr: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: '1px solid #D3D3D3',
          backgroundColor: 'white',
          pointerEvents: uploadLoader ? 'none' : 'auto',
          opacity: uploadLoader ? 0.5 : 1,
        }}
        onClick={() => setShowEmojiPicker(false)}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <IconButton
            onClick={() => navigate(`/org/${orgName}/chat`)}
            sx={{
              p: 0.5,
            }}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <ManualAvatar chat={{ name: groupName }} bgColor={bgColor} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            flex={1}
            width={'100%'}
          >
            {groupDetails?.groupChatData?.name && (
              <p
                style={{
                  fontSize: '18px',
                  flexDirection: 'row',
                  alignItems: 'center',
                  overflowX: 'auto',
                  display: '-webkit-box',
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'normal',
                }}
              >
                {groupDetails?.groupChatData?.name}
              </p>
            )}
            <div style={{ maxWidth: '100%', overflow: 'hidden' }}>
              {groupDetails?.allowSendingMessage && (
                <Select
                  sx={{
                    padding: '0px',
                    height: '20px',
                    border: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        maxHeight: '50vh',
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  {members.length > 0 &&
                    members.map((member, index) => (
                      <MenuItem
                        key={index}
                        style={{
                          padding: '5px',
                          backgroundColor: 'transparent',
                          whiteSpace: 'normal',
                          wordBreak: 'break-word',
                        }}
                      >
                        {member.username}
                      </MenuItem>
                    ))}
                </Select>
              )}
            </div>
          </Box>
          {members && groupDetails?.allowSendingMessage && (
            <p>
              {members.length} {members.length > 1 ? 'members' : 'member'}
            </p>
          )}
          <IconButton
            onClick={handleMenuClick}
            sx={{
              p: 0.5,
            }}
          >
            <MoreVertIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          sx={{
            '& .MuiPaper-root': {
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            },
            '& .MuiList-root': {
              py: 0.5,
            },
          }}
        >
          {/* <MenuItem onClick={handleSearchClick}>Search</MenuItem> */}
          {groupDetails?.allowSendingMessage && (
            <>
              <MenuItem onClick={handleOpenDetails}>Chat Info</MenuItem>

              <MenuItem onClick={() => handleConfirmation('leave')}>Leave</MenuItem>
            </>
          )}
          <MenuItem onClick={() => handleConfirmation('delete chat')}>Delete for Me</MenuItem>
          {isAdmin && groupDetails?.allowSendingMessage && (
            <MenuItem onClick={() => handleConfirmation('delete')}>Delete for Everyone</MenuItem>
          )}
        </Menu>
        <ConfirmationDialog
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          onConfirm={handleAction}
          title={
            confirmationAction === 'delete'
              ? 'Delete Group'
              : confirmationAction === 'delete chat'
              ? 'Delete group for yourself'
              : 'Leave Group'
          }
          message={
            confirmationAction === 'delete'
              ? 'Are you sure you want to delete this group? This action cannot be undone.'
              : confirmationAction === 'delete chat'
              ? 'Are you sure you want to delete the chat of this group?'
              : 'Are you sure you want to leave this group?'
          }
        />
      </Box>

      {showSearchBar && (
        <SearchBar
          handleCancelSearch={handleCancelSearch}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
        />
      )}
      <Box
        sx={{
          padding: 2,
          flexGrow: 1,
          overflowY: uploadLoader ? 'hidden' : 'auto',
          margin: 0,
          position: 'relative',
        }}
        ref={messagesContainerRef}
        onClick={() => setShowEmojiPicker(false)}
        onScroll={debouncedHandleScroll}
      >
        {isLoader ? (
          <ChatMessageLoader />
        ) : messages && messages.length === 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <img
              src={GroupChatDesktop}
              style={{
                height: '40vh',
              }}
            />
            <Typography
              sx={{
                textAlign: 'center',
                maxWidth: {
                  xs: '100%',
                  sm: '80%',
                },
                marginBottom: 'auto',
                borderRadius: '5px',
                padding: '10px',
                backgroundColor: '#f5f5f5',
              }}
            >
              Welcome to the group! 🎉 We're excited to have you here. Feel free to introduce
              yourselves, share your thoughts, and start the conversation. Let's make this a great
              space for everyone!
            </Typography>
            <SuggestedFeatures />
          </Box>
        ) : (
          <>
            {isLoading && <ChatMessageLoader />}
            {messages.map((message, index) => (
              <>
                <div style={{ position: 'relative' }}>
                  {hoveredMessageId === message.id && (
                    <MessageActions
                      canSendMessages={groupDetails?.allowSendingMessage}
                      message={message}
                      currentUserInfo={currentUserInfo}
                      handleDeleteMessage={handleDeleteMessage}
                      handleEditMessage={handleEditMessage}
                      handleReplyMessage={handleReplyMessage}
                      setHoveredMessageId={setHoveredMessageId}
                      showReactEmoji={showReactEmoji}
                      setReactionsData={setReactionsData}
                      reactionsData={reactionsData}
                    />
                  )}
                </div>
                <ChatMessagePanel
                  key={index}
                  message={message}
                  prevMessage={index > 0 ? messages[index - 1] : null}
                  isCurrentUser={message.sender_id === currentUserInfo.id}
                  style={{
                    pointerEvents: uploadLoader ? 'none' : 'auto',
                    opacity: uploadLoader ? 0.5 : 1,
                  }}
                  onMouseEnter={() => setHoveredMessageId(message.id)}
                  onMouseLeave={() => setHoveredMessageId(null)}
                  setHoveredMessageId={setHoveredMessageId}
                  handleEditMessage={handleEditMessage}
                  handleDeleteMessage={handleDeleteMessage}
                  handleReplyMessage={handleReplyMessage}
                  handleCancelReply={handleCancelReply}
                  replyTo={replyTo}
                  setReplyTo={setReplyTo}
                  currentUserInfo={currentUserInfo}
                />
                {reactionsData?.length > 0 &&
                  reactionsData.map((reactionData, index) => {
                    if (reactionData.msgId === message.id) {
                      return (
                        <ChatReactionBox
                          key={index}
                          reactionData={reactionData}
                          isCurrentUser={message.sender_id === currentUserInfo.id}
                          setReactionsData={setReactionsData}
                          reactionsData={reactionsData}
                        />
                      );
                    }
                  })}
                {editingMessageId === message.id && (
                  <Box sx={{ display: 'flex', flexDirection: 'column', mt: 1, mb: 1 }}>
                    <TextField
                      value={editingMessageText}
                      onChange={(e) => setEditingMessageText(e.target.value)}
                      fullWidth
                      multiline
                      variant="outlined"
                      size="small"
                      sx={{ mb: 1 }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <Button onClick={handleCancelEdit} size="small" sx={{ mr: 1 }}>
                        Cancel
                      </Button>
                      <Button
                        onClick={() => handleEditMessageSubmit(message.id)}
                        size="small"
                        variant="contained"
                      >
                        Update
                      </Button>
                    </Box>
                  </Box>
                )}
              </>
            ))}
          </>
        )}
      </Box>
      <div ref={messagesEndRef} />
      {showScrollButton && (
        <Box
          sx={{
            position: 'sticky',
            bottom: '24px',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '16px',
            zIndex: 1,
          }}
        >
          <IconButton
            onClick={scrollToBottom}
            sx={{
              backgroundColor: '#eeeff1',
              '&:hover': {
                backgroundColor: '#dbe7ff',
              },
            }}
          >
            <ArrowDownwardIcon sx={{ color: '#8ca4f8' }} />
          </IconButton>
        </Box>
      )}
      {uploadLoader ? (
        <Box display={'flex'} position={'absolute'} top={'50%'} left={'50%'}>
          <Skeletons type={'LazySmallCircularLoader'} />
        </Box>
      ) : (
        uploadedFile && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginRight: 'auto',
              padding: '10px',
              borderBottom: '1px solid #D3D3D3',
            }}
          >
            <img
              src={uploadedFile.url}
              alt={uploadedFile.file.name}
              style={{ maxWidth: '100px', maxHeight: '100px', marginRight: '10px' }}
            />
            <span>{uploadedFile.file.name}</span>
          </Box>
        )
      )}
      {replyTo && (
        <Box
          sx={{
            backgroundColor: '#f5f5f5',
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2" color="textSecondary">
            Replying to: {replyTo.message}
          </Typography>
          <IconButton onClick={handleCancelReply} size="small">
            <CancelIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      {groupDetails?.allowSendingMessage ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
        >
          <ChatInputField
            ref={inputRef}
            inputValue={inputValue}
            setInputValue={setInputValue}
            handleSendMessage={handleSendMessage}
            handleKeyPress={handleKeyPress}
            setShowEmojiPicker={setShowEmojiPicker}
            showEmojiPicker={showEmojiPicker}
            setUploadedFile={setUploadedFile}
            setUploadLoader={setUploadLoader}
          />
        </form>
      ) : isLoader ? (
        <ChatMessageLoader />
      ) : (
        <Box
          sx={{
            marginTop: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            width: '100%',
            backgroundColor: '#f0f0f0',
            color: '#555',
            padding: '5px',
            borderRadius: '5px',
            fontSize: '14px',
          }}
        >
          You can't send messages to this group because you're no longer a member.
        </Box>
      )}
      {showEmojiPicker && (
        <div style={{ position: 'absolute', bottom: '60px', right: '20px' }}>
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </div>
      )}
      {openDetails && (
        <GroupDetails
          chat={groupDetails}
          setOpenDetails={setOpenDetails}
          setGroupList={setGroupList}
          groupList={groupList}
          groupMembers={members}
          setGroupMembers={setMembers}
          socket={socket}
          openDetails={openDetails}
        />
      )}
    </Box>
  );
}
