import { Grid, TextField, Typography, Box, Button } from '@mui/material';
import React, { useState } from 'react';

const SubmissionUiSolution = ({ content, onContentChange, submitCode, submissionResponse }) => {
  const { githubRepo, language, githubUsername } = content;

  const handleChange = (field) => (event) => {
    onContentChange(field, event.target.value);
  };

  return (
    <Grid container spacing={2} sx={{ width: '-webkit-fill-available', mx: 0, mt: 2, px: '16px' }}>
      <Grid
        item
        xs={12}
        pt="0px!important"
        pl="0px!important"
        sx={{
          mb: 2.5,
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500',
            mb: 2,
          }}
        >
          Please fill in the below fields to submit your solution
        </Typography>
        <TextField
          sx={{ mr: 2 }}
          size="small"
          required
          id="outlined-basic"
          label="Github repository"
          type="text"
          fullWidth
          variant="outlined"
          value={githubRepo}
          onChange={handleChange('githubRepo')}
          autoFocus
        />
      </Grid>
      <Grid
        item
        xs={12}
        pt="0px!important"
        pl="0px!important"
        sx={{
          mb: 2.5,
        }}
      >
        <TextField
          sx={{ mr: 2 }}
          size="small"
          required
          id="outlined-basic"
          label="Language"
          type="text"
          fullWidth
          variant="outlined"
          value={language}
          onChange={handleChange('language')}
          autoFocus
        />
      </Grid>
      <Grid
        item
        xs={12}
        pt="0px!important"
        pl="0px!important"
        sx={{
          mb: 2.5,
        }}
      >
        <TextField
          sx={{ mr: 2 }}
          size="small"
          required
          id="outlined-basic"
          label="Github username"
          type="text"
          fullWidth
          variant="outlined"
          value={githubUsername}
          onChange={handleChange('githubUsername')}
          autoFocus
        />
      </Grid>
      <Grid
        item
        xs={12}
        pt="0px!important"
        pl="0px!important"
        sx={{
          mb: 0,
        }}
      >
        <Button
          onClick={() => submitCode()}
          size="small"
          sx={{
            textTransform: 'none',
            backgroundColor: '#00d23010',
            color: '#28A745',
            mb: 0,
            '&:hover': {
              backgroundColor: '#00d23020',
            },
          }}
        >
          Submit Code
        </Button>
        {submissionResponse && (
          <Typography sx={{ mt: 2 }} variant="subtitle2">
            {submissionResponse}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12} sx={{ px: '0px!important' }}>
        <Typography variant="body1" sx={{ fontWeight: 500 }}>
          Important instruction regarding Submissions
        </Typography>
        <Box
          sx={{
            color: 'black',
            border: '1px solid grey',
            padding: '10px',
            borderRadius: '5px',
            marginBottom: '10px',
            mt: 2,
          }}
        >
          <Typography variant="body2" sx={{ color: 'black' }}>
            * Github Repository should be public.
            <br />
            * Branch should be master.
            <br />* Repository should have a build folder.
          </Typography>
        </Box>
      </Grid>
      {/* <Grid item xs={12} sx={{ pl: '0px!important' }}>
        <Typography
          variant="body1"
          sx={{
            fontWeight: '500',
            mb: 1,
          }}
        >
          Upload code here
        </Typography>
        <Typography variant="subtitle2">
          Fill the required details to make your submission
        </Typography>
      </Grid> */}
    </Grid>
  );
};

export default SubmissionUiSolution;
