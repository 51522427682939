import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import Rating from '@mui/material/Rating';
import './Product.css';
import './CarouselItem.css';
import { useDispatch } from 'react-redux';
import { manipulateCart } from '../../Redux/AddToCart/Cart-Action';
import { REMOVE_ITEM } from '../../Redux/AddToCart/Cart-Constants';
import { useNavigate } from 'react-router-dom';
import { manipulateWishList } from '../../Redux/AddToWishlist/Wishlist-Action';
import { manipulateEditCourse } from '../../Redux/EditCourse/EditCourse-Action';
import {
  ADD_EDIT_COURSE,
  REMOVE_EDITABLE_COURSE,
} from '../../Redux/EditCourse/EditCourse-Constants';
import defaultImage from '../../assets/CourseImages/Thumbnail.webp';
import { courseStageAPI } from '../../api/requests/courses/courseStageAPI';
import { courseAPI } from '../../api/requests/courses/courseAPI';
import GetValidatedTokenData from '../../utils/helper';
import { handleAlert } from '../../utils/handleAlert';
import ManageCourseAccess from '../../pages/Courses/ManageCourseAccess';
import ButtonAddAccess from '../SearchAndAdd/ButtonAddAccess';
import { getOrgName } from '../../utils/appendOrgQuery';
import { Colors } from '../../theme/Theme';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
const CarouselItem = ({
  ProductDetails,
  keyfrombackend,
  loading,
  isEditable,
  isUserProfileInProduct,
  domainData,
  getCourses,
  forSuggestedOrgPopup,
  currentPage,
  activeTab,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openManageAccessDialog, setOpenManageAccessDialog] = React.useState(false);
  const [isEnrolled, setHasEnrolled] = useState(false);
  const [isEditor, setIsEditor] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currUser = GetValidatedTokenData();
  const orgPath = getOrgName();

  const getdata = () => {
    if (ProductDetails.courseStage === 'ENROLLED') {
      setHasEnrolled(true);
    }
    if (ProductDetails.courseStatus === 'EDIT') {
      setIsEditor(true);
    }

    if (
      Object.prototype.hasOwnProperty.call(ProductDetails, 'courseStage') &&
      Object.prototype.hasOwnProperty.call(ProductDetails, 'courseStatus')
    ) {
      return;
    }
  };

  const updateState = (data) => {
    setHasEnrolled(data.data.courseStage === 'ENROLLED');
    setIsEditor(data.data.courseStatus === 'EDIT');
  };

  useEffect(() => {
    const getCourseData = async () => {
      await getdata();
    };
    getCourseData();
  }, []);

  const handleRemoveEnrolledCourse = async (id) => {
    await courseStageAPI
      .removeFromEnrolledCourses(id)
      .then((data) => {})
      .catch((err) => {
        dispatch(manipulateCart(REMOVE_ITEM, id));
      });
  };

  const handleClose = (_, reason) => {
    if (reason && reason === 'backdropClick') return;
    setIsHovered(false);
  };

  const isCurrentUser = (userId) => {
    return GetValidatedTokenData().id === userId;
  };

  const handleGoToDetailPage = (id, userId, orgName) => {
    if (isCurrentUser(userId)) navigate(`/org/${orgName}/course/videos/${id}`);
    else if (ProductDetails?.status === 'UNDERREVIEW') navigate('/under-review');
    else if (ProductDetails?.status === 'APPROVED') navigate(`/org/${orgPath}/course/videos/${id}`);
    else if (ProductDetails?.courseStage === 'ENROLLED')
      navigate(`/org/${orgPath}/course/videos/${id}`);
  };

  const handleEditCourse = (ProductDetails) => {
    dispatch(manipulateEditCourse(ADD_EDIT_COURSE, ProductDetails?.id));
  };

  const handleDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setIsHovered(false);
  };

  const handleManageAccessDialog = () => {
    setOpenManageAccessDialog(true);
  };

  const handleCloseManageAccessDialog = () => {
    setOpenManageAccessDialog(false);
    setIsHovered(false);
  };

  const handleDeleteCourse = async (ProductDetails) => {
    await courseAPI
      .deleteCourse(ProductDetails.id)
      .then((res) => {
        dispatch(manipulateWishList(REMOVE_EDITABLE_COURSE, ProductDetails.id));
        handleAlert(res?.data?.message, 'success');
        setOpenDeleteDialog(false);
        getCourses(currentPage, activeTab);
      })
      .catch((err) => {
        handleAlert(err?.message, 'error');
      });
  };
  return (
    <>
      <div
        className="Container"
        key={keyfrombackend}
        isUserProfileInProduct={isUserProfileInProduct}
      >
        <Box
          sx={{
            backgroundColor: '#fff',
            borderRadius: '8px',
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {loading ? (
            <Skeleton variant="rectangular" width={300} height={240} />
          ) : (
            <img
              style={{
                width: '100%',
                height: '150px',
                minHeight: '150px',
                objectFit: 'cover',
                borderRadius: '8px 8px 0px 0px',
              }}
              alt={ProductDetails?.title}
              src={ProductDetails?.imageUrl ?? defaultImage}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultImage;
              }}
            />
          )}
          {ProductDetails ? (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 1.5,
                  alignItems: 'stretch',
                }}
              >
                <Box
                  style={{ cursor: 'pointer', height: '100%' }}
                  onClick={() =>
                    handleGoToDetailPage(
                      ProductDetails?.id,
                      ProductDetails?.userId,
                      ProductDetails?.orgName
                    )
                  }
                >
                  <Box>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: '600',
                        color: Colors.card.heading,
                        fontFamily: 'Poppins',
                        display: '-webkit-box!important',
                        WebkitLineClamp: 2,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        WebkitBoxOrient: ' vertical',
                        mb: 0.5,
                        mt: 0,
                      }}
                    >
                      {ProductDetails.title}
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      sx={{
                        fontWeight: '400',
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        lineHeight: 1.4,
                        display: '-webkit-box!important',
                        WebkitLineClamp: 1,
                        overflow: 'hidden',
                        WebkitBoxOrient: ' vertical',
                        color: Colors.card.cardCaption,
                      }}
                    >
                      {`${ProductDetails?.authorData?.firstName} ${ProductDetails?.authorData?.lastName}`}
                    </Typography>
                    {isUserProfileInProduct === true ? (
                      <Typography display="block" variant="caption" color="text.secondary">
                        {ProductDetails?.status}
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Box>

                  <Box
                    sx={{
                      mt: 0.5,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{ mr: 0.5, fontWeight: '500' }}
                        variant="caption"
                        color="#faaf00"
                      >
                        {ProductDetails?.rating?.rating}
                      </Typography>
                      <Stack spacing={1} sx={{ mr: 0.5 }}>
                        <Rating
                          sx={{ fontSize: '12px' }}
                          name="half-rating"
                          defaultValue={ProductDetails?.rating?.rating}
                          precision={1}
                          readOnly
                        />
                      </Stack>
                      <Typography variant="caption" color="text.secondary">
                        {` (${ProductDetails?.rating?.ratedBy})`}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', rowGap: '4px', mt: 0.5 }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        lineHeight: 0,
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: Colors.card.cardCaption,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <OndemandVideoIcon sx={{ fontSize: '16px' }} />{' '}
                      {`Total Videos: ${ProductDetails?.videosCount ?? 0}`}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{
                        lineHeight: 0,
                        fontFamily: 'Poppins',
                        fontSize: '12px',
                        color: Colors.card.cardCaption,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px',
                      }}
                    >
                      <FolderOpenIcon sx={{ fontSize: '16px' }} />{' '}
                      {`Total Sections: ${ProductDetails?.sectionsCount ?? 0}`}
                    </Typography>
                  </Box>
                </Box>
                {forSuggestedOrgPopup ? (
                  <></>
                ) : (
                  <Box>
                    {!domainData && ProductDetails?.courseStage !== 'ENROLLED' ? (
                      <Box>
                        {isEditable ? (
                          <Box mt={2} display={'flex'} flexDirection="column" gap="16px">
                            <Box display={'flex'} gap="16px">
                              <Button
                                fullWidth
                                sx={{ fontSize: '11px', fontWeight: '400' }}
                                variant="contained"
                                onClick={() => {
                                  handleEditCourse(ProductDetails);
                                  navigate(
                                    `/org/${ProductDetails.orgName}/course/update/${ProductDetails.id}`
                                  );
                                }}
                              >
                                Edit Course
                              </Button>
                              {ProductDetails?.userId === currUser.id &&
                              (currUser.role === 'SUPERADMIN' ||
                                currUser.role === 'CREATOR' ||
                                (currUser.organizationId && currUser.role === 'ADMIN')) ? (
                                <Button
                                  fullWidth
                                  sx={{ fontSize: '11px', fontWeight: '400' }}
                                  variant="contained"
                                  onClick={() => {
                                    handleManageAccessDialog();
                                  }}
                                >
                                  Manage Access
                                </Button>
                              ) : null}
                            </Box>
                            <Box display={'flex'} gap="16px">
                              <Button
                                fullWidth
                                sx={{ fontSize: '11px', fontWeight: '400' }}
                                variant="outlined"
                                onClick={() => {
                                  handleDeleteDialog(ProductDetails);
                                }}
                              >
                                Delete Course
                              </Button>
                              <Button
                                fullWidth
                                sx={{ fontSize: '11px', fontWeight: '400' }}
                                variant="contained"
                                onClick={() =>
                                  handleGoToDetailPage(
                                    ProductDetails?.id,
                                    ProductDetails?.userId,
                                    ProductDetails?.orgName
                                  )
                                }
                              >
                                View Course
                              </Button>
                            </Box>
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    ) : (
                      <Box>
                        <Grid
                          item
                          xs={12}
                          display="flex"
                          alignItems="center"
                          sx={{ ml: 1, flex: 1 }}
                        >
                          <Button
                            fullWidth
                            sx={{ fontSize: '11px', fontWeight: '400' }}
                            variant="outlined"
                            onClick={() =>
                              handleGoToDetailPage(
                                ProductDetails?.id,
                                ProductDetails?.userId,
                                ProductDetails?.orgName
                              )
                            }
                          >
                            View Details
                          </Button>
                        </Grid>
                      </Box>
                    )}

                    <Dialog
                      open={openDeleteDialog}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        backdropFilter: 'blur(2px)',
                      }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {'Do you really want to delete?'}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          this course will delete permanently
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDeleteCourse(ProductDetails)}>Yes</Button>
                        <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={openManageAccessDialog}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                      sx={{
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        backdropFilter: 'blur(2px)',
                      }}
                    >
                      <DialogTitle id="alert-dialog-title">
                        {'Give course access permissions'}
                      </DialogTitle>
                      <DialogContent>
                        <ManageCourseAccess courseId={keyfrombackend} handleClose={handleClose} />
                      </DialogContent>
                      <DialogActions
                        sx={{
                          mt: -2,
                          display: 'flex',
                          justifyContent: 'space-between',
                          mx: 2,
                        }}
                      >
                        <ButtonAddAccess
                          courseId={keyfrombackend}
                          setOpenManageAccessDialog={setOpenManageAccessDialog}
                        />
                        <Button onClick={handleCloseManageAccessDialog}>Cancel</Button>
                      </DialogActions>
                    </Dialog>
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          )}
        </Box>
      </div>
    </>
  );
};

export default React.memo(CarouselItem);
